import * as React from 'react';
import Svg, {
    Path,
    Defs,
    RadialGradient,
    Stop,
    LinearGradient,
} from 'react-native-svg';

export default function GlowingStarIcon() {
    return (
        <Svg
            width={54}
            height={54}
            viewBox="0 0 54 54"
            fill="none"
        >
            <Path
                d="M48.902 30.157l-5.245.36c-1.407.104-1.988 1.822-.915 2.715l4.03 3.299c.88.721 2.218.343 2.57-.722l1.196-3.59c.37-1.066-.492-2.148-1.636-2.062z"
                fill="#F6C327"
            />
            <Path
                d="M48.902 30.157l-5.245.36c-1.407.104-1.988 1.822-.915 2.715l4.03 3.299c.88.721 2.218.343 2.57-.722l1.196-3.59c.37-1.066-.492-2.148-1.636-2.062z"
                fill="url(#paint0_radial_376_26265)"
            />
            <Path
                d="M48.902 30.157l-5.245.36c-1.407.104-1.988 1.822-.915 2.715l4.03 3.299c.88.721 2.218.343 2.57-.722l1.196-3.59c.37-1.066-.492-2.148-1.636-2.062z"
                fill="url(#paint1_linear_376_26265)"
            />
            <Path
                d="M48.902 30.157l-5.245.36c-1.407.104-1.988 1.822-.915 2.715l4.03 3.299c.88.721 2.218.343 2.57-.722l1.196-3.59c.37-1.066-.492-2.148-1.636-2.062z"
                fill="url(#paint2_linear_376_26265)"
            />
            <Path
                d="M36.702 6.006L35.487 10.9c-.337 1.316 1.114 2.38 2.261 1.654l4.287-2.667a1.546 1.546 0 00.084-2.565l-3.004-2.176a1.538 1.538 0 00-2.413.86z"
                fill="#F4C226"
            />
            <Path
                d="M36.702 6.006L35.487 10.9c-.337 1.316 1.114 2.38 2.261 1.654l4.287-2.667a1.546 1.546 0 00.084-2.565l-3.004-2.176a1.538 1.538 0 00-2.413.86z"
                fill="url(#paint3_linear_376_26265)"
            />
            <Path
                d="M36.702 6.006L35.487 10.9c-.337 1.316 1.114 2.38 2.261 1.654l4.287-2.667a1.546 1.546 0 00.084-2.565l-3.004-2.176a1.538 1.538 0 00-2.413.86z"
                fill="url(#paint4_linear_376_26265)"
            />
            <Path
                d="M36.702 6.006L35.487 10.9c-.337 1.316 1.114 2.38 2.261 1.654l4.287-2.667a1.546 1.546 0 00.084-2.565l-3.004-2.176a1.538 1.538 0 00-2.413.86z"
                fill="url(#paint5_linear_376_26265)"
            />
            <Path
                d="M10.852 9.893l4.269 2.666c1.147.726 2.599-.32 2.278-1.654l-1.215-4.893a1.551 1.551 0 00-2.413-.878l-3.004 2.177c-.894.675-.844 2.008.085 2.582z"
                fill="#EABA25"
            />
            <Path
                d="M10.852 9.893l4.269 2.666c1.147.726 2.599-.32 2.278-1.654l-1.215-4.893a1.551 1.551 0 00-2.413-.878l-3.004 2.177c-.894.675-.844 2.008.085 2.582z"
                fill="url(#paint6_linear_376_26265)"
            />
            <Path
                d="M10.852 9.893l4.269 2.666c1.147.726 2.599-.32 2.278-1.654l-1.215-4.893a1.551 1.551 0 00-2.413-.878l-3.004 2.177c-.894.675-.844 2.008.085 2.582z"
                fill="url(#paint7_linear_376_26265)"
            />
            <Path
                d="M10.852 9.893l4.269 2.666c1.147.726 2.599-.32 2.278-1.654l-1.215-4.893a1.551 1.551 0 00-2.413-.878l-3.004 2.177c-.894.675-.844 2.008.085 2.582z"
                fill="url(#paint8_linear_376_26265)"
            />
            <Path
                d="M7.063 36.417l3.864-3.24c1.046-.877.49-2.582-.86-2.666l-5.03-.354c-1.096-.085-1.923.978-1.586 2.025L4.6 35.708c.337 1.047 1.637 1.418 2.464.71z"
                fill="#DFAD26"
            />
            <Path
                d="M7.063 36.417l3.864-3.24c1.046-.877.49-2.582-.86-2.666l-5.03-.354c-1.096-.085-1.923.978-1.586 2.025L4.6 35.708c.337 1.047 1.637 1.418 2.464.71z"
                fill="url(#paint9_linear_376_26265)"
            />
            <Path
                d="M7.063 36.417l3.864-3.24c1.046-.877.49-2.582-.86-2.666l-5.03-.354c-1.096-.085-1.923.978-1.586 2.025L4.6 35.708c.337 1.047 1.637 1.418 2.464.71z"
                fill="url(#paint10_linear_376_26265)"
            />
            <Path
                d="M30.03 48.722l-1.89-4.674c-.507-1.266-2.296-1.266-2.802 0l-1.89 4.674c-.405 1.013.338 2.127 1.435 2.127h3.712c1.097-.017 1.84-1.114 1.434-2.127z"
                fill="#E3B028"
            />
            <Path
                d="M30.03 48.722l-1.89-4.674c-.507-1.266-2.296-1.266-2.802 0l-1.89 4.674c-.405 1.013.338 2.127 1.435 2.127h3.712c1.097-.017 1.84-1.114 1.434-2.127z"
                fill="url(#paint11_linear_376_26265)"
            />
            <Path
                d="M30.03 48.722l-1.89-4.674c-.507-1.266-2.296-1.266-2.802 0l-1.89 4.674c-.405 1.013.338 2.127 1.435 2.127h3.712c1.097-.017 1.84-1.114 1.434-2.127z"
                fill="url(#paint12_linear_376_26265)"
            />
            <Path
                d="M30.03 48.722l-1.89-4.674c-.507-1.266-2.296-1.266-2.802 0l-1.89 4.674c-.405 1.013.338 2.127 1.435 2.127h3.712c1.097-.017 1.84-1.114 1.434-2.127z"
                fill="url(#paint13_linear_376_26265)"
            />
            <Path
                d="M29.64 7.074l2.914 6.277a3.817 3.817 0 002.898 2.176l6.843 1.054c3.097.47 4.346 4.269 2.182 6.512l-5.212 5.373a3.855 3.855 0 00-1.033 3.314l1.2 7.382c.515 3.18-2.848 5.557-5.662 4l-5.745-3.197a3.797 3.797 0 00-3.73 0l-5.745 3.197c-2.814 1.557-6.178-.82-5.661-4l1.199-7.382a3.855 3.855 0 00-1.033-3.314l-5.212-5.373c-2.181-2.26-.916-6.043 2.182-6.512l6.843-1.054a3.893 3.893 0 002.898-2.176l2.914-6.277c1.382-2.98 5.578-2.98 6.96 0z"
                fill="url(#paint14_radial_376_26265)"
            />
            <Path
                d="M29.64 7.074l2.914 6.277a3.817 3.817 0 002.898 2.176l6.843 1.054c3.097.47 4.346 4.269 2.182 6.512l-5.212 5.373a3.855 3.855 0 00-1.033 3.314l1.2 7.382c.515 3.18-2.848 5.557-5.662 4l-5.745-3.197a3.797 3.797 0 00-3.73 0l-5.745 3.197c-2.814 1.557-6.178-.82-5.661-4l1.199-7.382a3.855 3.855 0 00-1.033-3.314l-5.212-5.373c-2.181-2.26-.916-6.043 2.182-6.512l6.843-1.054a3.893 3.893 0 002.898-2.176l2.914-6.277c1.382-2.98 5.578-2.98 6.96 0z"
                fill="url(#paint15_radial_376_26265)"
            />
            <Path
                d="M29.64 7.074l2.914 6.277a3.817 3.817 0 002.898 2.176l6.843 1.054c3.097.47 4.346 4.269 2.182 6.512l-5.212 5.373a3.855 3.855 0 00-1.033 3.314l1.2 7.382c.515 3.18-2.848 5.557-5.662 4l-5.745-3.197a3.797 3.797 0 00-3.73 0l-5.745 3.197c-2.814 1.557-6.178-.82-5.661-4l1.199-7.382a3.855 3.855 0 00-1.033-3.314l-5.212-5.373c-2.181-2.26-.916-6.043 2.182-6.512l6.843-1.054a3.893 3.893 0 002.898-2.176l2.914-6.277c1.382-2.98 5.578-2.98 6.96 0z"
                fill="url(#paint16_radial_376_26265)"
            />
            <Path
                d="M29.64 7.074l2.914 6.277a3.817 3.817 0 002.898 2.176l6.843 1.054c3.097.47 4.346 4.269 2.182 6.512l-5.212 5.373a3.855 3.855 0 00-1.033 3.314l1.2 7.382c.515 3.18-2.848 5.557-5.662 4l-5.745-3.197a3.797 3.797 0 00-3.73 0l-5.745 3.197c-2.814 1.557-6.178-.82-5.661-4l1.199-7.382a3.855 3.855 0 00-1.033-3.314l-5.212-5.373c-2.181-2.26-.916-6.043 2.182-6.512l6.843-1.054a3.893 3.893 0 002.898-2.176l2.914-6.277c1.382-2.98 5.578-2.98 6.96 0z"
                fill="url(#paint17_radial_376_26265)"
            />
            <Path
                d="M29.64 7.074l2.914 6.277a3.817 3.817 0 002.898 2.176l6.843 1.054c3.097.47 4.346 4.269 2.182 6.512l-5.212 5.373a3.855 3.855 0 00-1.033 3.314l1.2 7.382c.515 3.18-2.848 5.557-5.662 4l-5.745-3.197a3.797 3.797 0 00-3.73 0l-5.745 3.197c-2.814 1.557-6.178-.82-5.661-4l1.199-7.382a3.855 3.855 0 00-1.033-3.314l-5.212-5.373c-2.181-2.26-.916-6.043 2.182-6.512l6.843-1.054a3.893 3.893 0 002.898-2.176l2.914-6.277c1.382-2.98 5.578-2.98 6.96 0z"
                fill="url(#paint18_radial_376_26265)"
            />
            <Path
                d="M29.64 7.074l2.914 6.277a3.817 3.817 0 002.898 2.176l6.843 1.054c3.097.47 4.346 4.269 2.182 6.512l-5.212 5.373a3.855 3.855 0 00-1.033 3.314l1.2 7.382c.515 3.18-2.848 5.557-5.662 4l-5.745-3.197a3.797 3.797 0 00-3.73 0l-5.745 3.197c-2.814 1.557-6.178-.82-5.661-4l1.199-7.382a3.855 3.855 0 00-1.033-3.314l-5.212-5.373c-2.181-2.26-.916-6.043 2.182-6.512l6.843-1.054a3.893 3.893 0 002.898-2.176l2.914-6.277c1.382-2.98 5.578-2.98 6.96 0z"
                fill="url(#paint19_radial_376_26265)"
            />
            <Path
                d="M29.64 7.074l2.914 6.277a3.817 3.817 0 002.898 2.176l6.843 1.054c3.097.47 4.346 4.269 2.182 6.512l-5.212 5.373a3.855 3.855 0 00-1.033 3.314l1.2 7.382c.515 3.18-2.848 5.557-5.662 4l-5.745-3.197a3.797 3.797 0 00-3.73 0l-5.745 3.197c-2.814 1.557-6.178-.82-5.661-4l1.199-7.382a3.855 3.855 0 00-1.033-3.314l-5.212-5.373c-2.181-2.26-.916-6.043 2.182-6.512l6.843-1.054a3.893 3.893 0 002.898-2.176l2.914-6.277c1.382-2.98 5.578-2.98 6.96 0z"
                fill="url(#paint20_radial_376_26265)"
            />
            <Path
                d="M29.64 7.074l2.914 6.277a3.817 3.817 0 002.898 2.176l6.843 1.054c3.097.47 4.346 4.269 2.182 6.512l-5.212 5.373a3.855 3.855 0 00-1.033 3.314l1.2 7.382c.515 3.18-2.848 5.557-5.662 4l-5.745-3.197a3.797 3.797 0 00-3.73 0l-5.745 3.197c-2.814 1.557-6.178-.82-5.661-4l1.199-7.382a3.855 3.855 0 00-1.033-3.314l-5.212-5.373c-2.181-2.26-.916-6.043 2.182-6.512l6.843-1.054a3.893 3.893 0 002.898-2.176l2.914-6.277c1.382-2.98 5.578-2.98 6.96 0z"
                fill="url(#paint21_radial_376_26265)"
            />
            <Path
                d="M29.64 7.074l2.914 6.277a3.817 3.817 0 002.898 2.176l6.843 1.054c3.097.47 4.346 4.269 2.182 6.512l-5.212 5.373a3.855 3.855 0 00-1.033 3.314l1.2 7.382c.515 3.18-2.848 5.557-5.662 4l-5.745-3.197a3.797 3.797 0 00-3.73 0l-5.745 3.197c-2.814 1.557-6.178-.82-5.661-4l1.199-7.382a3.855 3.855 0 00-1.033-3.314l-5.212-5.373c-2.181-2.26-.916-6.043 2.182-6.512l6.843-1.054a3.893 3.893 0 002.898-2.176l2.914-6.277c1.382-2.98 5.578-2.98 6.96 0z"
                fill="url(#paint22_radial_376_26265)"
            />
            <Path
                d="M29.64 7.074l2.914 6.277a3.817 3.817 0 002.898 2.176l6.843 1.054c3.097.47 4.346 4.269 2.182 6.512l-5.212 5.373a3.855 3.855 0 00-1.033 3.314l1.2 7.382c.515 3.18-2.848 5.557-5.662 4l-5.745-3.197a3.797 3.797 0 00-3.73 0l-5.745 3.197c-2.814 1.557-6.178-.82-5.661-4l1.199-7.382a3.855 3.855 0 00-1.033-3.314l-5.212-5.373c-2.181-2.26-.916-6.043 2.182-6.512l6.843-1.054a3.893 3.893 0 002.898-2.176l2.914-6.277c1.382-2.98 5.578-2.98 6.96 0z"
                fill="url(#paint23_radial_376_26265)"
            />
            <Path
                d="M29.64 7.074l2.914 6.277a3.817 3.817 0 002.898 2.176l6.843 1.054c3.097.47 4.346 4.269 2.182 6.512l-5.212 5.373a3.855 3.855 0 00-1.033 3.314l1.2 7.382c.515 3.18-2.848 5.557-5.662 4l-5.745-3.197a3.797 3.797 0 00-3.73 0l-5.745 3.197c-2.814 1.557-6.178-.82-5.661-4l1.199-7.382a3.855 3.855 0 00-1.033-3.314l-5.212-5.373c-2.181-2.26-.916-6.043 2.182-6.512l6.843-1.054a3.893 3.893 0 002.898-2.176l2.914-6.277c1.382-2.98 5.578-2.98 6.96 0z"
                fill="url(#paint24_radial_376_26265)"
            />
            <Path
                d="M29.64 7.074l2.914 6.277a3.817 3.817 0 002.898 2.176l6.843 1.054c3.097.47 4.346 4.269 2.182 6.512l-5.212 5.373a3.855 3.855 0 00-1.033 3.314l1.2 7.382c.515 3.18-2.848 5.557-5.662 4l-5.745-3.197a3.797 3.797 0 00-3.73 0l-5.745 3.197c-2.814 1.557-6.178-.82-5.661-4l1.199-7.382a3.855 3.855 0 00-1.033-3.314l-5.212-5.373c-2.181-2.26-.916-6.043 2.182-6.512l6.843-1.054a3.893 3.893 0 002.898-2.176l2.914-6.277c1.382-2.98 5.578-2.98 6.96 0z"
                fill="url(#paint25_radial_376_26265)"
            />
            <Path
                d="M29.64 7.074l2.914 6.277a3.817 3.817 0 002.898 2.176l6.843 1.054c3.097.47 4.346 4.269 2.182 6.512l-5.212 5.373a3.855 3.855 0 00-1.033 3.314l1.2 7.382c.515 3.18-2.848 5.557-5.662 4l-5.745-3.197a3.797 3.797 0 00-3.73 0l-5.745 3.197c-2.814 1.557-6.178-.82-5.661-4l1.199-7.382a3.855 3.855 0 00-1.033-3.314l-5.212-5.373c-2.181-2.26-.916-6.043 2.182-6.512l6.843-1.054a3.893 3.893 0 002.898-2.176l2.914-6.277c1.382-2.98 5.578-2.98 6.96 0z"
                fill="url(#paint26_radial_376_26265)"
            />
            <Path
                d="M29.64 7.074l2.914 6.277a3.817 3.817 0 002.898 2.176l6.843 1.054c3.097.47 4.346 4.269 2.182 6.512l-5.212 5.373a3.855 3.855 0 00-1.033 3.314l1.2 7.382c.515 3.18-2.848 5.557-5.662 4l-5.745-3.197a3.797 3.797 0 00-3.73 0l-5.745 3.197c-2.814 1.557-6.178-.82-5.661-4l1.199-7.382a3.855 3.855 0 00-1.033-3.314l-5.212-5.373c-2.181-2.26-.916-6.043 2.182-6.512l6.843-1.054a3.893 3.893 0 002.898-2.176l2.914-6.277c1.382-2.98 5.578-2.98 6.96 0z"
                fill="url(#paint27_radial_376_26265)"
            />
            <Path
                d="M29.64 7.074l2.914 6.277a3.817 3.817 0 002.898 2.176l6.843 1.054c3.097.47 4.346 4.269 2.182 6.512l-5.212 5.373a3.855 3.855 0 00-1.033 3.314l1.2 7.382c.515 3.18-2.848 5.557-5.662 4l-5.745-3.197a3.797 3.797 0 00-3.73 0l-5.745 3.197c-2.814 1.557-6.178-.82-5.661-4l1.199-7.382a3.855 3.855 0 00-1.033-3.314l-5.212-5.373c-2.181-2.26-.916-6.043 2.182-6.512l6.843-1.054a3.893 3.893 0 002.898-2.176l2.914-6.277c1.382-2.98 5.578-2.98 6.96 0z"
                fill="url(#paint28_radial_376_26265)"
            />
            <Path
                d="M29.64 7.074l2.914 6.277a3.817 3.817 0 002.898 2.176l6.843 1.054c3.097.47 4.346 4.269 2.182 6.512l-5.212 5.373a3.855 3.855 0 00-1.033 3.314l1.2 7.382c.515 3.18-2.848 5.557-5.662 4l-5.745-3.197a3.797 3.797 0 00-3.73 0l-5.745 3.197c-2.814 1.557-6.178-.82-5.661-4l1.199-7.382a3.855 3.855 0 00-1.033-3.314l-5.212-5.373c-2.181-2.26-.916-6.043 2.182-6.512l6.843-1.054a3.893 3.893 0 002.898-2.176l2.914-6.277c1.382-2.98 5.578-2.98 6.96 0z"
                fill="url(#paint29_radial_376_26265)"
            />
            <Path
                d="M29.64 7.074l2.914 6.277a3.817 3.817 0 002.898 2.176l6.843 1.054c3.097.47 4.346 4.269 2.182 6.512l-5.212 5.373a3.855 3.855 0 00-1.033 3.314l1.2 7.382c.515 3.18-2.848 5.557-5.662 4l-5.745-3.197a3.797 3.797 0 00-3.73 0l-5.745 3.197c-2.814 1.557-6.178-.82-5.661-4l1.199-7.382a3.855 3.855 0 00-1.033-3.314l-5.212-5.373c-2.181-2.26-.916-6.043 2.182-6.512l6.843-1.054a3.893 3.893 0 002.898-2.176l2.914-6.277c1.382-2.98 5.578-2.98 6.96 0z"
                fill="url(#paint30_radial_376_26265)"
            />
            <Path
                d="M29.64 7.074l2.914 6.277a3.817 3.817 0 002.898 2.176l6.843 1.054c3.097.47 4.346 4.269 2.182 6.512l-5.212 5.373a3.855 3.855 0 00-1.033 3.314l1.2 7.382c.515 3.18-2.848 5.557-5.662 4l-5.745-3.197a3.797 3.797 0 00-3.73 0l-5.745 3.197c-2.814 1.557-6.178-.82-5.661-4l1.199-7.382a3.855 3.855 0 00-1.033-3.314l-5.212-5.373c-2.181-2.26-.916-6.043 2.182-6.512l6.843-1.054a3.893 3.893 0 002.898-2.176l2.914-6.277c1.382-2.98 5.578-2.98 6.96 0z"
                fill="url(#paint31_radial_376_26265)"
            />
            <Path
                d="M29.64 7.074l2.914 6.277a3.817 3.817 0 002.898 2.176l6.843 1.054c3.097.47 4.346 4.269 2.182 6.512l-5.212 5.373a3.855 3.855 0 00-1.033 3.314l1.2 7.382c.515 3.18-2.848 5.557-5.662 4l-5.745-3.197a3.797 3.797 0 00-3.73 0l-5.745 3.197c-2.814 1.557-6.178-.82-5.661-4l1.199-7.382a3.855 3.855 0 00-1.033-3.314l-5.212-5.373c-2.181-2.26-.916-6.043 2.182-6.512l6.843-1.054a3.893 3.893 0 002.898-2.176l2.914-6.277c1.382-2.98 5.578-2.98 6.96 0z"
                fill="url(#paint32_radial_376_26265)"
            />
            <Path
                d="M29.64 7.074l2.914 6.277a3.817 3.817 0 002.898 2.176l6.843 1.054c3.097.47 4.346 4.269 2.182 6.512l-5.212 5.373a3.855 3.855 0 00-1.033 3.314l1.2 7.382c.515 3.18-2.848 5.557-5.662 4l-5.745-3.197a3.797 3.797 0 00-3.73 0l-5.745 3.197c-2.814 1.557-6.178-.82-5.661-4l1.199-7.382a3.855 3.855 0 00-1.033-3.314l-5.212-5.373c-2.181-2.26-.916-6.043 2.182-6.512l6.843-1.054a3.893 3.893 0 002.898-2.176l2.914-6.277c1.382-2.98 5.578-2.98 6.96 0z"
                fill="url(#paint33_radial_376_26265)"
            />
            <Defs>
                <RadialGradient
                    id="paint0_radial_376_26265"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(6.32812 -3.26953 4.53512 8.77766 44.56 33.79)"
                >
                    <Stop offset={0.746579} stopColor="#FFF055" stopOpacity={0} />
                    <Stop offset={1} stopColor="#FFF055" />
                </RadialGradient>
                <LinearGradient
                    id="paint1_linear_376_26265"
                    x1={46.4062}
                    y1={29.5314}
                    x2={47.4115}
                    y2={35.9938}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#FFF659" />
                    <Stop offset={0.324993} stopColor="#FFF659" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint2_linear_376_26265"
                    x1={45.5625}
                    y1={35.4376}
                    x2={51.9724}
                    y2={28.1922}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#E6B54A" />
                    <Stop offset={0.0892553} stopColor="#E6B54A" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint3_linear_376_26265"
                    x1={41.7129}
                    y1={5.63037}
                    x2={40.5527}
                    y2={7.3706}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#FFF659" />
                    <Stop offset={1} stopColor="#FFF659" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint4_linear_376_26265"
                    x1={40.5}
                    y1={5.90638}
                    x2={38.8125}
                    y2={8.43763}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#FFF659" />
                    <Stop offset={0.557692} stopColor="#FFF659" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint5_linear_376_26265"
                    x1={36.2812}
                    y1={8.43763}
                    x2={41.3437}
                    y2={10.1251}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#EAB72B" />
                    <Stop offset={0.170312} stopColor="#EAB72B" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint6_linear_376_26265"
                    x1={16.875}
                    y1={7.59388}
                    x2={13.4967}
                    y2={8.43616}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#FFF659" />
                    <Stop offset={0.363812} stopColor="#FFF659" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint7_linear_376_26265"
                    x1={11.8125}
                    y1={5.90638}
                    x2={13.4943}
                    y2={8.44337}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#FFF659" />
                    <Stop offset={0.363812} stopColor="#FFF659" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint8_linear_376_26265"
                    x1={11.8125}
                    y1={10.9689}
                    x2={14.3398}
                    y2={6.74779}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset={0.071232} stopColor="#E4A829" />
                    <Stop offset={0.20374} stopColor="#E4A829" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint9_linear_376_26265"
                    x1={7.42321}
                    y1={30.1519}
                    x2={7.42321}
                    y2={31.8394}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#DEAE2C" />
                    <Stop offset={1} stopColor="#DEAE2C" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint10_linear_376_26265"
                    x1={9.28125}
                    y1={35.4376}
                    x2={3.375}
                    y2={27.8439}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset={0.0608173} stopColor="#D89C40" />
                    <Stop offset={0.174519} stopColor="#D89C40" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint11_linear_376_26265"
                    x1={29.7422}
                    y1={46.9736}
                    x2={27.8965}
                    y2={47.7651}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#F9C83C" />
                    <Stop offset={1} stopColor="#F9C83C" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint12_linear_376_26265"
                    x1={24.4687}
                    y1={46.4065}
                    x2={36.2812}
                    y2={49.7815}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#D89C40" />
                    <Stop offset={0.0704617} stopColor="#D89C40" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint13_linear_376_26265"
                    x1={26.1562}
                    y1={51.469}
                    x2={26.1562}
                    y2={43.0315}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset={0.09375} stopColor="#D89C40" />
                    <Stop offset={0.18125} stopColor="#D89C40" stopOpacity={0} />
                </LinearGradient>
                <RadialGradient
                    id="paint14_radial_376_26265"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(-18.91917 20.35161 -20.13914 -18.72165 37.976 14.535)"
                >
                    <Stop stopColor="#FFEAAE" />
                    <Stop offset={1} stopColor="#F7C632" />
                </RadialGradient>
                <RadialGradient
                    id="paint15_radial_376_26265"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(90 .955 25.2) scale(19.4063)"
                >
                    <Stop stopColor="#DDBA38" />
                    <Stop offset={1} stopColor="#DDBA38" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint16_radial_376_26265"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(4.86498 -2.7827 3.22847 5.6443 44.868 17.361)"
                >
                    <Stop offset={0.0027979} stopColor="#FFF249" />
                    <Stop offset={0.686538} stopColor="#FFF249" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint17_radial_376_26265"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(45.028 -9.13 63.276) scale(4.91678 11.9712)"
                >
                    <Stop offset={0.125} stopColor="#E5B23C" />
                    <Stop offset={0.95} stopColor="#E5B23C" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint18_radial_376_26265"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(-58.521 48.202 7.99) scale(3.93402 3.93611)"
                >
                    <Stop stopColor="#D69E1F" />
                    <Stop offset={1} stopColor="#D69E1F" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint19_radial_376_26265"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(150.727 21.126 15.293) scale(3.09874 4.07657)"
                >
                    <Stop stopColor="#FFF246" />
                    <Stop offset={1} stopColor="#FFF246" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint20_radial_376_26265"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(3.47499 1.39135 -.69617 1.73873 40.003 43.102)"
                >
                    <Stop stopColor="#FFF246" />
                    <Stop offset={1} stopColor="#FFF246" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint21_radial_376_26265"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(-45.028 34.807 -34.258) scale(12.7836)"
                >
                    <Stop offset={0.113127} stopColor="#FFF249" />
                    <Stop offset={0.298269} stopColor="#FFF249" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint22_radial_376_26265"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(8.33997 -2.7827 5.80904 17.41015 39.308 34.058)"
                >
                    <Stop offset={0.0592265} stopColor="#FFF249" />
                    <Stop offset={0.39519} stopColor="#FFF249" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint23_radial_376_26265"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(11.11996 .69568 -.18783 3.00241 40.003 16.666)"
                >
                    <Stop offset={0.26571} stopColor="#FFF249" />
                    <Stop offset={0.738695} stopColor="#FFF249" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint24_radial_376_26265"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(74.49 10.124 23.72) scale(12.9954 3.50246)"
                >
                    <Stop offset={0.26571} stopColor="#FFF249" />
                    <Stop offset={0.738695} stopColor="#FFF249" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint25_radial_376_26265"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(-98.523 37.664 3.28) scale(14.0689 3.79141)"
                >
                    <Stop offset={0.26571} stopColor="#FFF249" />
                    <Stop offset={0.738695} stopColor="#FFF249" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint26_radial_376_26265"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(74.069 -3.82 17.105) scale(3.15139 10.5083)"
                >
                    <Stop stopColor="#F5DC40" />
                    <Stop offset={1} stopColor="#F5DC40" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint27_radial_376_26265"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(5.25025 1.51518 -1.51791 5.25972 6.75 18.086)"
                >
                    <Stop stopColor="#D69F20" />
                    <Stop offset={1} stopColor="#D69F20" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint28_radial_376_26265"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(115.201 9.249 10.604) scale(15.8526 5.28111)"
                >
                    <Stop offset={0.0888889} stopColor="#D79F21" />
                    <Stop offset={0.738695} stopColor="#D79F21" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint29_radial_376_26265"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(48.041 -21.273 19.348) scale(18.7106 6.64751)"
                >
                    <Stop offset={0.121202} stopColor="#D69E20" />
                    <Stop offset={0.738695} stopColor="#D69E20" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint30_radial_376_26265"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(100.61 -7.916 23.366) scale(11.3244 4.66974)"
                >
                    <Stop offset={0.121202} stopColor="#D69E20" />
                    <Stop offset={0.738695} stopColor="#D69E20" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint31_radial_376_26265"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(-9.73004 4.86968 -2.34925 -4.694 21.238 41.71)"
                >
                    <Stop offset={0.121202} stopColor="#DCA636" />
                    <Stop offset={0.738695} stopColor="#DCA636" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint32_radial_376_26265"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(28.324 -65.786 81.87) scale(10.2638 3.73321)"
                >
                    <Stop offset={0.121202} stopColor="#D69E22" />
                    <Stop offset={0.738695} stopColor="#D69E22" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint33_radial_376_26265"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(119.721 -5.862 24.947) scale(5.60738 7.03269)"
                >
                    <Stop offset={0.144231} stopColor="#D69E20" />
                    <Stop offset={0.738695} stopColor="#D69E20" stopOpacity={0} />
                </RadialGradient>
            </Defs>
        </Svg>
    );
}
