import React from 'react';
import { UserSettings } from '../components/screens/Admin/UserSettings';
import { DrawerContentComponentProps, DrawerContentScrollView, DrawerItemList, DrawerNavigationOptions, createDrawerNavigator } from '@react-navigation/drawer';
import { Image, StyleSheet, Text, View } from 'react-native';
import { imageUtils } from '../utils/imageUtils';
import { mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { QuarterQuestions } from '../components/screens/Admin/QuarterQuestions';
import { ModerateUserQuestions } from '../components/screens/Admin/ModerateUserQuestions';
import { SynthesisArticles } from '../components/screens/Admin/SynthesisArticles';
import { ResourceArticles } from '../components/screens/Admin/ResourceArticles';
import { Notifications } from '../components/screens/Admin/Notifications';
import { AppData } from '../components/screens/Admin/AppData';
import QuestionIcon from '../resources/svgComponents/Admin/QuestionIcon';
import GoodHeartIcon from '../resources/svgComponents/Admin/GoodHeartIcon';
import UsersIcon from '../resources/svgComponents/Admin/UsersIcon';
import ArticleIcon from '../resources/svgComponents/Admin/ArticleIcon';
import BellIcon from '../resources/svgComponents/Admin/BellIcon';
import GolfBallIcon from '../resources/svgComponents/Admin/GolfBallIcon';
import { textStyles } from '../constants/textStyles';

export type AdminNavigatorParamList = {
    QuarterQuestions: { id?: string, initialSet?: string },
    ModerateUserQuestions: {view?: string, page?:string, category?:string},
    UserSettings: undefined,
    SynthesisArticles: undefined,
    ResourceArticles: undefined,
    Notifications: undefined,
    AppData: undefined,
}

const AdminNavigatorDrawer = (props: DrawerContentComponentProps) => {
    return (
        <DrawerContentScrollView {...props} contentContainerStyle={{ alignItems: 'center' }}>
            <Image source={imageUtils.images.appIcon} style={styles.logoImage} resizeMode="contain" />
            <DrawerItemList {...props} />
        </DrawerContentScrollView>
    );
};

const DrawerIcon = ({ Icon, color, height }: { Icon: (props: { color?: string }) => React.JSX.Element, color?: string, height?: number }) => {
    return (
        <View style={[styles.drawerIcon, { height }]}>
            <Icon color={color} />
        </View>
    );
};

const drawerOptions: Record<keyof AdminNavigatorParamList, DrawerNavigationOptions> = {
    QuarterQuestions: {
        drawerLabel: ({ color }) => <Text style={[styles.drawerText, { color }]}>Quarter Questions</Text>,
        drawerIcon: ({ color }) => <DrawerIcon Icon={QuestionIcon} color={color} />,
    },
    ModerateUserQuestions: {
        drawerLabel: ({ color }) => <Text style={[styles.drawerText, { color }]}>Shape Questions</Text>,
        drawerIcon: ({ color }) => <DrawerIcon Icon={GoodHeartIcon} color={color} />,
    },
    UserSettings: {
        drawerLabel: ({ color }) => <Text style={[styles.drawerText, { color }]}>User Settings</Text>,
        drawerIcon: ({ color }) => <DrawerIcon Icon={UsersIcon} color={color} />,
    },
    SynthesisArticles: {
        drawerLabel: ({ color }) => <Text style={[styles.drawerText, { color }]}>Synthesis Articles</Text>,
        drawerIcon: ({ color }) => <DrawerIcon Icon={ArticleIcon} color={color} />,
    },
    ResourceArticles: {
        drawerLabel: ({ color }) => <Text style={[styles.drawerText, { color }]}>Resource Articles</Text>,
        drawerIcon: ({ color }) => <DrawerIcon Icon={ArticleIcon} color={color} />,
    },
    Notifications: {
        drawerLabel: ({ color }) => <Text style={[styles.drawerText, { color }]}>Notifications</Text>,
        drawerIcon: ({ color }) => <DrawerIcon Icon={BellIcon} color={color} />,
    },
    AppData: {
        drawerLabel: ({ color }) => <Text style={[styles.drawerText, { color }]}>App Data</Text>,
        drawerIcon: ({ color }) => <DrawerIcon Icon={GolfBallIcon} color={color} />,
    },
};

const Drawer = createDrawerNavigator<AdminNavigatorParamList>();

const AdminNavigator = () => {

    return (
        <Drawer.Navigator
            screenOptions={{
                headerShown: false,
                drawerType: 'permanent',
                drawerActiveBackgroundColor: '#FEF2EE',
                drawerInactiveTintColor: '#464A4E',
                drawerActiveTintColor: '#F35E2E',
                drawerStyle: styles.drawer,
                drawerItemStyle: styles.drawerItem,
                sceneContainerStyle: styles.sceneContainer,
            }}
            drawerContent={AdminNavigatorDrawer}
        >
            <Drawer.Screen name="QuarterQuestions" component={QuarterQuestions} options={drawerOptions.QuarterQuestions} />
            <Drawer.Screen name="ModerateUserQuestions" component={ModerateUserQuestions} options={drawerOptions.ModerateUserQuestions} />
            <Drawer.Screen name="UserSettings" component={UserSettings} options={drawerOptions.UserSettings} />
            <Drawer.Screen name="SynthesisArticles" component={SynthesisArticles} options={drawerOptions.SynthesisArticles} />
            <Drawer.Screen name="ResourceArticles" component={ResourceArticles} options={drawerOptions.ResourceArticles} />
            <Drawer.Screen name="Notifications" component={Notifications} options={drawerOptions.Notifications} />
            <Drawer.Screen name="AppData" component={AppData} options={drawerOptions.AppData} />
        </Drawer.Navigator>
    );
};

export { AdminNavigator };

const styles = StyleSheet.create({
    logoImage: {
        width: 132,
        height: 132,
        marginVertical: 32,
    },
    sceneContainer: {
        overflow: 'hidden',
    },
    drawer: {
        width: 260,
        borderRightWidth: 0,
        ...mbShadow({
            offsetWidth: 14,
            offsetHeight: 65,
            color: '#7090B0',
            opacity: 0.08,
            radius: 40,
            elevation: 0,
        }),
    },
    drawerItem: {
        width: 237,
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    drawerIcon: {
        width: 32,
        height: 21,
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    drawerText: mbTextStyles([textStyles.normalText, {
        marginLeft: -20,
        textAlign: 'left',
        lineHeight: 21,
    }], { allowLineHeight: true }),
});
