export enum UserRole {
    admin = 'admin',
    user = 'user',
}

export enum ZodiacSign {
    aries = 'aries',
    taurus = 'taurus',
    gemini = 'gemini',
    cancer = 'cancer',
    leo = 'leo',
    virgo = 'virgo',
    libra = 'libra',
    scorpio = 'scorpio',
    sagittarius = 'sagittarius',
    capricorn = 'capricorn',
    aquarius = 'aquarius',
    pisces = 'pisces',
}

export enum AgeGroup {
    lateTeens = 'lateTeens', // 18-21
    '20s' = '20s',
    '30s' = '30s',
    '40s' = '40s',
    '50s+' = '50s+',
}

export enum UserIdentity {
    lesbian = 'lesbian',
    bisexual = 'bisexual',
    queer = 'queer',
}

export enum AccountStatus {
    active = 'active',
    inactive = 'inactive',
    deleted = 'deleted', //deleted by user, soft delete.
}

export enum AdminSortBy {
    featured = 'featured',
    isInStudy = 'isInStudy',
    hasFullAccess = 'hasFullAccess',
}

export enum UniqueUserFields {
    userName = 'userName',
    email = 'email',
}

export enum IdentityDefaultTags {
    lesbian = 'Lesbian',
    bisexual = 'Bisexual',
    queer = 'Queer',
}

export enum AgeGroupDefaultTags {
    lateTeens = 'Late Teens',
    '20s' = '20s',
    '30s' = '30s',
    '40s' = '40s',
    '50s+' = '50s+',
}

export enum ZodiacSignDefaultTags {
    aries = 'Aries',
    taurus = 'Taurus',
    gemini = 'Gemini',
    cancer = 'Cancer',
    leo = 'Leo',
    virgo = 'Virgo',
    libra = 'Libra',
    scorpio = 'Scorpio',
    sagittarius = 'Sagittarius',
    capricorn = 'Capricorn',
    aquarius = 'Aquarius',
    pisces = 'Pisces',
}
export enum UserSelectTags {
    cis = 'Cis',
    trans = 'Trans',
    genderqueer = 'Genderqueer',
    nonBinary = 'Non-binary',
    twoSpirit = 'Two-spirit',
    genderFluid = 'Gender fluid',
    intersex = 'Intersex',
    polysexual = 'Polysexual',
    monogamous = 'Monogamous',
    nonMonogamous = 'Non-monogamous',
    polyamorous = 'Polyamorous',
    demisexual = 'Demisexual',
    pansexual = 'Pansexual',
    demiromantic = 'Demiromantic',
    asexual = 'Asexual',
    aromantic = 'Aromantic',
    masc = 'Masc',
    fem = 'Fem',
    lipstickLesbian = 'Lipstick lesbian',
    chapstickLesbian = 'Chapstick lesbian',
    stem = 'Stem',
    futch = 'Futch',
    butch = 'Butch',
    stud = 'Stud',
    hyperFem = 'Hyper-fem',
    androgynous = 'Androgynous',
    bottom = 'Bottom',
    top = 'Top',
    switch = 'Switch/Vers',
    dominant = 'Dominant',
    submissive = 'Submissive',
    stoneButch = 'Stone Butch',
    pillowPrincess = 'Pillow princess',
    wife = 'Wife',
    mother = 'Mother',
    parent = 'Parent',
    girlfriend = 'Girlfriend',
    partner = 'Partner',
    student = 'Student',
    single = 'Single',
    widow = 'Widow',
    divorced = 'Divorced',
}

export enum BadgeTypes {
    playStreaker = 'playStreaker',
    idealAnswer = 'idealAnswer',
    topReferrer = 'topReferrer',
    topQuestionSubmitter = 'topQuestionSubmitter',
    top100Scorer = 'top100Scorer',
    prizeWinner = 'prizeWinner',
}
