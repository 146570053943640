import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { getNumberOfSteps, getTopInset } from '../../../../utils/shared';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import Feather from 'react-native-vector-icons/Feather';
import ShadowRoundedButton from '../../../../resources/svgComponents/ShadowRoundedButton';
import { Spacer } from '../../../helperComponents/Spacer';
import { ShadowView } from '../../../helperComponents/ShadowView';
import CoinsIcon from '../../../../resources/svgComponents/CoinsIcon';
import MinusSignIcon from '../../../../resources/svgComponents/Quarter/MinusSignIcon';
import PlusSignIcon from '../../../../resources/svgComponents/Quarter/PlusSignIcon';
import Stepper from '../../../helperComponents/Stepper';
import { InnerShadow } from '../../../helperComponents/InnerShadow';
import CoinIcon from '../../../../resources/svgComponents/Quarter/CoinIcon';
import BarSlider from '../../../helperComponents/BarSlider';
import { AnswerType, QuestionToPlay } from '@sapphicsavvy/business';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { Answer } from '../../../../navigations/RootNavigator';

type BetStepProp = {
    onNext?: (answer: Partial<Answer>) => void,
    onBack?: () => void,
    question: QuestionToPlay
    answer: Answer,
    points?: number,
}

export default function BetStep({ question, answer, onNext, onBack, points = 200 }: BetStepProp) {

    const [value, setValue] = useState(15);

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <Text style={styles.headerTitleText}>BET</Text>
                <Spacer />
                <ShadowView style={styles.coins}>
                    <CoinsIcon size={27} />
                    <Spacer width={6} />
                    <Text style={styles.pointsText}>{points}</Text>
                </ShadowView>
            </View>
            <View style={styles.topSection}>
                <Stepper style={styles.topSectionStepper} step={2} max={getNumberOfSteps(question)} />
                <View style={styles.topSectionQuestion}>
                    <CoinIcon />
                    <Spacer height={30} />
                    <Text style={styles.topSectionTitleText}>How many extra points do you want to bet that</Text>
                    <Text style={styles.topSectionTitleBoldText}>{answer.guess}{question.answerType === AnswerType.percentage ? '%' : ''}</Text>
                    <Text style={styles.topSectionTitleText}>is Correct?</Text>
                </View>
            </View>
            <View style={styles.bottomSection}>
                <ShadowView style={styles.bottomSectionBet}>
                    <Text style={styles.bottomSectionBetTitleText}>Enter Extra Bet Value</Text>
                    <InnerShadow style={styles.bottomSectionBetValue}>
                        <Text style={styles.bottomSectionBetValueText}>{value !== undefined ? value : '?'}</Text>
                    </InnerShadow>
                    <Spacer />
                    <BarSlider minValue={0} value={value} maxValue={50} steps={5} onChange={setValue} />
                    <View style={styles.bottomSectionBtns}>
                        <ShadowRoundedButton
                            size={61}
                            borderWidth={15}
                            icon={<MinusSignIcon size={21} />}
                            onPress={() => setValue(prev => Math.max(prev - 5, 0))}
                        />
                        <Spacer width={30} />
                        <ShadowRoundedButton
                            size={61}
                            borderWidth={15}
                            icon={<PlusSignIcon size={21} />}
                            onPress={() => setValue(prev => Math.min(prev + 5, 50))}
                        />
                    </View>
                </ShadowView>
                <Spacer height={15} />
                <View style={styles.bottomSectionNotes}>
                    <ShadowView style={styles.bottomSectionNote}>
                        <Text style={styles.bottomSectionNoteTitle}>Base Value</Text>
                        <Text style={styles.bottomSectionNoteValue}>50</Text>
                    </ShadowView>
                    <Spacer width={11} />
                    <ShadowView style={styles.bottomSectionNote}>
                        <Text style={styles.bottomSectionNoteTitle}>Max Win</Text>
                        <Text style={styles.bottomSectionNoteValue}>{50 + value}</Text>
                    </ShadowView>
                    <Spacer width={11} />
                    <ShadowView style={styles.bottomSectionNote}>
                        <Text style={styles.bottomSectionNoteTitle}>Max Loss</Text>
                        <Text style={styles.bottomSectionNoteValue}>{value > 0 ? `-${value}` : value}</Text>
                    </ShadowView>
                </View>
            </View>
            <View style={styles.footer}>
                <MB_Button
                    style={styles.backBtn}
                    leftElement={<Feather name="chevron-left" color={styles.backBtnText.color} size={20} />}
                    title="BACK"
                    textStyle={styles.backBtnText}
                    onPress={onBack}
                />
                <MB_Button
                    style={styles.nextBtn}
                    rightElement={<Feather name="chevron-right" color={styles.nextBtnText.color} size={20} />}
                    title="NEXT"
                    textStyle={styles.nextBtnText}
                    onPress={() => onNext?.({ extraBet: value })}
                />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        paddingTop: getTopInset(),
        flex: 1,
        backgroundColor: '#EFEFF3',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 20,
        paddingVertical: 10,
    },
    coins: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        width: 111,
        height: 40,
    },
    pointsText: mbTextStyles([textStyles.largerText, {
        fontSize: 25,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        color: '#F35E2E',
    }]),
    headerTitleText: mbTextStyles([textStyles.normalText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        textAlign: 'left',
        fontWeight: '400',
        fontSize: 38,
        color: '#6A2C8C',
    }]),
    topSection: {
        flex: 1,
        backgroundColor: '#6A2C8C',
    },
    topSectionStepper: {
        margin: 2,
    },
    topSectionQuestion: {
        alignItems: 'center',
        padding: 20,
    },
    topSectionTitleText: mbTextStyles([textStyles.largeText, {
        fontSize: 21,
        fontWeight: '600',
    }]),
    topSectionTitleBoldText: mbTextStyles([textStyles.largeText, {
        fontSize: 55,
        fontWeight: '700',
        marginVertical: 14,
    }]),
    bottomSection: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        margin: 15,
    },
    bottomSectionBet: {
        flex: 1,
        alignSelf: 'stretch',
        padding: 12,
    },
    bottomSectionBetTitleText: mbTextStyles([textStyles.normalText, {
        color: '#6A2C8C',
        fontWeight: '700',
        marginBottom: 6,
    }]),
    bottomSectionBetValue: {
        height: 65,
        alignItems: 'center',
        justifyContent: 'center',
    },
    bottomSectionBetValueText: mbTextStyles([textStyles.normalText, {
        fontSize: 35,
        color: '#6A2C8C',
        fontWeight: '700',
    }]),
    bottomSectionBtns: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: -15,
    },
    bottomSectionNotes: {
        flexDirection: 'row',
        height: 70,
    },
    bottomSectionNote: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    bottomSectionNoteTitle: mbTextStyles([textStyles.normalText, {
        color: mbApplyTransparency('#46596B', 0.55),
        fontWeight: '700',
    }]),
    bottomSectionNoteValue: mbTextStyles([textStyles.normalText, {
        color: '#46596B',
        fontWeight: '700',
        fontSize: 25,
        marginTop: 4,
    }]),
    footer: {
        flexDirection: 'row',
        height: 60,
        ...mbShadow({
            offsetWidth: 5,
            offsetHeight: 5,
            radius: 10,
            elevation: 10,
            color: '#AEAEC0',
            opacity: 0.5,
        }),
    },
    backBtn: {
        backgroundColor: '#F0F0F3',
        flex: 1,
        height: '100%',
        paddingBottom: 10,
        borderRadius: 0,
    },
    backBtnText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '800',
        color: '#7E8F9F',
    }]),
    nextBtn: {
        backgroundColor: '#6A2C8C',
        flex: 1,
        height: '100%',
        paddingBottom: 10,
        borderRadius: 0,
    },
    nextBtnText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '800',
        color: '#FFFFFF',
    }]),
});
