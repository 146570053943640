import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

type IconProps = {
    size?: number,
}

export default function IncognitoIcon({ size = 66 }: IconProps) {
    return (
        <Svg
            width={size}
            height={size * (47 / 66)}
            viewBox="0 0 66 47"
            fill="none"
        >
            <G filter="url(#filter0_d_218_28045)">
                <Path
                    d="M28.913 30.546c1.67-.723 3.326-1.128 4.954-1.16a9.8 9.8 0 014.676 1.065 5.177 5.177 0 014.244-2.188c2.844 0 5.154 2.26 5.154 5.053s-2.306 5.053-5.154 5.053-5.153-2.26-5.153-5.053c0-.11.004-.223.01-.332a7.205 7.205 0 00-3.735-.95c-1.327.022-2.714.395-4.161 1.05.005.077.005.15.005.227 0 2.793-2.306 5.053-5.154 5.053-2.843 0-5.153-2.26-5.153-5.053 0-2.792 2.305-5.053 5.153-5.053a5.19 5.19 0 014.314 2.288zM20.652 2.461C23.23-.577 24.223.36 27.364 1.192c3.924 1.037 7.756 1.292 11.828.146 3.03-.855 4.356-2.042 7.088.946C48.29 4.476 49.537 8 50.283 10.21a42.728 42.728 0 011.874 8.119H15.23c1.317-6.495 2.9-12.33 5.423-15.869zM6.763 20.135h52.844c1.146 0 2.078.92 2.078 2.038 0 1.123-.937 2.038-2.078 2.038H6.763c-1.14 0-2.078-.915-2.078-2.038 0-1.119.937-2.038 2.078-2.038z"
                    fill="#fff"
                />
            </G>
        </Svg>
    );
}
