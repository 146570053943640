import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import OveviewScreen from '../../helperComponents/OveviewScreen';
import { FlatList, Image, ListRenderItemInfo, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../constants/textStyles';
import { StandingWithNumberIcon } from '../../../resources/svgComponents/StandingWithNumberIcon';
import { trpc } from '../../../apiCalls/trpcClient';
import { TopScoresType, UserDataForGetTopScores } from '@sapphicsavvy/business';
import { MB_TabView, MB_TabViewRef } from '@mightybyte/rnw.components.tab-view';
import { ShadowView } from '../../helperComponents/ShadowView';
import { Spacer } from '../../helperComponents/Spacer';
import { UIActivityIndicator } from '@mightybyte/rnw.components.activity-indicators';
import { mbGetMediumImage } from '@mightybyte/rnw.components.image';
import { numberWithCommas } from '../../../utils/shared';
import { MedalIcon } from '../../../resources/svgComponents/MedalIcon';


function StandingsView({ type }: { type: TopScoresType }) {
    const { data, isLoading } = trpc.quarter.getTopResults.useQuery({ type });

    const renderItem = useCallback(({ item, index }: ListRenderItemInfo<UserDataForGetTopScores>) => {
        const image = mbGetMediumImage(item.avatarUrl, { asUri: true });
        return (
            <ShadowView style={styles.item}>
                <Text style={styles.itemTankText}>{index + 1}</Text>
                <View>
                    <Image source={image} style={styles.itemImage} />
                    {index === 0 && <MedalIcon style={styles.itemImageMedal} type="gold" />}
                    {index === 1 && <MedalIcon style={styles.itemImageMedal} type="silver" />}
                    {index === 2 && <MedalIcon style={styles.itemImageMedal} type="bronze" />}
                </View>
                <Text style={styles.itemUsernameText}>{item.userName ?? 'N/A'}</Text>
                <Spacer />
                <Text style={styles.itemPointsText}>{numberWithCommas(item.points)}</Text>
            </ShadowView>
        );
    }, []);

    const EmptyComponent = useCallback(() => {
        if (isLoading) {
            return (
                <View>
                    <UIActivityIndicator size={30} color="#F35E2E" />
                </View>
            );
        }
        return (
            <View>
                <Text style={styles.emptyText}>No result available.</Text>
            </View>
        );
    }, [isLoading]);

    const keyExtractor = useCallback((item: UserDataForGetTopScores) => item.userId, []);
    const itemSeparator = useCallback(() => <Spacer height={15} />, []);

    return (
        <FlatList
            data={(data?.scores ?? []) as UserDataForGetTopScores[]}
            renderItem={renderItem}
            ItemSeparatorComponent={itemSeparator}
            keyExtractor={keyExtractor}
            contentContainerStyle={styles.flatListContainer}
            style={styles.flatList}
            ListEmptyComponent={EmptyComponent}
        />
    );
}

export default function Standings() {
    const titles = useRef(['Last Quarter', 'This Set']).current;
    const tabViewRef = useRef<MB_TabViewRef>(null);

    const [currentTab, setCurrentTab] = useState<0 | 1>(0);

    const changeTab = useCallback(() => setCurrentTab(prevTab => {
        const newTab = prevTab === 0 ? 1 : 0;
        tabViewRef.current?.navigateToTab(newTab);
        return newTab;
    }), []);

    const screens = useMemo(() => [
        () => (<StandingsView type={TopScoresType.lastQuarter} />),
        () => (<StandingsView type={TopScoresType.currentSet} />),
    ], []);

    return (
        <OveviewScreen
            showBackBtn
            topSectionStyle={styles.topSection}
            topSectionChildren={
                <Fragment>
                    <StandingWithNumberIcon size={80} />
                    <Text style={styles.titleText}>STANDINGS</Text>
                    <Text style={styles.subTitleText}>Top 100 Savviest Sapphics</Text>
                    <View style={styles.tabs}>
                        <TouchableOpacity
                            style={[styles.tabsBtn, currentTab === 0 && styles.tabsBtnActive]}
                            onPress={changeTab}
                            activeOpacity={0.5}
                        >
                            <Text style={[styles.tabsBtnText, currentTab === 0 && styles.tabsBtnTextActive]}>Last Quarter</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={[styles.tabsBtn, currentTab === 1 && styles.tabsBtnActive]}
                            onPress={changeTab}
                            activeOpacity={0.5}
                        >
                            <Text style={[styles.tabsBtnText, currentTab === 1 && styles.tabsBtnTextActive]}>This Set</Text>
                        </TouchableOpacity>
                    </View>
                </Fragment>
            }
            bottomSectionStyle={styles.bottomSection}
        >
            <MB_TabView
                MB_Ref={tabViewRef}
                titles={titles}
                screens={screens}
                tabStyle={styles.tab}
                swipeEnabled={false}
            />
        </OveviewScreen>
    );
}

const styles = StyleSheet.create({
    topSection: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 20,
        height: '35%',
    },
    bottomSection: {
        height: '65%',
        paddingTop: 0,
    },
    titleText: mbTextStyles([textStyles.largestText, {
        fontSize: 45,
        fontFamily: FONT_STYLES.Tabasco_400,
        color: '#FFFFFF',
        fontWeight: '400',
        marginTop: 18,
    }]),
    subTitleText: mbTextStyles([textStyles.largeText, {
        color: '#FFFFFF',
        fontSize: 18,
        fontWeight: '700',
        marginBottom: 20,
    }]),
    tab: {
        display: 'none',
    },
    tabs: {
        height: 54,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        borderWidth: 1,
        borderRadius: 10,
        paddingHorizontal: 7,
        borderColor: mbApplyTransparency('#FFFFFF', 0.55),
        ...mbShadow({
            offsetHeight: 2,
            offsetWidth: 2,
            color: '#565656',
            opacity: 0.50,
            elevation: 20,
            radius: 20,
        }),
    },
    tabsBtn: {
        height: 40,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
    },
    tabsBtnActive: {
        backgroundColor: '#F35E2E',
    },
    tabsBtnText: mbTextStyles([textStyles.smallText, {
        textAlign: 'left',
        color: mbApplyTransparency('#6D6E71', 0.95),
    }]),
    tabsBtnTextActive: {
        color: '#F8F8F8',
    },

    // standings view
    flatList: {
        borderTopLeftRadius: 40,
        borderTopRightRadius: 40,
    },
    flatListContainer: {
        paddingTop: 30,
        paddingHorizontal: 20,
        paddingBottom: 40,
    },
    emptyText: mbTextStyles([textStyles.largeText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        color: '#F35E2E',
        fontWeight: '400',
    }]),
    item: {
        flexDirection: 'row',
        padding: 15,
        alignItems: 'center',
    },
    itemTankText: mbTextStyles([textStyles.normalText, {
        color: '#000000',
    }]),
    itemUsernameText: mbTextStyles([textStyles.normalText, {
        color: '#0C092A',
    }]),
    itemPointsText: mbTextStyles([textStyles.largeText, {
        fontSize: 25,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        color: '#F35E2E',
    }]),
    itemImage: {
        height: 56,
        width: 56,
        borderRadius: 110,
        marginHorizontal: 10,
    },
    itemImageMedal: {
        position: 'absolute',
        bottom: -10,
        right: 5,
    },
});
