import React from 'react';
import { ComponentWrapper } from '../../../helperComponents/componentWrapper/ComponentWrapper';
import sharedStyles from '../Shared/styles';
import { Header } from '../Shared/Header';
import StandingIcon from '../../../../resources/svgComponents/StandingIcon';
import { trpc } from '../../../../apiCalls/trpcClient';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import Fontisto from 'react-native-vector-icons/Fontisto';
import Card from './components/Card';
import CoinsIcon from '../../../../resources/svgComponents/CoinsIcon';
import { StandingWithNumberIcon } from '../../../../resources/svgComponents/StandingWithNumberIcon';
import StatsIcon from '../../../../resources/svgComponents/StatsIcon';
import { Spacer } from '../../../helperComponents/Spacer';
import { useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../../../navigations/RootNavigator';

const Results = () => {
    const { data: haveToPlay } = trpc.quarter.haveToPlay.useQuery();
    const isLocked = haveToPlay ? !haveToPlay.isPlayed : false;
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

    return (
        <ComponentWrapper
            containerStyle={[sharedStyles.container, styles.container]}
        >
            <Header title="RESULTS" icon={<StandingIcon size={30} style={{ marginBottom: 10 }} />} />
            {isLocked &&
                <View style={styles.subHeader}>
                    <Fontisto name="locked" color={mbApplyTransparency('#292E30', 0.55)} size={14} />
                    <Text style={styles.subHeaderText}>Play Quarter to unlock</Text>
                </View>
            }
            <View>
                <View style={styles.headerTopLine} />
                <View style={styles.headerBottomLine} />
            </View>
            <View style={styles.cards}>
                <Card
                    icon={<CoinsIcon size={40} />}
                    color="#0295CD"
                    title="Scores"
                    text="Check Out Latest Scores from Recent Matches"
                    isLocked={isLocked}
                    onPress={() => navigation.navigate('ScoreAndResults')}
                />
                <Spacer height={15} />
                <Card
                    icon={<StandingWithNumberIcon />}
                    color="#F35E2E"
                    title="Standings"
                    text="View Current and Previous Quarter Standings"
                    isLocked={isLocked}
                    onPress={() => navigation.navigate('Standings')}
                />
                <Spacer height={15} />
                <Card
                    icon={<StatsIcon />}
                    color="#6A2C8C"
                    title="Stats"
                    text="Explore Comprehensive Statistics on your answers"
                    isLocked={isLocked}
                    onPress={() => navigation.navigate('Stats')}
                />
            </View>
        </ComponentWrapper>
    );

};

export { Results };

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 20,
    },
    headerTopLine: {
        height: 1,
        backgroundColor: mbApplyTransparency('#FFFFFF', 0.50),
        marginHorizontal: -20,
        ...mbShadow({
            offsetHeight: -1,
            offsetWidth: 0,
            color: '#FFFFFF',
            opacity: 0.25,
            elevation: 1,
            radius: 10,
        }),
    },
    headerBottomLine: {
        height: 1,
        backgroundColor: mbApplyTransparency('#8E9396', Platform.OS === 'ios' ? 1 : 0.50),
        marginHorizontal: -20,
        ...mbShadow({
            offsetHeight: 1,
            offsetWidth: 0,
            color: '#000000',
            opacity: 0.05,
            elevation: 1,
            radius: 10,
        }),
    },
    subHeader: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: -10,
        marginBottom: 15,
    },
    subHeaderText: mbTextStyles([textStyles.smallText, {
        color: mbApplyTransparency('#292E30', 0.55),
        marginLeft: 5,
    }]),
    cards: {
        flexGrow: 1,
        justifyContent: 'center',
    },
});
