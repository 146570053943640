import React, { useCallback, useEffect } from 'react';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { ShapeDataForAdminClient } from '@sapphicsavvy/business';
import { StyleSheet, Text, View } from 'react-native';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { trpc } from '../../../../apiCalls/trpcClient';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import { Spacer } from '../../../helperComponents/Spacer';
import { getColors } from '../../../../constants/colors';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';

const COLORS = getColors();


type Props = {
    isVisible: boolean;
    questionInfo?: ShapeDataForAdminClient;
    onDismiss?: () => void;
    showExtraButton?: boolean;
};

const EditShapeQuestionModal = ({ isVisible, questionInfo, onDismiss, showExtraButton }: Props) => {
    const trpcUtils = trpc.useUtils();

    const { mutate: editQuestion, isLoading } = trpc.admin.shape.edit.useMutation();
    const [text, setText] = React.useState(questionInfo?.question ?? '');

    useEffect(() => {
        setText(questionInfo?.question ?? '');
    }, [questionInfo?.question]);


    const [error, setError] = React.useState<string | undefined>(undefined);

    useEffect(() => {
        setError(undefined);
    }, [text]);

    const onSave = useCallback((saveAndApprove: boolean) => {
        if (!questionInfo) {
            return;
        }
        if (text.length === 0){
            setError('Question can\'t be empty');
            return;
        }

        const payload = {
            questionId: questionInfo?.id,
            question: text,
            shouldApprove: saveAndApprove,
        };
        editQuestion(payload, {
            onSuccess: () => {
                trpcUtils.admin.shape.getPage.invalidate();
                onDismiss && onDismiss();
            },
            onError: (err:any) => {
                setError(err?.message ?? 'An error occurred');
            },
        });
    }, [editQuestion, onDismiss, questionInfo, text, trpcUtils.admin.shape.getPage]);

    return (
        <MB_Modal
            isVisible={isVisible}
            onDismiss={onDismiss}
            hideCloseButton
            childrenWrapperStyle={styles.container}
        >
            <View style={styles.header}>
                <Text style={[styles.text, styles.primaryText]}>Edit Question</Text>
            </View>

            <View style={styles.row}>
                <Text style={[styles.subText]}>{`Question by ${questionInfo?.submittedBy?.userName}`}</Text>
            </View>

            <MB_TextInput title={'Edit Question'}
                          value={text}
                          multiline={true}
                          onChangeText={setText}
                          style={{marginTop: 20, width: '100%'}}
                          titleStyle={mbTextStyles([textStyles.smallerText, {color: '#000', textAlign: 'left', fontSize: 14}])}
                          textInputStyle={mbTextStyles([textStyles.normalText, {color: '#1E1E1E', textAlign: 'left', fontSize: 14}])}

                       />
            <Spacer height={10} />
            <View style={styles.errorContainer}>
                {<Text style={styles.errorText}>{error ?? ''}</Text>}
            </View>
            <View style={styles.footer}>
                {showExtraButton ? <>
                <MB_Button
                    title={'Save & Approve'}
                    style={[styles.primaryBtn, {width: 160}]}
                    textStyle={styles.primaryBtnText}
                    onPress={()=>onSave(true)}
                    disabled={isLoading}
                    loading={isLoading}
                />
                    <Spacer width={16} />
                </> : null}

                <MB_Button
                    title={'Save'}
                    style={styles.primaryBtn}
                    textStyle={styles.primaryBtnText}
                    onPress={()=>onSave(false)}
                    disabled={isLoading}
                    loading={isLoading}
                />
                <Spacer width={16} />
                <MB_Button
                    title="Cancel"
                    style={styles.secondaryBtn}
                    textStyle={[styles.primaryBtnText, styles.secondaryBtnText]}
                    onPress={onDismiss}
                    disabled={isLoading}
                />
            </View>
        </MB_Modal>
    )
};

export { EditShapeQuestionModal };


const styles = StyleSheet.create({
    container: {
        width: 600,
        paddingTop: 20,
        paddingBottom: 0,
        paddingHorizontal: 20,
        alignItems: 'stretch',
    },
    header: {
        height: 70,
        paddingHorizontal: 15,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FEF2EE',
        marginHorizontal: -20,
        marginTop: -20,
        marginBottom: 20,
    },
    text: mbTextStyles([textStyles.normalText, {
        color: '#000000',
        textAlign: 'left',
    }]),
    primaryText: {
        fontWeight: '600',
        fontSize: 18,
        color: '#F35E2E',
    },
    subText: mbTextStyles([textStyles.smallerText, {
        fontSize: 14,
        color: mbApplyTransparency('#000000', 0.5),
        textAlign: 'left',
    }]),
    footer: {
        height: 95,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#F5F5F5',
        paddingHorizontal: 15,
        flexDirection: 'row',
        marginHorizontal: -20,
    },
    primaryBtn: {
        width: 100,
        height: 49,
        backgroundColor: '#F35E2E',
        borderRadius: 8,
        borderWidth: 0,
    },
    primaryBtnText: mbTextStyles([textStyles.normalText, {
        textAlign: 'left',
        color: '#FFFFFF',
    }]),
    secondaryBtn: {
        width: 100,
        height: 49,
        borderRadius: 8,
        backgroundColor: '#FFFFFF',
        borderWidth: 2,
        borderColor: '#F35E2E',
    },
    secondaryBtnText: {
        color: '#F35E2E',
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    errorContainer: {
        height: 20,
        alignItems: 'center',
        marginBottom: 20,
    },
    errorText: mbTextStyles([textStyles.normalText, {
        color: COLORS.red,
    }]),
});
