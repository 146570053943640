import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { ShadowView } from '../../../helperComponents/ShadowView';
import ShadowRoundedButton from '../../../../resources/svgComponents/ShadowRoundedButton';
import MinusSignIcon from '../../../../resources/svgComponents/Quarter/MinusSignIcon';
import PlusSignIcon from '../../../../resources/svgComponents/Quarter/PlusSignIcon';
import BarSlider from '../../../helperComponents/BarSlider';
import { textStyles } from '../../../../constants/textStyles';
import { AnswerType } from '@sapphicsavvy/business';
import { Spacer } from '../../../helperComponents/Spacer';
import { capitalize } from '../../../../utils/shared';

type SliderCardProps = {
    title: string
    value: number,
    onChange?: (value: number) => void,
    steps?: number,
    min?: number,
    max?: number,
    answerType: AnswerType,
    strictMax?: boolean,
}

export default function ColumnSliderCard({ title, value, onChange, steps = 1, min = 0, max = 100, answerType, strictMax }: SliderCardProps) {
    const formattedValue = (value === max && !strictMax) ? `${value}+` : value;
    return (
        <ShadowView style={styles.sliderCard}>
            <Text style={styles.sliderCardText}>{capitalize(title)}</Text>
            <View style={[answerType === AnswerType.percentage && { alignItems: 'center' }]}>
                {answerType === AnswerType.numeric ?
                    <BarSlider
                        colors={[mbApplyTransparency('#FCAF41', 0.95), mbApplyTransparency('#FCAF41', 0.95)]}
                        value={value}
                        minValue={min}
                        maxValue={max}
                        disableLimit
                        steps={steps}
                        onChange={onChange}
                        showPlus={!strictMax}
                    />
                    :
                    <View style={styles.bottomSectionAnswerBtns}>
                        <ShadowView
                            style={[styles.bottomSectionAnswerBtn, value === 0 && styles.activeBtn]}
                            onPress={() => onChange?.(0)}
                        >
                            <Text style={[styles.bottomSectionAnswerBtnText, value === 0 && styles.activeText]}>NO</Text>
                        </ShadowView>
                        <Spacer width={15} />
                        <ShadowView
                            style={[styles.bottomSectionAnswerBtn, value === 1 && styles.activeBtn]}
                            onPress={() => onChange?.(1)}
                        >
                            <Text style={[styles.bottomSectionAnswerBtnText, value === 1 && styles.activeText]}>YES</Text>
                        </ShadowView>
                    </View>
                }
                {answerType === AnswerType.numeric &&
                    <View style={styles.sliderCardBtns}>
                        <ShadowRoundedButton
                            size={44}
                            borderWidth={10}
                            icon={<MinusSignIcon size={15} />}
                            onPress={() => onChange?.(Math.max(min, value - steps))}
                        />
                        {answerType === AnswerType.numeric ?
                            <Text style={styles.sliderCardValue}>{formattedValue}</Text>
                            :
                            <Spacer width={15} />
                        }
                        <ShadowRoundedButton
                            size={44}
                            borderWidth={10}
                            icon={<PlusSignIcon size={15} />}
                            onPress={() => onChange?.(Math.min(max, value + steps))}
                        />
                    </View>
                }
            </View>
        </ShadowView>
    );
}

const styles = StyleSheet.create({
    sliderCard: {
        padding: 13,
    },
    sliderCardText: mbTextStyles([textStyles.largeText, {
        fontSize: 18,
        fontWeight: '500',
        color: mbApplyTransparency('#000000', 0.95),
        marginBottom: 15,
    }]),
    sliderCardValue: mbTextStyles([textStyles.largeText, {
        fontSize: 22,
        fontWeight: '700',
        color: mbApplyTransparency('#000000', 0.95),
        marginHorizontal: 13,
        width: 44,
    }]),
    sliderCardBtns: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 15,
    },
    bottomPercentageText: mbTextStyles([textStyles.normalText, {
        fontSize: 45,
        fontWeight: '700',
        color: '#F35E2E',
        marginBottom: 15,
    }]),
    bottomSectionBtns: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: -15,
    },
    bottomSectionAnswerBtns: {
        flexDirection: 'row',
        paddingHorizontal: 20,
        justifyContent: 'center',
    },
    bottomSectionAnswerBtn: {
        width: 130,
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderColor: mbApplyTransparency('#FFFFFF', 0.55),
    },
    activeBtn: {
        backgroundColor: '#EFE7E8',
    },
    activeText: {
        color: '#F3522B',
    },
    bottomSectionAnswerBtnText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '700',
        color: '#46596B',
    }]),
});
