import {
    GetListOfUsersInput,
    GetListOfShapeQuestionsInputType,
} from '@sapphicsavvy/business';
import { trpc } from '../apiCalls/trpcClient';
import { utils } from './utils';

type TrpcUtils = ReturnType<typeof trpc.useUtils>;
type ToggleLikeMutationInput = Omit<GetListOfUsersInput, 'limit' | 'cursor'>;

export function mutateToggleLike(
    trpcUtils: TrpcUtils,
    inputs: ToggleLikeMutationInput[],
) {
    const mutate = (userId: string, input: ToggleLikeMutationInput) => {
        trpcUtils.sapphics.listOfUsers.setInfiniteData(input, (data) => {
            if (!data) {
                return data;
            }
            return {
                pageParams: data?.pageParams,
                pages: data?.pages.map((page) => ({
                    items: input.liked
                        ? page.items.filter((item) => item.id !== userId)
                        : page.items.map((item) => ({
                              ...item,
                              liked:
                                  item.id === userId ? !item.liked : item.liked,
                          })),
                    nextCursor: page.nextCursor,
                })),
            };
        });
    };
    return {
        onError: () => {
            utils.showToast('error', 'An error occurred. Please try again');
            trpcUtils.sapphics.listOfUsers.invalidate();
        },
        onMutate({ userId }: { userId: string }) {
            inputs.forEach((input) => mutate(userId, input));
        },
    };
}

type UpVoteQuestionMutationInput = Omit<
    GetListOfShapeQuestionsInputType,
    'limit' | 'cursor'
>;

export function mutateUpVoteQuestion(
    trpcUtils: TrpcUtils,
    inputs: UpVoteQuestionMutationInput[],
) {
    const mutate = (questionId: string, input: UpVoteQuestionMutationInput) => {
        trpcUtils.shape.list.setInfiniteData(input, (data) => {
            if (!data) {
                return data;
            }
            return {
                pageParams: data?.pageParams,
                pages: data?.pages.map((page) => ({
                    items: page.items.map((item) => ({
                        ...item,
                        hasVoted:
                            item.id === questionId
                                ? !item.hasVoted
                                : item.hasVoted,
                        votesTotal:
                            item.id === questionId
                                ? item.hasVoted
                                    ? item.votesTotal - 1
                                    : item.votesTotal + 1
                                : item.votesTotal,
                    })),
                    nextCursor: page.nextCursor,
                })),
            };
        });
    };
    return {
        onError: () => {
            utils.showToast('error', 'An error occurred. Please try again');
            trpcUtils.shape.list.invalidate();
        },
        onMutate({ questionId }: { questionId: string }) {
            inputs.forEach((input) => mutate(questionId, input));
        },
    };
}
