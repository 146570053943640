import {  useMemo, useState, useCallback } from 'react';
import {View, Text, StyleSheet, TouchableOpacity} from 'react-native';
import {mbTextStyles} from '@mightybyte/rnw.utils.style-utils';
import {textStyles} from '../../constants/textStyles';


type Props = {
    options: { label: string, value: string, action: (screen: any)=>void }[]
    initialSelectedIndex?: number
}

const ButtonFilter = ({options, initialSelectedIndex}: Props) => {
    const [selectedIndex, setSelectedIndex] = useState(initialSelectedIndex || 0);

    const onChange = useCallback((index: number) => {
        const action = options[index]?.action;
        const value = options[index]?.value;

        if (action) {
            action(value);
        }

        setSelectedIndex(index);
    }, [options]);

    const Component = useMemo(() => {
       return (
           <View style={[styles.row]}>
               {options.map((option, index) => (
                   <TouchableOpacity
                       key={`${index}-${option.value}`}
                       style={[
                           styles.item,
                           selectedIndex === index ? styles.activeItem : {},
                       ]}
                       onPress={() => onChange(index)}>
                       <Text style={[
                           styles.text,
                           selectedIndex === index ? styles.activeText : {},
                       ]}>
                           {option.label}
                          </Text>

                   </TouchableOpacity>
               ))}
           </View>
       );
    }, [options, selectedIndex, onChange]);

    return (
        {
            IUComponent: Component,
            selectedValue: options[selectedIndex]?.value,
        }
    );
};

export {ButtonFilter};

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    item: {
        paddingHorizontal: 10,
        paddingVertical: 10,
        borderRadius: 18,
        borderWidth: 1,
        backgroundColor: '#FFF',
        borderColor: '#F35E2E',
        marginRight: 10,
    },
    text: mbTextStyles([
        textStyles.normalText,
        { fontSize: 14, color: '#000', textAlign: 'center' },
    ]),
    activeItem: {
        backgroundColor: '#F35E2E',
    },
    activeText: {
        color: '#FFFFFF',
    },
});
