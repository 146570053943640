export const APIError = {
    internalServerError: {
        code: 'INTERNAL_SERVER_ERROR',
        statusCode: 500,
        message: 'Something went wrong.',
    },

    loginFailed: {
        code: 'LOGIN_FAILED',
        statusCode: 400,
        message: 'Phone number or password does not match.',
    },

    deletedAccount: {
        code: 'DELETED_ACCOUNT',
        statusCode: 400,
        message: 'Account is deleted.',
    },

    invalidParameter: {
        code: 'INVALID_PARAMETER',
        statusCode: 400,
        message: 'One or more parameters are invalid',
    },

    emailAlreadyInUse: {
        code: 'EMAIL_ALREADY_IN_USE',
        statusCode: 400,
        message: 'The provided email address is already in use.',
    },

    phoneNumberAlreadyInUse: {
        code: 'PHONE_NUMBER_ALREADY_IN_USE',
        statusCode: 400,
        message: 'The provided phone number is already in use.',
    },

    phoneNumberIsVerified: {
        code: 'PHONE_NUMBER_IS_VERIFIED',
        statusCode: 400,
        message: 'The phone number has already been verified.',
    },

    phoneNumnerIsNotVerified: {
        code: 'PHONE_NUMBER_IS_NOT_VERIFIED',
        statusCode: 400,
        message: 'You need to verify your phone number first.',
    },

    wrongPhoneNumber: {
        code: 'WRONG_PHONE_NUMBER',
        statusCode: 400,
        message: 'The provided phone number is invalid.',
    },

    signUpFailed: {
        code: 'SIGN_UP_FAILED',
        statusCode: 500,
        message: 'Failed to create a new user',
    },

    accessDenied: {
        code: 'ACCESS_DENIED',
        statusCode: 403,
        message: 'You do not have permission to perform this task.',
    },

    inactiveUser: {
        code: 'INACTIVE_USER',
        statusCode: 401,
        message: 'Your account is inactive.',
    },

    invalidAccessToken: {
        code: 'INVALID_ACCESS_TOKEN',
        statusCode: 401,
        message: 'The provided access token is invalid.',
    },

    expiredAccessToken: {
        code: 'EXPIRED_ACCESS_TOKEN',
        statusCode: 401,
        message: 'The provided access token expired.',
    },

    expiredPasswordRecoveryToken: {
        code: 'EXPIRED_PASSWORD_RECOVERY_TOKEN',
        statusCode: 401,
        message: 'The provided password recovery token expired.',
    },

    invalidRefreshToken: {
        code: 'INVALID_REFRESH_TOKEN',
        statusCode: 401,
        message:
            'The provided refresh token is invalid, please try signing in again.',
    },
    invalidPassword: {
        code: 'INVALID_PASSWORD',
        statusCode: 400,
        message: 'Old password was invalid',
    },

    invalidVerificationCode: {
        code: 'INVALID_VERIFICATION_CODE',
        statusCode: 400,
        message: 'The provided verification code is invalid.',
    },

    expiredVerificationCode: {
        code: 'EXPIRED_VERIFY_PHONE_TOKEN',
        statusCode: 401,
        message: 'The provided token is invalid. Please request a new one.',
    },

    invalidSignUpCode: {
        code: 'INVALID_SIGN_UP_CODE',
        statusCode: 400,
        message: 'Sign up code was invalid',
    },

    signUpCodeExpired: {
        code: 'SIGN_UP_CODE_EXPIRED',
        statusCode: 400,
        message: 'Sign up code expired',
    },

    notFound: {
        code: 'NOT_FOUND',
        statusCode: 404,
        message: 'Resource not found.',
    },

    requestBatchingNotAllowed: {
        code: 'REQUEST_BATCHING_NOT_ALLOWED',
        statusCode: 400,
        message: 'Request batching is not allowed for this call.',
    },

    shortPassword: {
        code: 'SHORT_PASSWORD',
        statusCode: 400,
        message: 'Your password must be at least 8 letters.',
    },

    confirmPasswordFailed: {
        code: 'CONFIRM_PASSWORD_FAILED',
        statusCode: 400,
        message: 'Password and Confirm Password do not match.',
    },
    selfLike: {
        code: 'CANT_LIKE_YOURSELF',
        statusCode: 400,
        message: 'You can not like yourself.',
    },
    quarterAlreadyExists: {
        code: 'QUARTER_ALREADY_EXISTS',
        statusCode: 400,
        message:
            'Quarter with provided date already exists. Please modify the existing one or select a different start date.',
    },

    deleteIsNotAllowed: {
        code: 'DELETE_IS_NOT_ALLOWED',
        statusCode: 400,
        message: 'You can not delete this record.',
    },

    canUpdateOnlyDraft: {
        code: 'CAN_UPDATE_ONLY_DRAFT',
        statusCode: 400,
        message: 'Quarter is not in draft status. You can not update it.',
    },
    cantSubmitAnswersToQuarter: {
        code: 'CANT_SUBMIT_ANSWERS_TO_QUARTER',
        statusCode: 400,
        message: 'This quarter game is not active. You can not submit answers.',
    },
    badRequest: {
        code: 'BAD_REQUEST',
        statusCode: 400,
        message: 'Bad request.',
    },
};
