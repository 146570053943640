import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

type PlusSignIconProps = {
    size?: number,
    color?: string
}

export default function PlusSignIcon({ size = 31, color = '#8C9FB1' }: PlusSignIconProps) {
    return (
        <Svg
            width={size}
            height={size}
            viewBox="0 0 31 31"
            fill="none"
        >
            <G>
                <Path
                    d="M15.304 27.003V3.891"
                    stroke={color}
                    strokeWidth={6.35294}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </G>
            <G>
                <Path
                    d="M3.748 15.447h23.113"
                    stroke={color}
                    strokeWidth={6.35294}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </G>
        </Svg>
    );
}
