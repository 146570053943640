import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type IconProps = {
  size?: number,
}

export default function HealthyHeartIcon({ size = 43 }: IconProps) {
  return (
    <Svg
      width={size}
      height={size * (35 / 43)}
      viewBox="0 0 43 35"
      fill="none"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.104 6.16c2.77-2.839 4.71-5.294 8.977-5.774 8.013-.905 15.382 7.162 11.336 15.103-1.151 2.262-3.496 4.952-6.09 7.591-2.846 2.898-5.996 5.738-8.202 7.89l-2.578 2.515a5 5 0 01-6.929.052l-1.483-1.404C10.153 26.467.397 19.336.075 10.5-.148 4.31 4.818.345 10.53.417 15.634.485 17.78 2.98 21.104 6.16zm-7.441 11.622a1.879 1.879 0 01-.055-2.694c.37-.377.86-.575 1.355-.585.494-.01.996.167 1.38.53l2.946 2.776 7.006-6.4a1.974 1.974 0 011.439-.524l.024.003c.487.03.961.245 1.314.63.356.39.518.887.49 1.373l-.003.024a1.89 1.89 0 01-.619 1.272l-8.334 7.638a.32.32 0 01-.048.048c-.373.323-.844.48-1.31.473a1.939 1.939 0 01-1.308-.53l-4.278-4.034z"
        fill="#fff"
      />
    </Svg>
  );
}
