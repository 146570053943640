import React, { useState } from 'react';
import { AnswerType, BadgeTypes, QuestionResultToView, ResultType } from '@sapphicsavvy/business';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { mbGetMediumImage } from '@mightybyte/rnw.components.image';
import Layout from '../components/Layout';
import { Image, Platform, StyleSheet, Text, View } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import SliderResultCard from '../components/SliderResultCard';
import { Spacer } from '../../../helperComponents/Spacer';
import { imageUtils } from '../../../../utils/imageUtils';
import { capitalize, getNumberOfSteps } from '../../../../utils/shared';
import MostIdealBadgeModal from '../modals/MostIdealBadgeModal';

type IdealBonusStepProps = {
    onNext?: () => void
    onBack?: () => void,
    points: number,
    results: QuestionResultToView
}

export default function IdealBonusStep({ points, onBack, onNext, results }: IdealBonusStepProps) {

    const { currentUserData } = useSignedInContext();
    const imageUri = mbGetMediumImage(results.imageUrl, { asUri: true });

    const userAnswer = results.idealResult?.userAnswer || 0;
    const surveyResult = results.idealResult?.surveyResult || 0;

    const [isBadgeModalVisible, setIsBadgeModalVisible] = useState(results.idealResult?.badge === BadgeTypes.idealAnswer);

    return (
        <Layout
            title="IDEAL BONUS"
            titleStyle={styles.title}
            points={points}
            numberOfSteps={getNumberOfSteps(results)}
            currentStep={4}
            onBack={onBack}
            onNext={onNext}
            nextButtonColor="#FCAF41F4"
            topSectionStyle={styles.topSection}
            titleColor="#FCAF41F4"
            topSectionChildren={
                <View style={styles.topSectionInfo}>
                    <Text style={styles.topSectionTitleText}>{capitalize(currentUserData?.identity || '')} ideal answer to...{'\n'}"<Text style={styles.topSectionTitleItalicText}>{results.guessQuestion}"</Text></Text>
                    <Image resizeMode={Platform.OS === 'ios' ? 'contain' : 'cover'} style={styles.topSectionImage} source={imageUri} />
                </View>
            }
            bottomSectionStyle={styles.bottomSection}
        >
            <SliderResultCard
                title="You"
                type={results.answerType}
                value={userAnswer}
                colors={results.answerType === AnswerType.percentage ? ['#EE3823', '#EE3823'] : ['#0295CD', '#0295CD']}
                image={
                    <View style={styles.imageConatiner}>
                        <Image style={styles.image} source={mbGetMediumImage(currentUserData?.avatarUrl, { asUri: true })} />
                    </View>
                }
                min={results.answerOptions.min}
                max={results.answerOptions.max}
            />
            <Spacer height={15} />
            <SliderResultCard
                title="Truth"
                type={results.answerType}
                value={surveyResult}
                colors={['#6A2C8C', '#6A2C8C']}
                image={
                    <View style={styles.logoContainer}>
                        <Image style={styles.logo} source={imageUtils.screens.logoHomeScreen} resizeMode={Platform.OS === 'android' ? 'stretch' : 'contain'} />
                    </View>
                }
                min={results.answerOptions.min}
                max={results.answerOptions.max}
            />
            {results.idealResult?.resultType === ResultType.outOfRange &&
                <View style={styles.wrongAnswer}>
                    <Text style={styles.answerText}>WRONG! YOU {userAnswer < surveyResult ? 'UNDERESTIMATED' : 'OVERESTIMATED'}</Text>
                </View>
            }
            {results.idealResult?.resultType === ResultType.exact &&
                <View style={styles.correctAnswer}>
                    <Text style={styles.answerText}>NICE! DEAD ON</Text>
                </View>
            }
            {results.idealResult?.resultType === ResultType.inMargin &&
                <View style={styles.correctAnswer}>
                    <Text style={styles.answerText}>WITHIN RANGE!</Text>
                </View>
            }
            {results.idealResult?.resultType === ResultType.inWiggle &&
                <View style={styles.correctAnswer}>
                    <Text style={styles.answerText}>CLOSE ENOUGH!</Text>
                </View>
            }
            <MostIdealBadgeModal
                answer={userAnswer}
                isVisible={isBadgeModalVisible}
                onDismiss={() => setIsBadgeModalVisible(false)}
            />
        </Layout>
    );
}

const styles = StyleSheet.create({
    title: {
        fontSize: 22,
    },
    topSection: {
        backgroundColor: '#FCAF41F4',
        height: '35%',
    },
    topSectionInfo: {
        paddingVertical: 20,
        paddingHorizontal: 30,
        flex: 1,
    },
    topSectionTitleText: mbTextStyles([textStyles.largeText, {
        fontSize: 21,
        fontFamily: FONT_STYLES.Inter_600_Italic,
        fontWeight: '600',
        marginBottom: 20,
    }]),
    topSectionTitleItalicText: mbTextStyles([textStyles.largeText, {
        fontSize: 21,
        fontFamily: FONT_STYLES.Inter_600_Italic,
        fontWeight: '600',
        marginBottom: 20,
        fontStyle: 'italic',
    }]),
    topSectionImage: Platform.select({
        default: {
            flex: 1,
            alignSelf: 'stretch',
            borderRadius: 12,
            marginHorizontal: 10,
        },
        android: {
            flex: 1,
            aspectRatio: 2,
            borderRadius: 12,
            marginHorizontal: 10,
            alignSelf: 'center',
        },
    }),
    bottomSection: {
        paddingHorizontal: 30,
        justifyContent: 'flex-start',
        paddingTop: 20,
    },
    imageConatiner: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 4,
        borderRadius: 80,
        borderColor: '#FFFFFF',
    },
    image: {
        width: 55,
        height: 55,
        borderRadius: 80,
    },
    logoContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 4,
        borderRadius: 80,
        borderColor: 'transparent',
    },
    logo: {
        width: 55,
        height: 55,
    },
    wrongAnswer: {
        backgroundColor: '#EE3823',
        position: 'absolute',
        left: 0,
        right: 0,
        paddingVertical: 15,
        alignItems: 'center',
        justifyContent: 'center',
        bottom: 10,
    },
    answerText: mbTextStyles([textStyles.largeText, {
        fontSize: 22,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
    }]),
    correctAnswer: {
        backgroundColor: '#0295CD',
        position: 'absolute',
        left: 0,
        right: 0,
        paddingVertical: 15,
        alignItems: 'center',
        justifyContent: 'center',
        bottom: 10,
    },
});
