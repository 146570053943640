export enum ShapeCategories {
    relationshipAndSex = 'RelationshipAndSex',
    lifestyle = 'Lifestyle',
    substanceUse = 'SubstanceUse',
    identityAndCommunity = 'IdentityAndCommunity',
    wellBeing = 'WellBeing',
    beliefs = 'Beliefs',
}

export enum ShapeQuestionModerationStatus {
    pending = 'pending',
    approved = 'approved',
    rejected = 'rejected',
    inactive = 'inactive', //already used in quarter game
}

export enum ShapeSortOptions {
    newest = 'newest',
    mostVoted = 'mostVoted',
}
