import React from 'react';
import { StyleProp, StyleSheet, Text, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import { InnerShadow } from './InnerShadow';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../constants/textStyles';
import { hitSlop } from '../../utils/shared';

type ShadowCheckboxProps = {
    value: boolean,
    onChange?: () => void,
    isError?: boolean,
    errorMessage?: string,
    text?: string | React.ReactChild,
    style?: StyleProp<ViewStyle>
    textStyle?: StyleProp<TextStyle>
    checkBoxStyle?: StyleProp<ViewStyle>
    checkColor?: string,
}

const ShadowCheckbox = (props: ShadowCheckboxProps) => {
    return (
        <View style={[styles.container, props.style]}>
            <TouchableOpacity
                activeOpacity={1}
                onPress={props.onChange}
                hitSlop={hitSlop}
            >
                <InnerShadow
                    style={[styles.checkBoxShadow, styles.checkBox, props.isError && styles.error, props.checkBoxStyle]}
                    shadowOffset={{ width: 2, height: 2 }}
                    bottomShadowOffset={{
                        height: 2,
                        width: 0,
                    }}
                    shadowColor="#AAAACC"
                    bottomShadowOpacity={1}
                    rightShadowOpacity={1}
                >
                    {props.value && <Feather name="check" size={16} color={props.checkColor ?? '#F2522B'} />}
                </InnerShadow>
            </TouchableOpacity>
            {typeof props.text === 'string' ?
                <Text style={[styles.text, props.textStyle]}>{props.text}</Text>
                :
                props.text
            }
            {props.isError && <Text style={styles.errorMessageText}>{props.errorMessage}</Text>}
        </View>
    );
};

export default ShadowCheckbox;

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    checkBoxShadow: {
        borderRadius: 4,
        borderWidth: 1,
        borderColor: '#DBDADA',
        backgroundColor: '#F5F5FA',
    },
    checkBox: {
        height: 20,
        width: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    error: {
        borderColor: mbApplyTransparency('#FF0000', 0.80),
    },
    errorMessageText: mbTextStyles([textStyles.smallText, {
        position: 'absolute',
        zIndex: 1,
        color: mbApplyTransparency('#FF0000', 0.80),
        textAlign: 'center',
        fontWeight: '400',
        fontSize: 11,
        left: 0,
        bottom: -14,
    }]),
    text: mbTextStyles([textStyles.smallerText, {
        color: '#000C14',
        textAlign: 'left',
        marginLeft: 7,
    }]),
});
