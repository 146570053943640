import { z } from 'zod';
import {
    AgeGroup,
    AnswerType,
    TopScoresType,
    UserIdentity,
} from '../../entities';

export const BonusTypeIdealSchema = z.object({
    guess: z.number(),
    answer: z.number(),
});

export const IdentityGroupBonusSchema = z.object({
    [UserIdentity.lesbian]: z.number()?.optional(),
    [UserIdentity.bisexual]: z.number()?.optional(),
    [UserIdentity.queer]: z.number()?.optional(),
});

export const AgeGroupBonusSchema = z.object({
    [AgeGroup.lateTeens]: z.number()?.optional(),
    [AgeGroup['20s']]: z.number()?.optional(),
    [AgeGroup['30s']]: z.number()?.optional(),
    [AgeGroup['40s']]: z.number()?.optional(),
    [AgeGroup['50s+']]: z.number()?.optional(),
});

// Define the schema for QuarterAnswerData
export const QuarterAnswerDataSchema = z
    .object({
        questionId: z.string(),
        answerType: z.nativeEnum(AnswerType),
        extraBet: z.number(),
        guess: z.number(),
        answer: z.number(),
        idealBonus: BonusTypeIdealSchema.optional(),
        identityGroupBonus: IdentityGroupBonusSchema.optional(),
        ageGroupBonus: AgeGroupBonusSchema.optional(),
    })
    .refine(
        (data) => {
            const hasIdentityBonus = data.identityGroupBonus !== undefined;
            const hasAgeBonus = data.ageGroupBonus !== undefined;

            // Allow neither or exactly one, but not both
            return !(hasIdentityBonus && hasAgeBonus);
        },
        {
            message: 'Cannot provide both identityGroupBonus and ageGroupBonus',
        },
    );

// Define the schema for AddQuarterAnswerInput
export const AddQuarterAnswerInputSchema = z.object({
    quarterId: z.string(),
    data: z.array(QuarterAnswerDataSchema).length(4),
});

export const GetQuarterDataToPlayInputSchema = z.object({
    quarterId: z.string(),
});

export const GetQuarterResultsInputSchema = z.object({
    quarterId: z.string(),
});

export const IncrementResultViewCountInputSchema = z.object({
    resultId: z.string(),
});

export const GetTopScoresInputSchema = z.object({
    type: z.nativeEnum(TopScoresType),
});
