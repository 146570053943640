import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { z } from 'zod';
import { envs } from '../../env';
import { SERVER_ERROR_CODES } from '../constants/constants';
import { signedInContextGlobalFunction } from '../context/SignedInContext';
import { ServerError } from '../typesAndInterfaces/typesAndInterfaces';
import { utils } from '../utils/utils';

export const adminConfig: { memberId?: string } = {};

export const customAxios = axios.create({
    baseURL: envs.SERVER_URL,
});

let responseInterceptor: number | undefined;

export const deleteResponseInterceptors = () => {
    if (responseInterceptor !== undefined) {
        customAxios.interceptors.response.eject(responseInterceptor);
        responseInterceptor = undefined;
    }
};

export const setResponseInterceptors = () => {
    if (responseInterceptor !== undefined) {
        // The interceptor is already set
        return;
    }

    responseInterceptor = customAxios.interceptors.response.use(
        function (response) {
            return response;
        },

        function (error) {
            const errorCode = error?.response?.data?.errorCode;
            if (
                errorCode === SERVER_ERROR_CODES.INVALID_ACCESS_TOKEN ||
                errorCode === SERVER_ERROR_CODES.INVALID_REFRESH_TOKEN ||
                errorCode === SERVER_ERROR_CODES.EXPIRED_ACCESS_TOKEN
            ) {
                const makeRefreshTokenCall =
                    errorCode === SERVER_ERROR_CODES.INVALID_ACCESS_TOKEN ||
                    errorCode === SERVER_ERROR_CODES.INVALID_REFRESH_TOKEN;
                signedInContextGlobalFunction.signOut?.({
                    skipSignOutRequest: true,
                    showExpiredError: true,
                    makeRefreshTokenCall:
                        envs.FLAVOR === 'dev' ? false : makeRefreshTokenCall,
                });
            }

            return Promise.reject(error);
        },
    );
};

/**
 * Parse real data from axios resonse format that needed.
 * @param response @type {pbject} response object that we got from axios
 * @param responseDataKey @type {string} key that need to fetch from response data
 */

const parseResponseData = (
    response: AxiosResponse,
    responseDataKey: string | undefined,
) => {
    if (responseDataKey) {
        return response.data?.data[responseDataKey];
    }
    return response.data?.data;
};

interface IHandleResponseValidation {
    responseData: object;
    responseValidatorObj: z.ZodTypeAny;
    responseFormatValidatorMessage?: string;
    url?: string;
    method: Method;
}

/**
 * Raise error if response format doesn't match by passed validator object.
 * @param responseData @type {object} parsed response data from api
 * @param responseValidatorObj zod object that will be used to validate the response data.
 * @param responseFormatValidatorMessage @type {string} if response format doesn't match then we throw error with this message
 * @param url @type {string} url of the api
 * @param method @type {string} rest api method
 */

const handleResponseValidation = ({
    responseData,
    responseValidatorObj,
    responseFormatValidatorMessage,
    url,
    method,
}: IHandleResponseValidation) => {
    const validateResult = responseValidatorObj.safeParse(responseData);
    if (!validateResult.success) {
        let errMsg = `Data format validation error for url\n${url}(${method}) \n\n`;
        console.error(errMsg, {
            data: responseData,
            issues: validateResult.error.issues,
        });
        throw utils.createErrorObject(
            responseFormatValidatorMessage || errMsg,
            SERVER_ERROR_CODES.CLIENT_DATA_MISMATCH_ERROR,
        );
    }
};

/**
 * Adds authorization header in the headers object.
 * @param  {object | undefined} headers | Object of headers, it can be undefined.
 * @param  {string} url | url to hit.
 * @param  {Method} method | rest api method.
 * @return {object} | returns an object which contain Authorization header as well as all existing headers.
 */

const addAuthorizationHeader = async ({
    headers,
    url,
    method,
}: {
    headers: any;
    url?: string;
    method: Method;
}) => {
    try {
        const accessToken = await MB_accessTokenUtils.getAccessToken();

        if (headers) {
            headers.Authorization = accessToken;
        } else {
            headers = {
                Authorization: accessToken,
            };
        }
        return headers;
    } catch (error) {
        const errMsg: string = `API Failed: ${url}(${method}).\n Error in setting authorization header`;
        console.error(errMsg);
        throw new Error(errMsg);
    }
};

/**
 * Builds and returns actual endpoint to hit.
 * @param  {url} string | url if passed
 * @param  {path} string | endpoint path
 * @return {url} @type {string} actual endpoint to hit.
 */

const getApiUrl = (url?: string, path?: string) => {
    if (url) {
        return url;
    }
    if (path) {
        return `${customAxios.defaults.baseURL}${path}`;
    }
    // One of url or path is mandatory.
    const errMsg = 'None of url or path passed in api.';
    console.error(errMsg);
    throw new Error(errMsg);
};

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
    /**
     * zod object that will be used to validate the response data
     */
    responseValidatorObj?: z.ZodTypeAny;
    /**
     * Error message to prepend when response validation fails
     */
    responseFormatValidatorMessage?: string;
    /**
     * key in response data object that contains the actual data.
     */
    responseDataKey?: string;
    /**
     * if we want to pass authorization header
     */
    passToken?: boolean;
    /**
     * api endpoint path
     */
    path?: string;
}

const handleError = (
    config: CustomAxiosRequestConfig,
    error: unknown,
): ServerError => {
    const errorToThrow = (error as any)?.response?.data as ServerError;
    if (!errorToThrow) {
        const errorMessage = (error as any).message;
        if (errorMessage === 'Network Error') {
            console.error(
                `Network Error when making axios call with url: ${config.url}`,
            );
        } else {
            console.error(
                `Error when making axios call with url: ${config.url}`,
                JSON.stringify(error),
            );
        }

        const unknownError: ServerError = {
            message:
                errorMessage === 'Network Error'
                    ? 'Unknown network error'
                    : 'Unable to throw the actual error',
            errorCode:
                errorMessage === 'Network Error'
                    ? SERVER_ERROR_CODES.NETWORK_ERROR
                    : SERVER_ERROR_CODES.UNKNOWN_ERROR,
            status: 'error',
        };
        throw unknownError;
    }
    throw errorToThrow;
};

/**
 * A function which is responsible to make api call throughout the application.
 * @returns {Promise<any>} | returns resolved promise with data or rejects with error
 */
export const axiosCaller = async (config: CustomAxiosRequestConfig) => {
    config.url = getApiUrl(config.url, config.path);
    if (config.passToken) {
        config.headers = await addAuthorizationHeader({
            headers: {
                ...config.headers,
                memberId: adminConfig.memberId,
            },
            url: config.url,
            method: config.method as Method,
        });
    }
    let response;
    try {
        response = await customAxios(config);
    } catch (error) {
        throw handleError(config, error);
    }
    const responseData = parseResponseData(response, config.responseDataKey);

    if (config.responseValidatorObj) {
        handleResponseValidation({
            responseData,
            responseValidatorObj: config.responseValidatorObj,
            responseFormatValidatorMessage:
                config.responseFormatValidatorMessage,
            url: config.url,
            method: config.method as Method,
        });
    }

    return responseData;
};
