import React, { useMemo, useState } from 'react';
import { Image, Platform, ScrollView, StyleSheet, Text, View } from 'react-native';
import { getNumberOfSteps, getTopInset } from '../../../../utils/shared';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import Feather from 'react-native-vector-icons/Feather';
import { Spacer } from '../../../helperComponents/Spacer';
import { ShadowView } from '../../../helperComponents/ShadowView';
import CoinsIcon from '../../../../resources/svgComponents/CoinsIcon';
import Stepper from '../../../helperComponents/Stepper';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { AnswerType, QuestionToPlay } from '@sapphicsavvy/business';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { Answer } from '../../../../navigations/RootNavigator';
import { mbGetMediumImage } from '@mightybyte/rnw.components.image';
import ColumnSliderCard from '../components/ColumnSliderCard';

type IdealBonusStepProp = {
    onNext?: (answer: Partial<Answer>) => void,
    onBack?: () => void,
    question: QuestionToPlay,
    isSubmitting?: boolean,
    points?: number,
}

export default function IdealBonusStep({ question, onBack, onNext, isSubmitting, points = 200 }: IdealBonusStepProp) {

    const { currentUserData } = useSignedInContext();

    const [typicalIdentityAnswer, setTypicalIdentityAnswer] = useState(question.answerType === AnswerType.numeric ? 4 : -1);
    const [idealAnswer, setIdealAnswer] = useState(question.answerType === AnswerType.numeric ? 4 : -1);

    const imageUri = mbGetMediumImage(question.imageUrl, { asUri: true });

    const min = question.answerOptions.min || 0;
    const max = question.answerOptions.max || 100;
    const increment = question.answerOptions.increment || 1;
    const strictMax = question.answerOptions.strictMax;

    const isSubmitDisabled = useMemo(() => {
        return question.answerType === AnswerType.numeric ? false : (typicalIdentityAnswer === -1 || idealAnswer === -1);
    }, [idealAnswer, question.answerType, typicalIdentityAnswer]);

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <Text style={styles.headerTitleText}>Ideals Bonus</Text>
                <Spacer />
                <ShadowView style={styles.coins}>
                    <CoinsIcon size={27} />
                    <Spacer width={6} />
                    <Text style={styles.pointsText}>{points}</Text>
                </ShadowView>
            </View>
            <View style={styles.topSection}>
                <Stepper style={styles.topSectionStepper} step={5} max={getNumberOfSteps(question)} />
                <View style={styles.topSectionInfo}>
                    <Text style={styles.topSectionTitleText}>{question.sapphicQuestion}</Text>
                    <Image resizeMode={Platform.OS === 'ios' ? 'contain' : 'cover'} style={styles.topSectionImage} source={imageUri} />
                </View>
            </View>
            <ScrollView
                style={styles.bottomSection}
                contentContainerStyle={styles.bottomSectionContent}
            >
                <ColumnSliderCard
                    answerType={question.answerType}
                    title={`What does the typical ${currentUserData?.identity} think is the ideal answer?`}
                    value={typicalIdentityAnswer}
                    onChange={setTypicalIdentityAnswer}
                    min={min}
                    max={max}
                    steps={increment}
                    strictMax={strictMax}
                />
                <Spacer height={15} />
                <ColumnSliderCard
                    answerType={question.answerType}
                    title="What do you think is the ideal answer?"
                    value={idealAnswer}
                    onChange={setIdealAnswer}
                    min={min}
                    max={max}
                    steps={increment}
                    strictMax={strictMax}
                />
            </ScrollView>
            <View style={styles.footer}>
                <MB_Button
                    style={styles.backBtn}
                    leftElement={<Feather name="chevron-left" color={styles.backBtnText.color} size={20} />}
                    title="BACK"
                    textStyle={styles.backBtnText}
                    onPress={onBack}
                />
                <MB_Button
                    style={styles.nextBtn}
                    rightElement={<Feather name="chevron-right" color={styles.nextBtnText.color} size={20} />}
                    title="NEXT"
                    textStyle={styles.nextBtnText}
                    onPress={() => onNext?.({
                        idealBonus: {
                            answer: idealAnswer,
                            guess: typicalIdentityAnswer,
                        },
                    })}
                    loading={isSubmitting}
                    disabled={isSubmitting || isSubmitDisabled}
                />
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        paddingTop: getTopInset(),
        flex: 1,
        backgroundColor: '#EFEFF3',
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 20,
        paddingVertical: 10,
    },
    coins: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        width: 111,
        height: 40,
    },
    pointsText: mbTextStyles([textStyles.largerText, {
        fontSize: 25,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        color: '#F35E2E',
    }]),
    headerTitleText: mbTextStyles([textStyles.normalText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        textAlign: 'left',
        fontWeight: '400',
        fontSize: 22,
        color: mbApplyTransparency('#FCAF41', 0.95),
    }]),
    topSection: {
        flex: 1,
        backgroundColor: mbApplyTransparency('#FCAF41', 0.95),
    },
    topSectionStepper: {
        margin: 2,
    },
    topSectionInfo: {
        paddingVertical: 20,
        paddingHorizontal: 30,
        flex: 1,
    },
    topSectionImage: Platform.select({
        default: {
            width: '100%',
            flex: 1,
            borderRadius: 12,
        },
        android: {
            aspectRatio: 2,
            alignSelf: 'center',
            flex: 1,
            borderRadius: 12,
        },
    }),
    topSectionTitleText: mbTextStyles([textStyles.largeText, {
        fontSize: 21,
        fontWeight: '600',
        marginBottom: 21,
    }]),
    topSectionTitleBoldText: mbTextStyles([textStyles.largeText, {
        fontSize: 55,
        fontWeight: '700',
        marginVertical: 14,
    }]),
    bottomSection: {
        flex: 1,
    },
    bottomSectionContent: {
        padding: 15,
    },
    footer: {
        flexDirection: 'row',
        height: 60,
        ...mbShadow({
            offsetWidth: 5,
            offsetHeight: 5,
            radius: 10,
            elevation: 10,
            color: '#AEAEC0',
            opacity: 0.5,
        }),
    },
    backBtn: {
        backgroundColor: '#F0F0F3',
        flex: 1,
        height: '100%',
        paddingBottom: 10,
        borderRadius: 0,
    },
    backBtnText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '800',
        color: '#7E8F9F',
    }]),
    nextBtn: {
        backgroundColor: mbApplyTransparency('#FCAF41', 0.95),
        flex: 1,
        height: '100%',
        paddingBottom: 10,
        borderRadius: 0,
    },
    nextBtnText: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        fontWeight: '800',
        color: '#FFFFFF',
    }]),
});
