import * as React from 'react';
import Svg, {
    Rect,
    Path,
    Defs,
    LinearGradient,
    Stop,
    RadialGradient,
} from 'react-native-svg';

export default function SpiralCalendarIcon() {
    return (
        <Svg
            width={54}
            height={48}
            viewBox="0 0 54 48"
            fill="none"
        >
            <Rect
                x={3.50684}
                y={5.44141}
                width={46.9863}
                height={38.269}
                rx={5.25}
                fill="url(#paint0_linear_376_26284)"
            />
            <Rect
                x={3.50684}
                y={5.44141}
                width={46.9863}
                height={38.269}
                rx={5.25}
                fill="url(#paint1_linear_376_26284)"
            />
            <Rect
                x={3.50684}
                y={5.44141}
                width={46.9863}
                height={38.269}
                rx={5.25}
                fill="url(#paint2_linear_376_26284)"
            />
            <Rect
                x={3.50684}
                y={5.44141}
                width={46.9863}
                height={38.269}
                rx={5.25}
                fill="url(#paint3_linear_376_26284)"
            />
            <Rect
                x={3.50684}
                y={5.44141}
                width={46.9863}
                height={38.269}
                rx={5.25}
                fill="url(#paint4_linear_376_26284)"
            />
            <Rect
                x={3.50684}
                y={5.44141}
                width={46.9863}
                height={38.269}
                rx={5.25}
                fill="url(#paint5_linear_376_26284)"
            />
            <Rect
                x={3.50684}
                y={5.44141}
                width={46.9863}
                height={38.269}
                rx={5.25}
                fill="url(#paint6_radial_376_26284)"
            />
            <Rect
                x={3.50684}
                y={5.44141}
                width={46.9863}
                height={38.269}
                rx={5.25}
                fill="url(#paint7_radial_376_26284)"
            />
            <Rect
                x={3.50684}
                y={5.44141}
                width={46.9863}
                height={38.269}
                rx={5.25}
                fill="url(#paint8_linear_376_26284)"
            />
            <Rect
                x={3.50684}
                y={5.44141}
                width={46.9863}
                height={38.269}
                rx={5.25}
                fill="url(#paint9_linear_376_26284)"
            />
            <Path
                d="M50.493 15.449H3.507v-2.522c0-4.147 3.853-7.509 8.606-7.509h29.774c4.753 0 8.606 3.362 8.606 7.51v2.52z"
                fill="url(#paint10_linear_376_26284)"
            />
            <Path
                d="M50.493 15.449H3.507v-2.522c0-4.147 3.853-7.509 8.606-7.509h29.774c4.753 0 8.606 3.362 8.606 7.51v2.52z"
                fill="url(#paint11_linear_376_26284)"
            />
            <Path
                d="M50.493 15.449H3.507v-2.522c0-4.147 3.853-7.509 8.606-7.509h29.774c4.753 0 8.606 3.362 8.606 7.51v2.52z"
                fill="url(#paint12_radial_376_26284)"
            />
            <Path
                d="M50.493 15.449H3.507v-2.522c0-4.147 3.853-7.509 8.606-7.509h29.774c4.753 0 8.606 3.362 8.606 7.51v2.52z"
                fill="url(#paint13_radial_376_26284)"
            />
            <Path
                d="M50.493 15.449H3.507v-2.522c0-4.147 3.853-7.509 8.606-7.509h29.774c4.753 0 8.606 3.362 8.606 7.51v2.52z"
                fill="url(#paint14_linear_376_26284)"
            />
            <Path
                d="M50.493 15.449H3.507v-2.522c0-4.147 3.853-7.509 8.606-7.509h29.774c4.753 0 8.606 3.362 8.606 7.51v2.52z"
                fill="url(#paint15_linear_376_26284)"
            />
            <Path
                d="M50.493 15.449H3.507v-2.522c0-4.147 3.853-7.509 8.606-7.509h29.774c4.753 0 8.606 3.362 8.606 7.51v2.52z"
                fill="url(#paint16_radial_376_26284)"
            />
            <Path
                d="M50.493 15.449H3.507v-2.522c0-4.147 3.853-7.509 8.606-7.509h29.774c4.753 0 8.606 3.362 8.606 7.51v2.52z"
                fill="url(#paint17_radial_376_26284)"
            />
            <Path
                d="M50.493 15.449H3.507v-2.522c0-4.147 3.853-7.509 8.606-7.509h29.774c4.753 0 8.606 3.362 8.606 7.51v2.52z"
                fill="url(#paint18_radial_376_26284)"
            />
            <Path
                d="M50.493 15.449H3.507v-2.522c0-4.147 3.853-7.509 8.606-7.509h29.774c4.753 0 8.606 3.362 8.606 7.51v2.52z"
                fill="url(#paint19_radial_376_26284)"
            />
            <Path
                d="M50.493 15.449H3.507v-2.522c0-4.147 3.853-7.509 8.606-7.509h29.774c4.753 0 8.606 3.362 8.606 7.51v2.52z"
                fill="url(#paint20_radial_376_26284)"
            />
            <Path
                d="M50.493 15.449H3.507v-2.522c0-4.147 3.853-7.509 8.606-7.509h29.774c4.753 0 8.606 3.362 8.606 7.51v2.52z"
                fill="url(#paint21_radial_376_26284)"
            />
            <Path
                d="M50.493 15.449H3.507v-2.522c0-4.147 3.853-7.509 8.606-7.509h29.774c4.753 0 8.606 3.362 8.606 7.51v2.52z"
                fill="url(#paint22_radial_376_26284)"
            />
            <Path
                d="M50.493 15.449H3.507v-2.522c0-4.147 3.853-7.509 8.606-7.509h29.774c4.753 0 8.606 3.362 8.606 7.51v2.52z"
                fill="url(#paint23_radial_376_26284)"
            />
            <Path
                d="M50.493 15.449H3.507v-2.522c0-4.147 3.853-7.509 8.606-7.509h29.774c4.753 0 8.606 3.362 8.606 7.51v2.52z"
                fill="url(#paint24_radial_376_26284)"
            />
            <Path
                d="M50.493 29.918v6.284c0 4.147-3.853 7.509-8.606 7.509h-7.299l15.905-13.793z"
                fill="#D3CBDD"
            />
            <Path
                d="M50.493 29.918v6.284c0 4.147-3.853 7.509-8.606 7.509h-7.299l15.905-13.793z"
                fill="url(#paint25_linear_376_26284)"
            />
            <Path
                d="M50.493 29.918v6.284c0 4.147-3.853 7.509-8.606 7.509h-7.299l15.905-13.793z"
                fill="url(#paint26_radial_376_26284)"
            />
            <Path
                d="M50.493 29.918v6.284c0 4.147-3.853 7.509-8.606 7.509h-7.299l15.905-13.793z"
                fill="url(#paint27_linear_376_26284)"
            />
            <Path
                d="M50.493 29.918v6.284c0 4.147-3.853 7.509-8.606 7.509h-7.299l15.905-13.793z"
                fill="url(#paint28_linear_376_26284)"
            />
            <Path
                d="M50.493 29.918v6.284c0 4.147-3.853 7.509-8.606 7.509h-7.299l15.905-13.793z"
                fill="url(#paint29_linear_376_26284)"
            />
            <Path
                d="M50.493 29.918v6.284c0 4.147-3.853 7.509-8.606 7.509h-7.299l15.905-13.793z"
                fill="url(#paint30_radial_376_26284)"
            />
            <Rect
                x={34.6597}
                y={19.6128}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="#9896A1"
            />
            <Rect
                x={34.6597}
                y={19.6128}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint31_linear_376_26284)"
            />
            <Rect
                x={34.6597}
                y={19.6128}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint32_linear_376_26284)"
            />
            <Rect
                x={34.6597}
                y={19.6128}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint33_linear_376_26284)"
            />
            <Rect
                x={34.6597}
                y={19.6128}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint34_linear_376_26284)"
            />
            <Rect
                x={34.6597}
                y={34.3359}
                width={4.73291}
                height={4.12939}
                rx={0.1}
                fill="#9896A1"
            />
            <Rect
                x={34.6597}
                y={34.3359}
                width={4.73291}
                height={4.12939}
                rx={0.1}
                fill="url(#paint35_linear_376_26284)"
            />
            <Rect
                x={34.6597}
                y={34.3359}
                width={4.73291}
                height={4.12939}
                rx={0.1}
                fill="url(#paint36_linear_376_26284)"
            />
            <Rect
                x={34.6597}
                y={34.3359}
                width={4.73291}
                height={4.12939}
                rx={0.1}
                fill="url(#paint37_linear_376_26284)"
            />
            <Rect
                x={34.6597}
                y={34.3359}
                width={4.73291}
                height={4.12939}
                rx={0.1}
                fill="url(#paint38_linear_376_26284)"
            />
            <Rect
                x={24.5544}
                y={34.3359}
                width={4.73291}
                height={4.12939}
                rx={0.1}
                fill="#9896A1"
            />
            <Rect
                x={24.5544}
                y={34.3359}
                width={4.73291}
                height={4.12939}
                rx={0.1}
                fill="url(#paint39_linear_376_26284)"
            />
            <Rect
                x={24.5544}
                y={34.3359}
                width={4.73291}
                height={4.12939}
                rx={0.1}
                fill="url(#paint40_linear_376_26284)"
            />
            <Rect
                x={24.5544}
                y={34.3359}
                width={4.73291}
                height={4.12939}
                rx={0.1}
                fill="url(#paint41_linear_376_26284)"
            />
            <Rect
                x={24.5544}
                y={34.3359}
                width={4.73291}
                height={4.12939}
                rx={0.1}
                fill="url(#paint42_linear_376_26284)"
            />
            <Rect
                x={14.4492}
                y={34.3359}
                width={4.73291}
                height={4.12939}
                rx={0.1}
                fill="#9896A1"
            />
            <Rect
                x={14.4492}
                y={34.3359}
                width={4.73291}
                height={4.12939}
                rx={0.1}
                fill="url(#paint43_linear_376_26284)"
            />
            <Rect
                x={14.4492}
                y={34.3359}
                width={4.73291}
                height={4.12939}
                rx={0.1}
                fill="url(#paint44_linear_376_26284)"
            />
            <Rect
                x={14.4492}
                y={34.3359}
                width={4.73291}
                height={4.12939}
                rx={0.1}
                fill="url(#paint45_linear_376_26284)"
            />
            <Rect
                x={14.4492}
                y={34.3359}
                width={4.73291}
                height={4.12939}
                rx={0.1}
                fill="url(#paint46_linear_376_26284)"
            />
            <Rect
                x={14.4492}
                y={26.9741}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="#9896A1"
            />
            <Rect
                x={14.4492}
                y={26.9741}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint47_linear_376_26284)"
            />
            <Rect
                x={14.4492}
                y={26.9741}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint48_linear_376_26284)"
            />
            <Rect
                x={14.4492}
                y={26.9741}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint49_linear_376_26284)"
            />
            <Rect
                x={14.4492}
                y={26.9741}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint50_linear_376_26284)"
            />
            <Rect
                x={24.5544}
                y={26.9741}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="#9896A1"
            />
            <Rect
                x={24.5544}
                y={26.9741}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint51_linear_376_26284)"
            />
            <Rect
                x={24.5544}
                y={26.9741}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint52_linear_376_26284)"
            />
            <Rect
                x={24.5544}
                y={26.9741}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint53_linear_376_26284)"
            />
            <Rect
                x={24.5544}
                y={26.9741}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint54_linear_376_26284)"
            />
            <Rect
                x={24.5544}
                y={19.5894}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="#9896A1"
            />
            <Rect
                x={24.5544}
                y={19.5894}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint55_linear_376_26284)"
            />
            <Rect
                x={24.5544}
                y={19.5894}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint56_linear_376_26284)"
            />
            <Rect
                x={24.5544}
                y={19.5894}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint57_linear_376_26284)"
            />
            <Rect
                x={24.5544}
                y={19.5894}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint58_linear_376_26284)"
            />
            <Rect
                x={14.4492}
                y={19.5894}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="#9896A1"
            />
            <Rect
                x={14.4492}
                y={19.5894}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint59_linear_376_26284)"
            />
            <Rect
                x={14.4492}
                y={19.5894}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint60_linear_376_26284)"
            />
            <Rect
                x={14.4492}
                y={19.5894}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint61_linear_376_26284)"
            />
            <Rect
                x={14.4492}
                y={19.5894}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint62_linear_376_26284)"
            />
            <Rect
                x={34.6597}
                y={26.9741}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint63_linear_376_26284)"
            />
            <Rect
                x={34.6597}
                y={26.9741}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint64_linear_376_26284)"
            />
            <Rect
                x={34.6597}
                y={26.9741}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint65_linear_376_26284)"
            />
            <Rect
                x={34.6597}
                y={26.9741}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint66_linear_376_26284)"
            />
            <Rect
                x={34.6597}
                y={26.9741}
                width={4.73291}
                height={4.12938}
                rx={0.1}
                fill="url(#paint67_linear_376_26284)"
            />
            <Path
                d="M50.334 30.179c.102-.09.16-.21.16-.336 0-.263-.245-.476-.546-.476h-1.973c-7.689 0-13.922 5.438-13.922 12.146v1.722c0 .263.244.476.546.476.144 0 .283-.05.385-.14l15.35-13.392z"
                fill="url(#paint68_radial_376_26284)"
            />
            <Path
                d="M50.334 30.179c.102-.09.16-.21.16-.336 0-.263-.245-.476-.546-.476h-1.973c-7.689 0-13.922 5.438-13.922 12.146v1.722c0 .263.244.476.546.476.144 0 .283-.05.385-.14l15.35-13.392z"
                fill="url(#paint69_linear_376_26284)"
            />
            <Path
                d="M50.334 30.179c.102-.09.16-.21.16-.336 0-.263-.245-.476-.546-.476h-1.973c-7.689 0-13.922 5.438-13.922 12.146v1.722c0 .263.244.476.546.476.144 0 .283-.05.385-.14l15.35-13.392z"
                fill="url(#paint70_radial_376_26284)"
            />
            <Path
                d="M10.736 6.83c0-1.581 1.374-2.764 2.935-2.764 1.044 0 2.005.53 2.527 1.353h2.54c-.657-1.95-2.639-3.415-5.067-3.415-2.986 0-5.298 2.215-5.298 4.825 0 2.33 1.833 4.3 4.37 4.745.64.112 1.263-.25 1.391-.808.129-.558-.286-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint71_radial_376_26284)"
            />
            <Path
                d="M10.736 6.83c0-1.581 1.374-2.764 2.935-2.764 1.044 0 2.005.53 2.527 1.353h2.54c-.657-1.95-2.639-3.415-5.067-3.415-2.986 0-5.298 2.215-5.298 4.825 0 2.33 1.833 4.3 4.37 4.745.64.112 1.263-.25 1.391-.808.129-.558-.286-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint72_radial_376_26284)"
            />
            <Path
                d="M10.736 6.83c0-1.581 1.374-2.764 2.935-2.764 1.044 0 2.005.53 2.527 1.353h2.54c-.657-1.95-2.639-3.415-5.067-3.415-2.986 0-5.298 2.215-5.298 4.825 0 2.33 1.833 4.3 4.37 4.745.64.112 1.263-.25 1.391-.808.129-.558-.286-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint73_radial_376_26284)"
            />
            <Path
                d="M10.736 6.83c0-1.581 1.374-2.764 2.935-2.764 1.044 0 2.005.53 2.527 1.353h2.54c-.657-1.95-2.639-3.415-5.067-3.415-2.986 0-5.298 2.215-5.298 4.825 0 2.33 1.833 4.3 4.37 4.745.64.112 1.263-.25 1.391-.808.129-.558-.286-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint74_radial_376_26284)"
            />
            <Path
                d="M10.736 6.83c0-1.581 1.374-2.764 2.935-2.764 1.044 0 2.005.53 2.527 1.353h2.54c-.657-1.95-2.639-3.415-5.067-3.415-2.986 0-5.298 2.215-5.298 4.825 0 2.33 1.833 4.3 4.37 4.745.64.112 1.263-.25 1.391-.808.129-.558-.286-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint75_radial_376_26284)"
            />
            <Path
                d="M10.736 6.83c0-1.581 1.374-2.764 2.935-2.764 1.044 0 2.005.53 2.527 1.353h2.54c-.657-1.95-2.639-3.415-5.067-3.415-2.986 0-5.298 2.215-5.298 4.825 0 2.33 1.833 4.3 4.37 4.745.64.112 1.263-.25 1.391-.808.129-.558-.286-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint76_radial_376_26284)"
            />
            <Path
                d="M10.736 6.83c0-1.581 1.374-2.764 2.935-2.764 1.044 0 2.005.53 2.527 1.353h2.54c-.657-1.95-2.639-3.415-5.067-3.415-2.986 0-5.298 2.215-5.298 4.825 0 2.33 1.833 4.3 4.37 4.745.64.112 1.263-.25 1.391-.808.129-.558-.286-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint77_radial_376_26284)"
            />
            <Path
                d="M19.532 6.83c0-1.581 1.374-2.764 2.935-2.764 1.044 0 2.005.53 2.527 1.353h2.54c-.657-1.95-2.639-3.415-5.067-3.415-2.986 0-5.298 2.215-5.298 4.825 0 2.33 1.833 4.3 4.37 4.745.64.112 1.263-.25 1.391-.808.129-.558-.286-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint78_radial_376_26284)"
            />
            <Path
                d="M19.532 6.83c0-1.581 1.374-2.764 2.935-2.764 1.044 0 2.005.53 2.527 1.353h2.54c-.657-1.95-2.639-3.415-5.067-3.415-2.986 0-5.298 2.215-5.298 4.825 0 2.33 1.833 4.3 4.37 4.745.64.112 1.263-.25 1.391-.808.129-.558-.286-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint79_radial_376_26284)"
            />
            <Path
                d="M19.532 6.83c0-1.581 1.374-2.764 2.935-2.764 1.044 0 2.005.53 2.527 1.353h2.54c-.657-1.95-2.639-3.415-5.067-3.415-2.986 0-5.298 2.215-5.298 4.825 0 2.33 1.833 4.3 4.37 4.745.64.112 1.263-.25 1.391-.808.129-.558-.286-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint80_radial_376_26284)"
            />
            <Path
                d="M19.532 6.83c0-1.581 1.374-2.764 2.935-2.764 1.044 0 2.005.53 2.527 1.353h2.54c-.657-1.95-2.639-3.415-5.067-3.415-2.986 0-5.298 2.215-5.298 4.825 0 2.33 1.833 4.3 4.37 4.745.64.112 1.263-.25 1.391-.808.129-.558-.286-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint81_radial_376_26284)"
            />
            <Path
                d="M19.532 6.83c0-1.581 1.374-2.764 2.935-2.764 1.044 0 2.005.53 2.527 1.353h2.54c-.657-1.95-2.639-3.415-5.067-3.415-2.986 0-5.298 2.215-5.298 4.825 0 2.33 1.833 4.3 4.37 4.745.64.112 1.263-.25 1.391-.808.129-.558-.286-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint82_radial_376_26284)"
            />
            <Path
                d="M19.532 6.83c0-1.581 1.374-2.764 2.935-2.764 1.044 0 2.005.53 2.527 1.353h2.54c-.657-1.95-2.639-3.415-5.067-3.415-2.986 0-5.298 2.215-5.298 4.825 0 2.33 1.833 4.3 4.37 4.745.64.112 1.263-.25 1.391-.808.129-.558-.286-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint83_radial_376_26284)"
            />
            <Path
                d="M19.532 6.83c0-1.581 1.374-2.764 2.935-2.764 1.044 0 2.005.53 2.527 1.353h2.54c-.657-1.95-2.639-3.415-5.067-3.415-2.986 0-5.298 2.215-5.298 4.825 0 2.33 1.833 4.3 4.37 4.745.64.112 1.263-.25 1.391-.808.129-.558-.286-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint84_radial_376_26284)"
            />
            <Path
                d="M28.391 6.83c0-1.581 1.375-2.764 2.936-2.764 1.044 0 2.004.53 2.526 1.353h2.54c-.656-1.95-2.639-3.415-5.066-3.415-2.987 0-5.298 2.215-5.298 4.825 0 2.33 1.832 4.3 4.37 4.745.64.112 1.262-.25 1.39-.808.13-.558-.285-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint85_radial_376_26284)"
            />
            <Path
                d="M28.391 6.83c0-1.581 1.375-2.764 2.936-2.764 1.044 0 2.004.53 2.526 1.353h2.54c-.656-1.95-2.639-3.415-5.066-3.415-2.987 0-5.298 2.215-5.298 4.825 0 2.33 1.832 4.3 4.37 4.745.64.112 1.262-.25 1.39-.808.13-.558-.285-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint86_radial_376_26284)"
            />
            <Path
                d="M28.391 6.83c0-1.581 1.375-2.764 2.936-2.764 1.044 0 2.004.53 2.526 1.353h2.54c-.656-1.95-2.639-3.415-5.066-3.415-2.987 0-5.298 2.215-5.298 4.825 0 2.33 1.832 4.3 4.37 4.745.64.112 1.262-.25 1.39-.808.13-.558-.285-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint87_radial_376_26284)"
            />
            <Path
                d="M28.391 6.83c0-1.581 1.375-2.764 2.936-2.764 1.044 0 2.004.53 2.526 1.353h2.54c-.656-1.95-2.639-3.415-5.066-3.415-2.987 0-5.298 2.215-5.298 4.825 0 2.33 1.832 4.3 4.37 4.745.64.112 1.262-.25 1.39-.808.13-.558-.285-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint88_radial_376_26284)"
            />
            <Path
                d="M28.391 6.83c0-1.581 1.375-2.764 2.936-2.764 1.044 0 2.004.53 2.526 1.353h2.54c-.656-1.95-2.639-3.415-5.066-3.415-2.987 0-5.298 2.215-5.298 4.825 0 2.33 1.832 4.3 4.37 4.745.64.112 1.262-.25 1.39-.808.13-.558-.285-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint89_radial_376_26284)"
            />
            <Path
                d="M28.391 6.83c0-1.581 1.375-2.764 2.936-2.764 1.044 0 2.004.53 2.526 1.353h2.54c-.656-1.95-2.639-3.415-5.066-3.415-2.987 0-5.298 2.215-5.298 4.825 0 2.33 1.832 4.3 4.37 4.745.64.112 1.262-.25 1.39-.808.13-.558-.285-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint90_radial_376_26284)"
            />
            <Path
                d="M28.391 6.83c0-1.581 1.375-2.764 2.936-2.764 1.044 0 2.004.53 2.526 1.353h2.54c-.656-1.95-2.639-3.415-5.066-3.415-2.987 0-5.298 2.215-5.298 4.825 0 2.33 1.832 4.3 4.37 4.745.64.112 1.262-.25 1.39-.808.13-.558-.285-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint91_radial_376_26284)"
            />
            <Path
                d="M37.235 6.83c0-1.581 1.374-2.764 2.935-2.764 1.059 0 2.032.544 2.548 1.387.948.08 1.852.293 2.685.619-.396-2.268-2.54-4.068-5.233-4.068-2.986 0-5.298 2.215-5.298 4.825 0 2.33 1.833 4.3 4.37 4.745.64.112 1.263-.25 1.391-.808.129-.558-.286-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint92_radial_376_26284)"
            />
            <Path
                d="M37.235 6.83c0-1.581 1.374-2.764 2.935-2.764 1.059 0 2.032.544 2.548 1.387.948.08 1.852.293 2.685.619-.396-2.268-2.54-4.068-5.233-4.068-2.986 0-5.298 2.215-5.298 4.825 0 2.33 1.833 4.3 4.37 4.745.64.112 1.263-.25 1.391-.808.129-.558-.286-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint93_radial_376_26284)"
            />
            <Path
                d="M37.235 6.83c0-1.581 1.374-2.764 2.935-2.764 1.059 0 2.032.544 2.548 1.387.948.08 1.852.293 2.685.619-.396-2.268-2.54-4.068-5.233-4.068-2.986 0-5.298 2.215-5.298 4.825 0 2.33 1.833 4.3 4.37 4.745.64.112 1.263-.25 1.391-.808.129-.558-.286-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint94_radial_376_26284)"
            />
            <Path
                d="M37.235 6.83c0-1.581 1.374-2.764 2.935-2.764 1.059 0 2.032.544 2.548 1.387.948.08 1.852.293 2.685.619-.396-2.268-2.54-4.068-5.233-4.068-2.986 0-5.298 2.215-5.298 4.825 0 2.33 1.833 4.3 4.37 4.745.64.112 1.263-.25 1.391-.808.129-.558-.286-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint95_radial_376_26284)"
            />
            <Path
                d="M37.235 6.83c0-1.581 1.374-2.764 2.935-2.764 1.059 0 2.032.544 2.548 1.387.948.08 1.852.293 2.685.619-.396-2.268-2.54-4.068-5.233-4.068-2.986 0-5.298 2.215-5.298 4.825 0 2.33 1.833 4.3 4.37 4.745.64.112 1.263-.25 1.391-.808.129-.558-.286-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint96_radial_376_26284)"
            />
            <Path
                d="M37.235 6.83c0-1.581 1.374-2.764 2.935-2.764 1.059 0 2.032.544 2.548 1.387.948.08 1.852.293 2.685.619-.396-2.268-2.54-4.068-5.233-4.068-2.986 0-5.298 2.215-5.298 4.825 0 2.33 1.833 4.3 4.37 4.745.64.112 1.263-.25 1.391-.808.129-.558-.286-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint97_radial_376_26284)"
            />
            <Path
                d="M37.235 6.83c0-1.581 1.374-2.764 2.935-2.764 1.059 0 2.032.544 2.548 1.387.948.08 1.852.293 2.685.619-.396-2.268-2.54-4.068-5.233-4.068-2.986 0-5.298 2.215-5.298 4.825 0 2.33 1.833 4.3 4.37 4.745.64.112 1.263-.25 1.391-.808.129-.558-.286-1.101-.925-1.213-1.404-.246-2.473-1.349-2.473-2.724z"
                fill="url(#paint98_radial_376_26284)"
            />
            <Defs>
                <LinearGradient
                    id="paint0_linear_376_26284"
                    x1={8.73337}
                    y1={26.7899}
                    x2={47.289}
                    y2={26.7899}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#E4DEEC" />
                    <Stop offset={1} stopColor="#E7E2EF" />
                </LinearGradient>
                <LinearGradient
                    id="paint1_linear_376_26284"
                    x1={2.41056}
                    y1={25.62}
                    x2={7.86811}
                    y2={25.62}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#AC9EBF" stopOpacity={0.73} />
                    <Stop offset={0.565214} stopColor="#AC9EBF" stopOpacity={0.226703} />
                    <Stop offset={0.831735} stopColor="#AC9EBF" stopOpacity={0.0550695} />
                    <Stop offset={1} stopColor="#AC9EBF" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint2_linear_376_26284"
                    x1={3.08807}
                    y1={25.0914}
                    x2={8.38183}
                    y2={25.0914}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#CCC9D1" />
                    <Stop offset={0.504059} stopColor="#CCC9D1" stopOpacity={0.381425} />
                    <Stop offset={0.748215} stopColor="#CCC9D1" stopOpacity={0.133098} />
                    <Stop offset={1} stopColor="#CCC9D1" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint3_linear_376_26284"
                    x1={2.28613}
                    y1={25.9571}
                    x2={5.8905}
                    y2={25.9571}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#AB9EBE" stopOpacity={0.62} />
                    <Stop offset={0.844796} stopColor="#AB9EBE" stopOpacity={0.03} />
                    <Stop offset={1} stopColor="#AB9EBE" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint4_linear_376_26284"
                    x1={14.146}
                    y1={47.0418}
                    x2={14.146}
                    y2={38.2764}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#AC9EBF" />
                    <Stop offset={0.777445} stopColor="#AC9EBF" stopOpacity={0.164632} />
                    <Stop offset={1} stopColor="#AC9EBF" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint5_linear_376_26284"
                    x1={17.1083}
                    y1={46.4199}
                    x2={17.1083}
                    y2={41.4983}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset={0.364621} stopColor="#AC9EBF" />
                    <Stop offset={0.865223} stopColor="#AC9EBF" stopOpacity={0.0952221} />
                    <Stop offset={1} stopColor="#AC9EBF" stopOpacity={0} />
                </LinearGradient>
                <RadialGradient
                    id="paint6_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(137.713 18.968 12.56) scale(48.4088 29.2808)"
                >
                    <Stop stopColor="#AC9EBF" stopOpacity={0} />
                    <Stop offset={0.884758} stopColor="#AC9EBF" stopOpacity={0.0191153} />
                    <Stop offset={0.911221} stopColor="#AC9EBF" stopOpacity={0.116465} />
                    <Stop offset={1} stopColor="#AC9EBF" stopOpacity={0.73} />
                </RadialGradient>
                <RadialGradient
                    id="paint7_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(137.713 18.968 12.56) scale(48.4088 29.2808)"
                >
                    <Stop stopColor="#AB9EBE" stopOpacity={0} />
                    <Stop offset={0.941889} stopColor="#AB9EBE" stopOpacity={0.0191153} />
                    <Stop offset={0.968045} stopColor="#AB9EBE" stopOpacity={0.116465} />
                    <Stop offset={1} stopColor="#AB9EBE" stopOpacity={0.73} />
                </RadialGradient>
                <LinearGradient
                    id="paint8_linear_376_26284"
                    x1={50.6969}
                    y1={24.5759}
                    x2={46.3803}
                    y2={24.5759}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset={0.145191} stopColor="#F4F1F9" />
                    <Stop offset={0.616923} stopColor="#F4F1F9" stopOpacity={0.496781} />
                    <Stop offset={0.823441} stopColor="#F4F1F9" stopOpacity={0.121417} />
                    <Stop offset={1} stopColor="#F4F1F9" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint9_linear_376_26284"
                    x1={51.8265}
                    y1={25.8517}
                    x2={49.2821}
                    y2={25.8517}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#AB9EBE" stopOpacity={0.72} />
                    <Stop offset={0.85823} stopColor="#AB9EBE" stopOpacity={0.0549551} />
                    <Stop offset={1} stopColor="#AB9EBE" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint10_linear_376_26284"
                    x1={2.95529}
                    y1={13.165}
                    x2={46.9738}
                    y2={13.165}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#4271C7" />
                    <Stop offset={0.794195} stopColor="#4F9FDC" />
                </LinearGradient>
                <LinearGradient
                    id="paint11_linear_376_26284"
                    x1={27}
                    y1={5.41846}
                    x2={27}
                    y2={8.23944}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset={0.315625} stopColor="#5591D3" />
                    <Stop offset={1} stopColor="#5591D3" stopOpacity={0} />
                </LinearGradient>
                <RadialGradient
                    id="paint12_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(6.15242 -7.88719 7.53713 5.87935 40.495 14.719)"
                >
                    <Stop stopColor="#70C0F8" stopOpacity={0} />
                    <Stop offset={0.537712} stopColor="#70C0F8" stopOpacity={0.129121} />
                    <Stop offset={0.600109} stopColor="#70C0F8" stopOpacity={0.242949} />
                    <Stop offset={0.777418} stopColor="#70C0F8" stopOpacity={0.823821} />
                    <Stop offset={0.806938} stopColor="#70C0F8" stopOpacity={0.911572} />
                    <Stop offset={0.837741} stopColor="#70C0F8" stopOpacity={0.94} />
                    <Stop offset={0.877133} stopColor="#70C0F8" stopOpacity={0.95} />
                    <Stop offset={0.910017} stopColor="#70C0F8" stopOpacity={0.747082} />
                    <Stop offset={0.953809} stopColor="#70C0F8" stopOpacity={0.445935} />
                    <Stop offset={1} stopColor="#70C0F8" stopOpacity={0.07} />
                </RadialGradient>
                <RadialGradient
                    id="paint13_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(-29.105 34.988 -60.283) scale(9.65317 20.5839)"
                >
                    <Stop offset={0.411532} stopColor="#4F9FDC" />
                    <Stop offset={0.653004} stopColor="#4F9FDC" stopOpacity={0.82} />
                    <Stop offset={0.765553} stopColor="#4F9FDC" stopOpacity={0.42869} />
                    <Stop offset={0.858234} stopColor="#4F9FDC" stopOpacity={0.187929} />
                    <Stop offset={1} stopColor="#4F9FDC" stopOpacity={0} />
                </RadialGradient>
                <LinearGradient
                    id="paint14_linear_376_26284"
                    x1={35.7328}
                    y1={16.6576}
                    x2={35.7328}
                    y2={13.9739}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#437AC4" />
                    <Stop offset={0.837011} stopColor="#437AC4" stopOpacity={0.0841696} />
                    <Stop offset={1} stopColor="#437AC4" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint15_linear_376_26284"
                    x1={2.95529}
                    y1={13.165}
                    x2={46.9738}
                    y2={13.165}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#4271C7" />
                    <Stop offset={0.794195} stopColor="#4F9FDC" stopOpacity={0.25} />
                </LinearGradient>
                <RadialGradient
                    id="paint16_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(-33.13094 -5.01532 2.44972 -16.18273 36.638 15.449)"
                >
                    <Stop offset={0.710095} stopColor="#3E62C2" stopOpacity={0} />
                    <Stop offset={1} stopColor="#3B41A1" />
                </RadialGradient>
                <RadialGradient
                    id="paint17_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(2.68473 -1.95995 3.60482 4.93786 9.203 7.889)"
                >
                    <Stop offset={0.176844} stopColor="#324DAF" />
                    <Stop offset={0.575433} stopColor="#324DAF" stopOpacity={0.71862} />
                    <Stop offset={0.840439} stopColor="#324DAF" stopOpacity={0.240624} />
                    <Stop offset={1} stopColor="#324DAF" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint18_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(2.6994 -1.63293 2.38896 3.94919 11.382 7.802)"
                >
                    <Stop stopColor="#4271C7" />
                    <Stop offset={0.666406} stopColor="#4271C7" stopOpacity={0.656304} />
                    <Stop offset={0.867031} stopColor="#4271C7" stopOpacity={0.231508} />
                    <Stop offset={1} stopColor="#4271C7" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint19_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(2.29265 -1.08587 1.92683 4.06818 12.817 7.178)"
                >
                    <Stop stopColor="#4F9FDC" stopOpacity={0.34} />
                    <Stop offset={0.265625} stopColor="#4F9FDC" stopOpacity={0.27} />
                    <Stop offset={0.567708} stopColor="#4F9FDC" stopOpacity={0.15} />
                    <Stop offset={1} stopColor="#4F9FDC" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint20_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(2.7608 -1.82125 2.89438 4.38754 36.235 8.218)"
                >
                    <Stop offset={0.265053} stopColor="#3E7BC6" />
                    <Stop offset={0.560275} stopColor="#3E7BC6" stopOpacity={0.71862} />
                    <Stop offset={0.817054} stopColor="#3E7BC6" stopOpacity={0.240624} />
                    <Stop offset={1} stopColor="#3E7BC6" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint21_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(2.51858 -1.30218 2.40296 4.64765 27.243 8.033)"
                >
                    <Stop offset={0.176844} stopColor="#3E7BC6" />
                    <Stop offset={0.575433} stopColor="#3E7BC6" stopOpacity={0.71862} />
                    <Stop offset={0.844248} stopColor="#3E7BC6" stopOpacity={0.240624} />
                    <Stop offset={1} stopColor="#3E7BC6" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint22_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(2.17959 -1.16537 2.3662 4.42548 17.65 8.336)"
                >
                    <Stop offset={0.176844} stopColor="#3E7BC6" />
                    <Stop offset={0.624702} stopColor="#3E7BC6" stopOpacity={0.71862} />
                    <Stop offset={0.844248} stopColor="#3E7BC6" stopOpacity={0.240624} />
                    <Stop offset={1} stopColor="#3E7BC6" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint23_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(-27.074 30.37 -57.31) scale(2.78706 4.35261)"
                >
                    <Stop stopColor="#4F9FDC" />
                    <Stop offset={0.71089} stopColor="#4F9FDC" />
                    <Stop offset={1} stopColor="#4F9FDC" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint24_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(-23.569 36.474 -88.073) scale(2.76592 4.874)"
                >
                    <Stop stopColor="#4F9FDC" />
                    <Stop offset={0.448525} stopColor="#4F9FDC" />
                    <Stop offset={1} stopColor="#4F9FDC" stopOpacity={0} />
                </RadialGradient>
                <LinearGradient
                    id="paint25_linear_376_26284"
                    x1={49.6667}
                    y1={35.0158}
                    x2={45.0097}
                    y2={35.0158}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#EFEAF6" />
                    <Stop offset={0.589482} stopColor="#EFEAF6" stopOpacity={0.2095} />
                    <Stop offset={0.848243} stopColor="#EFEAF6" stopOpacity={0.069066} />
                    <Stop offset={1} stopColor="#EFEAF6" stopOpacity={0} />
                </LinearGradient>
                <RadialGradient
                    id="paint26_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(8.8641 8.30059 -11.56326 12.34825 39.124 33.214)"
                >
                    <Stop stopColor="#EFEAF6" stopOpacity={0} />
                    <Stop offset={0.382846} stopColor="#EFEAF6" stopOpacity={0.069066} />
                    <Stop offset={0.609706} stopColor="#EFEAF6" stopOpacity={0.38} />
                    <Stop offset={0.844218} stopColor="#EFEAF6" />
                </RadialGradient>
                <LinearGradient
                    id="paint27_linear_376_26284"
                    x1={40.9552}
                    y1={35.5333}
                    x2={43.4406}
                    y2={38.4406}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A89BBA" stopOpacity={0.69} />
                    <Stop offset={0.880123} stopColor="#A89BBA" stopOpacity={0.0522108} />
                    <Stop offset={1} stopColor="#A89BBA" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint28_linear_376_26284"
                    x1={43.5589}
                    y1={46.826}
                    x2={43.5589}
                    y2={40.3743}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset={0.248984} stopColor="#A89BBA" />
                    <Stop offset={0.856001} stopColor="#A89BBA" stopOpacity={0.0971553} />
                    <Stop offset={1} stopColor="#A89BBA" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint29_linear_376_26284"
                    x1={52.1786}
                    y1={32.5944}
                    x2={49.3914}
                    y2={32.5944}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A89BBA" stopOpacity={0.74} />
                    <Stop offset={0.839186} stopColor="#A89BBA" stopOpacity={0.0553304} />
                    <Stop offset={0.91781} stopColor="#A89BBA" stopOpacity={0.0244732} />
                    <Stop offset={1} stopColor="#A89BBA" stopOpacity={0} />
                </LinearGradient>
                <RadialGradient
                    id="paint30_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(41.104 -24.724 65.965) scale(14.1374 12.9074)"
                >
                    <Stop stopColor="#A89BBA" stopOpacity={0} />
                    <Stop offset={0.899092} stopColor="#A89BBA" stopOpacity={0.0553304} />
                    <Stop offset={0.920202} stopColor="#A89BBA" stopOpacity={0.090963} />
                    <Stop offset={0.938557} stopColor="#A89BBA" stopOpacity={0.171545} />
                    <Stop offset={1} stopColor="#A89BBA" stopOpacity={0.5} />
                </RadialGradient>
                <LinearGradient
                    id="paint31_linear_376_26284"
                    x1={34.6597}
                    y1={21.6775}
                    x2={34.9471}
                    y2={21.6775}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#92909B" />
                    <Stop offset={1} stopColor="#92909B" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint32_linear_376_26284"
                    x1={37.0261}
                    y1={23.7422}
                    x2={37.0261}
                    y2={23.4443}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#92909B" />
                    <Stop offset={1} stopColor="#92909B" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint33_linear_376_26284"
                    x1={37.0261}
                    y1={19.6128}
                    x2={37.0261}
                    y2={19.852}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A7A5AE" />
                    <Stop offset={1} stopColor="#A7A5AE" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint34_linear_376_26284"
                    x1={39.3926}
                    y1={21.9777}
                    x2={39.0833}
                    y2={21.9777}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A7A5AE" />
                    <Stop offset={1} stopColor="#A7A5AE" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint35_linear_376_26284"
                    x1={34.6597}
                    y1={36.4006}
                    x2={34.9471}
                    y2={36.4006}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#92909B" />
                    <Stop offset={1} stopColor="#92909B" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint36_linear_376_26284"
                    x1={37.0261}
                    y1={38.4653}
                    x2={37.0261}
                    y2={38.1674}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#92909B" />
                    <Stop offset={1} stopColor="#92909B" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint37_linear_376_26284"
                    x1={37.0261}
                    y1={34.3359}
                    x2={37.0261}
                    y2={34.5751}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A7A5AE" />
                    <Stop offset={1} stopColor="#A7A5AE" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint38_linear_376_26284"
                    x1={39.3926}
                    y1={36.7008}
                    x2={39.0833}
                    y2={36.7008}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A7A5AE" />
                    <Stop offset={1} stopColor="#A7A5AE" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint39_linear_376_26284"
                    x1={24.5544}
                    y1={36.4006}
                    x2={24.8419}
                    y2={36.4006}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#92909B" />
                    <Stop offset={1} stopColor="#92909B" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint40_linear_376_26284"
                    x1={26.9209}
                    y1={38.4653}
                    x2={26.9209}
                    y2={38.1674}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#92909B" />
                    <Stop offset={1} stopColor="#92909B" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint41_linear_376_26284"
                    x1={26.9209}
                    y1={34.3359}
                    x2={26.9209}
                    y2={34.5751}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A7A5AE" />
                    <Stop offset={1} stopColor="#A7A5AE" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint42_linear_376_26284"
                    x1={29.2874}
                    y1={36.7008}
                    x2={28.9781}
                    y2={36.7008}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A7A5AE" />
                    <Stop offset={1} stopColor="#A7A5AE" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint43_linear_376_26284"
                    x1={14.4492}
                    y1={36.4006}
                    x2={14.7366}
                    y2={36.4006}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#92909B" />
                    <Stop offset={1} stopColor="#92909B" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint44_linear_376_26284"
                    x1={16.8157}
                    y1={38.4653}
                    x2={16.8157}
                    y2={38.1674}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#92909B" />
                    <Stop offset={1} stopColor="#92909B" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint45_linear_376_26284"
                    x1={16.8157}
                    y1={34.3359}
                    x2={16.8157}
                    y2={34.5751}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A7A5AE" />
                    <Stop offset={1} stopColor="#A7A5AE" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint46_linear_376_26284"
                    x1={19.1821}
                    y1={36.7008}
                    x2={18.8728}
                    y2={36.7008}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A7A5AE" />
                    <Stop offset={1} stopColor="#A7A5AE" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint47_linear_376_26284"
                    x1={14.4492}
                    y1={29.0388}
                    x2={14.7366}
                    y2={29.0388}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#92909B" />
                    <Stop offset={1} stopColor="#92909B" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint48_linear_376_26284"
                    x1={16.8157}
                    y1={31.1035}
                    x2={16.8157}
                    y2={30.8056}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#92909B" />
                    <Stop offset={1} stopColor="#92909B" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint49_linear_376_26284"
                    x1={16.8157}
                    y1={26.9741}
                    x2={16.8157}
                    y2={27.2133}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A7A5AE" />
                    <Stop offset={1} stopColor="#A7A5AE" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint50_linear_376_26284"
                    x1={19.1821}
                    y1={29.339}
                    x2={18.8728}
                    y2={29.339}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A7A5AE" />
                    <Stop offset={1} stopColor="#A7A5AE" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint51_linear_376_26284"
                    x1={24.5544}
                    y1={29.0388}
                    x2={24.8419}
                    y2={29.0388}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#92909B" />
                    <Stop offset={1} stopColor="#92909B" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint52_linear_376_26284"
                    x1={26.9209}
                    y1={31.1035}
                    x2={26.9209}
                    y2={30.8056}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#92909B" />
                    <Stop offset={1} stopColor="#92909B" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint53_linear_376_26284"
                    x1={26.9209}
                    y1={26.9741}
                    x2={26.9209}
                    y2={27.2133}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A7A5AE" />
                    <Stop offset={1} stopColor="#A7A5AE" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint54_linear_376_26284"
                    x1={29.2874}
                    y1={29.339}
                    x2={28.9781}
                    y2={29.339}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A7A5AE" />
                    <Stop offset={1} stopColor="#A7A5AE" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint55_linear_376_26284"
                    x1={24.5544}
                    y1={21.654}
                    x2={24.8419}
                    y2={21.654}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#92909B" />
                    <Stop offset={1} stopColor="#92909B" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint56_linear_376_26284"
                    x1={26.9209}
                    y1={23.7187}
                    x2={26.9209}
                    y2={23.4208}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#92909B" />
                    <Stop offset={1} stopColor="#92909B" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint57_linear_376_26284"
                    x1={26.9209}
                    y1={19.5894}
                    x2={26.9209}
                    y2={19.8286}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A7A5AE" />
                    <Stop offset={1} stopColor="#A7A5AE" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint58_linear_376_26284"
                    x1={29.2874}
                    y1={21.9543}
                    x2={28.9781}
                    y2={21.9543}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A7A5AE" />
                    <Stop offset={1} stopColor="#A7A5AE" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint59_linear_376_26284"
                    x1={14.4492}
                    y1={21.654}
                    x2={14.7366}
                    y2={21.654}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#92909B" />
                    <Stop offset={1} stopColor="#92909B" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint60_linear_376_26284"
                    x1={16.8157}
                    y1={23.7187}
                    x2={16.8157}
                    y2={23.4208}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#92909B" />
                    <Stop offset={1} stopColor="#92909B" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint61_linear_376_26284"
                    x1={16.8157}
                    y1={19.5894}
                    x2={16.8157}
                    y2={19.8286}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A7A5AE" />
                    <Stop offset={1} stopColor="#A7A5AE" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint62_linear_376_26284"
                    x1={19.1821}
                    y1={21.9543}
                    x2={18.8728}
                    y2={21.9543}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#A7A5AE" />
                    <Stop offset={1} stopColor="#A7A5AE" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint63_linear_376_26284"
                    x1={39.8936}
                    y1={26.9741}
                    x2={34.3184}
                    y2={31.5618}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#4C92DE" />
                    <Stop offset={1} stopColor="#4B91DB" />
                </LinearGradient>
                <LinearGradient
                    id="paint64_linear_376_26284"
                    x1={34.6597}
                    y1={29.0388}
                    x2={34.9409}
                    y2={29.0388}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#417FD1" />
                    <Stop offset={1} stopColor="#417FD1" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint65_linear_376_26284"
                    x1={36.839}
                    y1={31.1035}
                    x2={36.839}
                    y2={30.8233}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#417FD1" />
                    <Stop offset={1} stopColor="#417FD1" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint66_linear_376_26284"
                    x1={37.0261}
                    y1={26.9741}
                    x2={37.0261}
                    y2={27.4363}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#59A1EA" />
                    <Stop offset={1} stopColor="#59A1EA" stopOpacity={0} />
                </LinearGradient>
                <LinearGradient
                    id="paint67_linear_376_26284"
                    x1={39.3926}
                    y1={29.2502}
                    x2={38.8054}
                    y2={29.2502}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#59A1EA" />
                    <Stop offset={1} stopColor="#59A1EA" stopOpacity={0} />
                </LinearGradient>
                <RadialGradient
                    id="paint68_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(41.798 -23.734 67.335) scale(5.37602 12.9508)"
                >
                    <Stop offset={0.188388} stopColor="#E4DDED" />
                    <Stop offset={1} stopColor="#D5CFDC" />
                </RadialGradient>
                <LinearGradient
                    id="paint69_linear_376_26284"
                    x1={42.373}
                    y1={37.3895}
                    x2={41.997}
                    y2={36.9586}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset={0.461334} stopColor="#BFB8CA" />
                    <Stop offset={1} stopColor="#D9D3E0" stopOpacity={0} />
                </LinearGradient>
                <RadialGradient
                    id="paint70_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(-139.622 28.478 10.782) scale(6.9531 10.7215)"
                >
                    <Stop stopColor="#CDCEDE" stopOpacity={0} />
                    <Stop offset={0.86023} stopColor="#CDCEDE" stopOpacity={0} />
                    <Stop offset={0.912513} stopColor="#CDCEDE" stopOpacity={0.180902} />
                    <Stop offset={1} stopColor="#CDCEDE" />
                </RadialGradient>
                <RadialGradient
                    id="paint71_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(141.227 6.877 5.767) scale(7.3563 7.99405)"
                >
                    <Stop offset={0.525413} stopColor="#C2C4CC" />
                    <Stop offset={0.961617} stopColor="#938DA1" />
                </RadialGradient>
                <RadialGradient
                    id="paint72_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(1.9723 -3.09713 3.4411 2.19135 13.85 6.8)"
                >
                    <Stop offset={0.545652} stopColor="#8F9CBB" />
                    <Stop offset={1} stopColor="#8F96BB" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint73_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(96.243 4.145 9.574) scale(5.12918 5.53425)"
                >
                    <Stop stopColor="#A59BB1" stopOpacity={0} />
                    <Stop offset={0.774508} stopColor="#A59BB1" stopOpacity={0} />
                    <Stop offset={0.844801} stopColor="#A59BB1" stopOpacity={0.187895} />
                    <Stop offset={1} stopColor="#A59BB1" />
                </RadialGradient>
                <RadialGradient
                    id="paint74_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(-.1851 4.5865 -4.95589 -.2 13.74 6.457)"
                >
                    <Stop offset={0.583671} stopColor="#DCD9E0" stopOpacity={0} />
                    <Stop offset={0.663069} stopColor="#DCD9E0" stopOpacity={0.288065} />
                    <Stop offset={0.753915} stopColor="#DCD9E0" stopOpacity={0.737708} />
                    <Stop offset={0.795343} stopColor="#DCD9E0" stopOpacity={0.82} />
                    <Stop offset={0.832699} stopColor="#DCD9E0" stopOpacity={0.77} />
                    <Stop offset={0.90262} stopColor="#DCD9E0" stopOpacity={0.460107} />
                    <Stop offset={0.955219} stopColor="#DCD9E0" stopOpacity={0.162519} />
                    <Stop offset={1} stopColor="#DCD9E0" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint75_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(0 -.85815 4.09364 0 18.145 6.413)"
                >
                    <Stop stopColor="#A59BB1" />
                    <Stop offset={0.685748} stopColor="#A59BB1" stopOpacity={0.206761} />
                    <Stop offset={1} stopColor="#A59BB1" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint76_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(-5.29252 -5.27224 23.3575 -23.44736 17.023 10.233)"
                >
                    <Stop stopColor="#C2C4CC" />
                    <Stop offset={0.685748} stopColor="#C2C4CC" stopOpacity={0.206761} />
                    <Stop offset={1} stopColor="#C2C4CC" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint77_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(-2.45162 -3.77646 15.5866 -10.11858 14.763 12.098)"
                >
                    <Stop stopColor="#938DA1" />
                    <Stop offset={0.580377} stopColor="#938DA1" stopOpacity={0.206761} />
                    <Stop offset={1} stopColor="#938DA1" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint78_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(141.227 11.274 7.314) scale(7.3563 7.99405)"
                >
                    <Stop offset={0.525413} stopColor="#C2C4CC" />
                    <Stop offset={0.961617} stopColor="#938DA1" />
                </RadialGradient>
                <RadialGradient
                    id="paint79_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(1.9723 -3.09713 3.4411 2.19135 22.647 6.8)"
                >
                    <Stop offset={0.545652} stopColor="#8F9CBB" />
                    <Stop offset={1} stopColor="#8F96BB" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint80_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(96.243 8.543 13.518) scale(5.12918 5.53425)"
                >
                    <Stop stopColor="#A59BB1" stopOpacity={0} />
                    <Stop offset={0.774508} stopColor="#A59BB1" stopOpacity={0} />
                    <Stop offset={0.844801} stopColor="#A59BB1" stopOpacity={0.187895} />
                    <Stop offset={1} stopColor="#A59BB1" />
                </RadialGradient>
                <RadialGradient
                    id="paint81_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(-.1851 4.5865 -4.95589 -.2 22.537 6.457)"
                >
                    <Stop offset={0.583671} stopColor="#DCD9E0" stopOpacity={0} />
                    <Stop offset={0.663069} stopColor="#DCD9E0" stopOpacity={0.288065} />
                    <Stop offset={0.753915} stopColor="#DCD9E0" stopOpacity={0.737708} />
                    <Stop offset={0.795343} stopColor="#DCD9E0" stopOpacity={0.82} />
                    <Stop offset={0.832699} stopColor="#DCD9E0" stopOpacity={0.77} />
                    <Stop offset={0.90262} stopColor="#DCD9E0" stopOpacity={0.460107} />
                    <Stop offset={0.955219} stopColor="#DCD9E0" stopOpacity={0.162519} />
                    <Stop offset={1} stopColor="#DCD9E0" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint82_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(0 -.85815 4.09364 0 26.941 6.413)"
                >
                    <Stop stopColor="#A59BB1" />
                    <Stop offset={0.685748} stopColor="#A59BB1" stopOpacity={0.206761} />
                    <Stop offset={1} stopColor="#A59BB1" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint83_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(-5.29252 -5.27224 23.3575 -23.44736 25.82 10.233)"
                >
                    <Stop stopColor="#C2C4CC" />
                    <Stop offset={0.685748} stopColor="#C2C4CC" stopOpacity={0.206761} />
                    <Stop offset={1} stopColor="#C2C4CC" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint84_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(-2.45162 -3.77646 15.5866 -10.11858 23.559 12.098)"
                >
                    <Stop stopColor="#938DA1" />
                    <Stop offset={0.580377} stopColor="#938DA1" stopOpacity={0.206761} />
                    <Stop offset={1} stopColor="#938DA1" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint85_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(141.227 15.704 8.873) scale(7.3563 7.99405)"
                >
                    <Stop offset={0.525413} stopColor="#C2C4CC" />
                    <Stop offset={0.961617} stopColor="#938DA1" />
                </RadialGradient>
                <RadialGradient
                    id="paint86_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(1.9723 -3.09713 3.4411 2.19135 31.506 6.8)"
                >
                    <Stop offset={0.545652} stopColor="#8F9CBB" />
                    <Stop offset={1} stopColor="#8F96BB" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint87_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(96.243 12.972 17.49) scale(5.12918 5.53425)"
                >
                    <Stop stopColor="#A59BB1" stopOpacity={0} />
                    <Stop offset={0.774508} stopColor="#A59BB1" stopOpacity={0} />
                    <Stop offset={0.844801} stopColor="#A59BB1" stopOpacity={0.187895} />
                    <Stop offset={1} stopColor="#A59BB1" />
                </RadialGradient>
                <RadialGradient
                    id="paint88_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(-.1851 4.5865 -4.95589 -.2 31.396 6.457)"
                >
                    <Stop offset={0.583671} stopColor="#DCD9E0" stopOpacity={0} />
                    <Stop offset={0.663069} stopColor="#DCD9E0" stopOpacity={0.288065} />
                    <Stop offset={0.753915} stopColor="#DCD9E0" stopOpacity={0.737708} />
                    <Stop offset={0.795343} stopColor="#DCD9E0" stopOpacity={0.82} />
                    <Stop offset={0.832699} stopColor="#DCD9E0" stopOpacity={0.77} />
                    <Stop offset={0.90262} stopColor="#DCD9E0" stopOpacity={0.460107} />
                    <Stop offset={0.955219} stopColor="#DCD9E0" stopOpacity={0.162519} />
                    <Stop offset={1} stopColor="#DCD9E0" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint89_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(0 -.85815 4.09364 0 35.8 6.413)"
                >
                    <Stop stopColor="#A59BB1" />
                    <Stop offset={0.685748} stopColor="#A59BB1" stopOpacity={0.206761} />
                    <Stop offset={1} stopColor="#A59BB1" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint90_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(-5.29252 -5.27224 23.3575 -23.44736 34.678 10.233)"
                >
                    <Stop stopColor="#C2C4CC" />
                    <Stop offset={0.685748} stopColor="#C2C4CC" stopOpacity={0.206761} />
                    <Stop offset={1} stopColor="#C2C4CC" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint91_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(-2.45162 -3.77646 15.5866 -10.11858 32.418 12.098)"
                >
                    <Stop stopColor="#938DA1" />
                    <Stop offset={0.580377} stopColor="#938DA1" stopOpacity={0.206761} />
                    <Stop offset={1} stopColor="#938DA1" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint92_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(-5.82712 4.60677 -4.98842 -6.30986 42.468 5.956)"
                >
                    <Stop offset={0.525413} stopColor="#C2C4CC" />
                    <Stop offset={0.961617} stopColor="#938DA1" />
                </RadialGradient>
                <RadialGradient
                    id="paint93_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(2.0039 -3.09713 3.46394 2.24124 40.438 6.8)"
                >
                    <Stop offset={0.545652} stopColor="#8F9CBB" />
                    <Stop offset={1} stopColor="#8F96BB" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint94_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(-.56667 5.09877 -5.58744 -.62099 40.704 6.495)"
                >
                    <Stop stopColor="#A59BB1" stopOpacity={0} />
                    <Stop offset={0.774508} stopColor="#A59BB1" stopOpacity={0} />
                    <Stop offset={0.844801} stopColor="#A59BB1" stopOpacity={0.187895} />
                    <Stop offset={1} stopColor="#A59BB1" />
                </RadialGradient>
                <RadialGradient
                    id="paint95_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(-.18806 4.5865 -5.03502 -.20645 40.325 6.457)"
                >
                    <Stop offset={0.583671} stopColor="#DCD9E0" stopOpacity={0} />
                    <Stop offset={0.663069} stopColor="#DCD9E0" stopOpacity={0.288065} />
                    <Stop offset={0.753915} stopColor="#DCD9E0" stopOpacity={0.737708} />
                    <Stop offset={0.795343} stopColor="#DCD9E0" stopOpacity={0.82} />
                    <Stop offset={0.832699} stopColor="#DCD9E0" stopOpacity={0.77} />
                    <Stop offset={0.90262} stopColor="#DCD9E0" stopOpacity={0.460107} />
                    <Stop offset={0.955219} stopColor="#DCD9E0" stopOpacity={0.162519} />
                    <Stop offset={1} stopColor="#DCD9E0" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint96_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(0 -.85815 4.15923 0 44.8 6.413)"
                >
                    <Stop stopColor="#A59BB1" />
                    <Stop offset={0.685748} stopColor="#A59BB1" stopOpacity={0.206761} />
                    <Stop offset={1} stopColor="#A59BB1" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint97_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(-135.565 23.92 -3.8) scale(7.53074 33.357)"
                >
                    <Stop stopColor="#C2C4CC" />
                    <Stop offset={0.685748} stopColor="#C2C4CC" stopOpacity={0.206761} />
                    <Stop offset={1} stopColor="#C2C4CC" stopOpacity={0} />
                </RadialGradient>
                <RadialGradient
                    id="paint98_radial_376_26284"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="rotate(-123.408 23.939 -5.085) scale(4.52396 18.791)"
                >
                    <Stop stopColor="#938DA1" />
                    <Stop offset={0.580377} stopColor="#938DA1" stopOpacity={0.206761} />
                    <Stop offset={1} stopColor="#938DA1" stopOpacity={0} />
                </RadialGradient>
            </Defs>
        </Svg>
    );
}
