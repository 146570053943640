import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { Animated, ScrollView, StyleProp, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { getGroupedTags, UserSelectTags } from '@sapphicsavvy/business';
import { FONT_STYLES, textStyles } from '../../../constants/textStyles';
import { getTopInset, hitSlop } from '../../../utils/shared';
import XIcon from '../../../resources/svgComponents/XIcon';
import { Spacer } from '../Spacer';
import Feather from 'react-native-vector-icons/Feather';
import { ShadowView } from '../ShadowView';
import { utils } from '../../../utils/utils';

type CollapsibleViewProps = {
    title: string,
    children?: React.ReactNode,
    open?: boolean,
    style?: StyleProp<ViewStyle>
}

function CollapsibleView({ title, children, open = false, style }: CollapsibleViewProps) {

    const [isOpen, setIsOpen] = useState(open);
    const animatedValue = useRef(new Animated.Value(1)).current;
    const maxContent = useRef(1);

    const onToggle = useCallback(() => {
        if (isOpen) {
            Animated.timing(animatedValue, {
                toValue: 1,
                duration: 100,
                useNativeDriver: false,
            }).start(end => end.finished && setIsOpen(false));
        } else {
            Animated.timing(animatedValue, {
                toValue: maxContent.current,
                duration: 100,
                useNativeDriver: false,
            }).start(end => end.finished && setIsOpen(true));
        }
    }, [animatedValue, isOpen]);

    const onContentSizeChange = useCallback((_w: number, h: number) => {
        maxContent.current = h;
        if (open) {
            animatedValue.setValue(h);
        }
    }, [animatedValue, open]);

    const rotation = animatedValue.interpolate({
        inputRange: [1, maxContent.current],
        outputRange: ['0deg', '180deg'],
    });

    return (
        <View style={style}>
            <View style={styles.cHeader}>
                <Text onPress={onToggle} suppressHighlighting style={styles.cHeaderText}>{title}</Text>
                <TouchableOpacity
                    style={styles.cHeadeAction}
                    hitSlop={hitSlop}
                    onPress={onToggle}
                >
                    <Animated.View
                        style={{ transform: [{ rotate: rotation }] }}
                    >
                        <Feather name="chevron-down" size={20} color="#292D32" />
                    </Animated.View>
                </TouchableOpacity>
            </View>
            <Animated.ScrollView
                onContentSizeChange={onContentSizeChange}
                style={{ height: animatedValue }}
                scrollEnabled={false}
                showsVerticalScrollIndicator={false}
            >
                {children}
            </Animated.ScrollView>
        </View>
    );
}

export type EditTagModalProps = {
    isVisible: boolean,
    tags?: UserSelectTags[],
    max?: number,
    onDismiss?: (tags?: UserSelectTags[]) => void,
}

export default function EditTagModal({ isVisible, tags, max, onDismiss }: EditTagModalProps) {

    const [selectedTags, setSelectedTags] = useState(tags);

    const toggleTag = useCallback((item: UserSelectTags) => {
        setSelectedTags(prev => {
            if (prev?.includes(item)) {
                return prev.filter(tag => tag !== item);
            } else {
                const newTags = [...(prev || []), item];
                if (max && newTags.length > max) {
                    utils.showToast('error', `you can't add more than ${max} tags`);
                    return prev;
                }
                return newTags;
            }
        });
    }, [max]);

    const isTagActive = useCallback((item: UserSelectTags) => selectedTags?.includes(item), [selectedTags]);

    useEffect(() => {
        if (isVisible) {
            setSelectedTags(tags);
        }
    }, [isVisible, tags]);

    return (
        <MB_Modal
            isVisible={isVisible}
            onDismiss={() => onDismiss?.()}
            hideCloseButton
            childrenWrapperStyle={styles.container}
            withoutScrollView
            withoutSafeArea
            statusBarTranslucent
        >
            <View style={styles.header}>
                <Text style={styles.headerTitleText}>ADD TAGS ABOUT YOURSELF</Text>
                <TouchableOpacity
                    onPress={() => onDismiss?.()}
                    hitSlop={hitSlop}
                >
                    <XIcon size={20} color="#FFFFFF" />
                </TouchableOpacity>
            </View>
            <ScrollView
                style={styles.content}
                contentContainerStyle={styles.contentContainer}
            >
                <View>
                    <Text style={styles.selectedTagsText}>Selected Tags</Text>
                    <View style={styles.tags}>
                        {selectedTags?.map((item, index) => (
                            <ShadowView key={`${item}-${index}`} style={styles.tag}>
                                <Text style={styles.tagText}>{item}</Text>
                                <Spacer width={5} />
                                <Feather
                                    name="x"
                                    size={20}
                                    color={mbApplyTransparency('#6D6E71', 0.55)}
                                    onPress={() => toggleTag(item)}
                                />
                            </ShadowView>
                        ))}
                    </View>
                </View>
                {getGroupedTags()
                    .map((g: { label: string, tags: UserSelectTags[] }, gIndex: number) => (
                        <View key={g.label}>
                            <View style={styles.separator} />
                            <CollapsibleView title={g.label} open={gIndex === 0} style={styles.section}>
                                <View style={styles.tags}>
                                    {g.tags.map((item, index) => (
                                        <ShadowView
                                            onPress={() => toggleTag(item)}
                                            key={`${item}-${index}`}
                                            style={[styles.gTag, isTagActive(item) && styles.gTagActive]}
                                        >
                                            <Text>{item}</Text>
                                        </ShadowView>
                                    ))}
                                </View>
                            </CollapsibleView>
                        </View>
                    ))
                }
            </ScrollView>
            <View style={styles.footer}>
                <MB_Button
                    title="UPDATE TAGS"
                    style={styles.primaryBtn}
                    textStyle={styles.primaryBtnText}
                    onPress={() => onDismiss?.(selectedTags)}
                />
            </View>
        </MB_Modal>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#EFEFF3',
        marginVertical: 0,
        width: '100%',
        height: '100%',
        paddingHorizontal: 0,
        paddingBottom: 0,
        marginBottom: 1,
        paddingTop: 0,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
        paddingBottom: 20,
        paddingLeft: 30,
        paddingRight: 20,
        paddingTop: 20 + getTopInset(),
        backgroundColor: '#F35E2E',
    },
    headerTitleText: mbTextStyles([{
        fontFamily: FONT_STYLES.Tabasco_400,
        fontSize: 18,
        textAlign: 'left',
        color: '#FFFFFF',
    }]),
    content: {
        alignSelf: 'stretch',
        flex: 1,
        paddingHorizontal: 20,
    },
    contentContainer: {
        paddingTop: 20,
    },
    selectedTagsText: mbTextStyles([textStyles.normalText, {
        fontFamily: FONT_STYLES.PlusJakartaSans_600,
        color: mbApplyTransparency('#000000', 0.55),
        textAlign: 'left',
    }]),
    tags: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 15,
    },
    tag: {
        backgroundColor: '#EFEFF3',
        borderRadius: 10,
        paddingLeft: 12,
        paddingRight: 4,
        paddingVertical: 4,
        flexDirection: 'row',
        marginTop: 12,
        marginLeft: 8,
    },
    gTag: {
        backgroundColor: '#EFEFF3',
        borderRadius: 10,
        paddingLeft: 12,
        paddingRight: 12,
        paddingVertical: 4,
        marginTop: 15,
        marginLeft: 9,
        borderWidth: 1,
        borderColor: '#EFEFF3',
    },
    gTagActive: {
        borderColor: '#F35E2E',
    },
    tagText: mbTextStyles([textStyles.smallText, {
        color: '#F35E2E',
        textAlign: 'left',
    }]),
    section: {
        marginVertical: 15,
    },
    separator: {
        height: 1,
        backgroundColor: '#8E9396',
        marginHorizontal: -20,
    },
    footer: {
        height: 97,
        paddingTop: 18,
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        alignSelf: 'stretch',
        paddingHorizontal: 29,
        ...mbShadow({
            offsetWidth: -5,
            offsetHeight: -5,
            opacity: 0.5,
            color: '#AEAEC0',
            radius: 20,
            elevation: 20,
        }),
    },
    primaryBtn: {
        height: 43,
        backgroundColor: '#F35E2E',
        borderRadius: 8,
        alignSelf: 'stretch',
    },
    primaryBtnText: mbTextStyles([textStyles.normalText, {
        textAlign: 'left',
        color: '#FFFFFF',
        fontWeight: '800',
    }]),


    // Collapse
    cHeader: {
        height: 40,
        justifyContent: 'center',
    },
    cHeadeAction: {
        position: 'absolute',
        right: 10,
    },
    cHeaderText: mbTextStyles([textStyles.normalText, {
        fontWeight: '600',
        fontFamily: FONT_STYLES.PlusJakartaSans_600,
        color: '#000000',
        textAlign: 'left',
        alignSelf: 'flex-start',
    }]),
});
