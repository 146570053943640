import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

export default function DonateBoxIcon() {
    return (
        <Svg
            width={25}
            height={30}
            viewBox="0 0 25 30"
            fill="none"
        >
            <Path
                d="M20.808 11.69c.2 0 .37.12.442.293l3.22 5.022c.04.08.056.165.054.248l-.213 11.982a.482.482 0 01-.48.483v.003H1.26a.483.483 0 01-.482-.486L.522 17.227V17.163l.002-.01v-.002l.002-.01.003-.006v-.005l.002-.007v-.038l.002-.005v-.004l.003-.005v-.002l.002-.008.002-.004.003-.005.002-.005.002-.004.003-.005.002-.005.002-.005.003-.007.002-.004.002-.007.003-.005V17l.002-.005.002-.004.003-.005.007.019.004-.007.003-.005.004-.007.003-.002v-.003l.002-.002 3.262-5.027a.48.48 0 01.426-.26c5.936-.002 10.59-.002 16.526-.002zM10.351.72v5.56H8.05l4.474 5.035 4.474-5.034h-2.303V.72h-4.343zM4.57 12.66L1.8 16.745h21.456l-2.738-4.085H4.57zm11.853 1.522c.267 0 .482.217.482.486a.483.483 0 01-.482.487H9.33a.483.483 0 01-.482-.486.48.48 0 01.482-.484h.812c2.436 0 3.844-.003 6.282-.003zm-14.94 3.536l.258 11.033h21.608l.218-11.033H1.483z"
                fill="#F35E2E"
            />
        </Svg>
    );
}
