import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type IconProps = {
    color?: string,
    size?: number
}

export default function SlidersIcon({ color = '#F35E2E', size = 21 }: IconProps) {
    return (
        <Svg
            width={size}
            height={size * (22 / 21)}
            viewBox="0 0 21 22"
            fill="none"
        >
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 2.61v1.802c0 .22-.208.401-.462.401h-7.163a3.03 3.03 0 01-2.732 1.711 3.029 3.029 0 01-2.73-1.71H.463C.21 4.813 0 4.631 0 4.411V2.61c0-.22.208-.402.462-.402h7.451A3.027 3.027 0 0110.643.5a3.03 3.03 0 012.73 1.709h7.163c.256 0 .464.181.464.402zM8.516 19.792A3.028 3.028 0 015.785 21.5a3.027 3.027 0 01-2.73-1.709H.462c-.254 0-.462-.181-.462-.402v-1.8c0-.221.208-.402.462-.402h2.593a3.029 3.029 0 012.73-1.711c1.202 0 2.242.699 2.73 1.71h12.021c.254 0 .462.182.462.402v1.801c0 .22-.21.402-.462.402H8.516zm9.373-7.374a3.033 3.033 0 01-5.462 0H.462c-.252 0-.462-.181-.462-.402v-1.8c0-.22.208-.401.462-.401h11.965a3.027 3.027 0 012.73-1.709 3.03 3.03 0 012.73 1.709h2.65c.253 0 .461.181.461.402v1.8c0 .22-.208.4-.462.4H17.89z"
                fill={color}
            />
        </Svg>
    );
}

