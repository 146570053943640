import React, { useCallback, useMemo, useState } from 'react';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import sharedStyles from './Shared/styles';
import { StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native';
import { Header } from './Shared/Header';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';
import { MB_Table, MB_TableProps } from '@mightybyte/rnw.components.table';
import { trpc } from '../../../apiCalls/trpcClient';
import Feather from 'react-native-vector-icons/Feather';
import MailIcon from '../../../resources/svgComponents/MailIcon';
import TrashIcon from '../../../resources/svgComponents/TrashIcon';
import { Indicators } from './Shared/Indicators';
import UsersIcon from '../../../resources/svgComponents/Admin/UsersIcon';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import { utilHooks } from '../../../hooks/utilHooks';
import { UserDataForAdmin } from '@sapphicsavvy/business';
import { EditUserModal } from './modals/EditUserModal';
import { useModalState } from '../../../utils/shared';
import {Spacer} from '../../helperComponents/Spacer';
import DeleteUserModal from './modals/DeleteUserModal';
import {utils} from './../../../utils/utils';

const headerTextStyle = mbTextStyles([textStyles.normalText, { color: '#000000', textAlign: 'left' }]);
const headerConatinerStyle: ViewStyle = {
    height: 52,
    justifyContent: 'center',
    paddingRight: 0,
    marginRight: 30,
};

const header: MB_TableProps['header'] = [
    { content: 'Username', conatinerStyle: { ...headerConatinerStyle, justifyContent: undefined, minWidth: 90, maxWidth: 120, flex: 1 }, textStyle: headerTextStyle },
    { content: 'Email/Phone', conatinerStyle: { ...headerConatinerStyle, justifyContent: 'flex-start', minWidth: 152, flex: 1 }, textStyle: headerTextStyle },
    { content: 'Sign up', conatinerStyle: { ...headerConatinerStyle, minWidth: 90, maxWidth: 90 }, textStyle: headerTextStyle },
    { content: 'Access', conatinerStyle: { ...headerConatinerStyle, minWidth: 81, maxWidth: 81 }, textStyle: headerTextStyle },
    { content: 'In Study', conatinerStyle: { ...headerConatinerStyle, minWidth: 81, maxWidth: 81 }, textStyle: headerTextStyle },
    { content: 'Featured', conatinerStyle: { ...headerConatinerStyle, minWidth: 81, maxWidth: 81 }, textStyle: headerTextStyle },
    { content: 'Actions', conatinerStyle: { ...headerConatinerStyle, minWidth: 90, maxWidth: 90 }, textStyle: headerTextStyle },
];

const formatAccess = (hasFullAccess: boolean) => hasFullAccess ? 'Full' : 'Limited';

const formatInStudy = (inStudy: boolean) => inStudy ? 'Yes' : 'No';

const UserSettings = () => {

    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const searchQuery = utilHooks.useDebounce(query, 200);
    const { data: { items, totalItems } = {}, isLoading } = trpc.admin.users.getPage.useQuery({ page, pageSize: 10, searchString: searchQuery ?? undefined });
    // const [userInfo, setUserInfo] = useState<UserDataForAdmin & { type: 'edit' | 'delete' }>();
    const { state: modalInfo, show, hide } = useModalState<{ user: UserDataForAdmin, type: 'edit' | 'delete' }>();

    const onChangeText = useCallback((text: string) => {
        setPage(1);
        setQuery(text);
    }, []);

    const data = useMemo(() => {
        return items?.map((user) => [
            <Text style={[styles.cellText, styles.cellLeftText]} selectable numberOfLines={1}>{user.userName ?? 'N/A'}</Text>,
            <View>
                <Text style={[styles.cellText, styles.cellLeftText]} selectable numberOfLines={1}>{user.email ?? 'N/A'}</Text>
                <Spacer height={8} />
                <Text style={[styles.cellText, styles.cellLeftText]} selectable numberOfLines={1}>{user.phoneNumber ?? 'N/A'}</Text>
            </View>,

            <Text style={[styles.cellText]} numberOfLines={1}>{utils.formatDateForAdmin(user.registrationDate)}</Text>,
            <Text style={[styles.cellText]} numberOfLines={1}>{formatAccess(user.hasFullAccess)}</Text>,
            <Text style={[styles.cellText]} numberOfLines={1}>{formatInStudy(user.isInStudy)}</Text>,
            <Text style={[styles.cellText]} numberOfLines={1}>Featured</Text>,
            <View style={styles.actions}>
                <Feather name="settings" size={20} onPress={() => show({ user, type: 'edit'})} />
                <MailIcon size={24} />
                <TouchableOpacity onPress={() => show({ user, type: 'delete'})}>
                    <TrashIcon size={18} />
                </TouchableOpacity>
            </View>,
        ]);
    }, [items, show]);

    const keyExtractor = useCallback((index) => items?.[index].id ?? index.toString(), [items]);
    const totalPages = useMemo(() => Math.ceil((totalItems || 0) / 10), [totalItems]);

    return (
        <ComponentWrapper
            innerContainerStyle={sharedStyles.innerContainer}
            containerStyle={sharedStyles.container}
            scrollViewStyle={sharedStyles.scrollView}
            wrapInScrollView
        >
            <Header />
            <View style={sharedStyles.body}>
                <View style={styles.content}>
                    <View style={styles.row}>
                        <View style={styles.row}>
                            <UsersIcon color="#F35E2E" />
                            <Text style={styles.titleText}>User Settings</Text>
                        </View>
                        <MB_TextInput
                            onChangeText={onChangeText}
                            style={styles.input}
                            placeholder="Search Users"
                            placeholderTextColor="#ADADAD"
                            textInputStyle={styles.textInput}
                            leftIcon={<Feather name="search" color="#1E1E1E" size={18} />}
                        />
                    </View>
                    <MB_Table
                        header={header}
                        data={data}
                        headerStyle={styles.tableHeader}
                        rowStyle={styles.rowStyle}
                        style={styles.table}
                        keyExtractor={keyExtractor}
                        loading={isLoading}
                        loadingScreenProps={{ textColor: '#F35E2E' }}
                    />
                </View>
                <Indicators
                    page={page}
                    totalPages={totalPages}
                    onPageChange={setPage}
                />
            </View>
            <EditUserModal isVisible={modalInfo?.type === 'edit'} userInfo={modalInfo?.user} onDismiss={hide} />
            <DeleteUserModal isVisible={modalInfo?.type === 'delete'} userInfo={modalInfo?.user} onDismiss={hide} />
        </ComponentWrapper>
    );

};

export { UserSettings };

const styles = StyleSheet.create({
    content: {
        backgroundColor: '#FFFFFF',
        borderRadius: 12,
        padding: 20,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    titleText: mbTextStyles([textStyles.largeText, {
        fontSize: 22,
        color: '#F35E2E',
        textAlign: 'left',
        marginLeft: 10,
    }]),
    input: {
        backgroundColor: '#FAFAFA',
        borderRadius: 22,
        borderWidth: 0,
        width: 304,
    },
    textInput: mbTextStyles([textStyles.normalText, {
        textAlign: 'left',
        color: '#000000',
        fontWeight: '400',
        marginLeft: 10,
    }]),
    table: {
        marginTop: 20,
        borderWidth: 0,
    },
    cellText: mbTextStyles([textStyles.smallText, {
        color: '#1E1E1E',
        fontWeight: '400',
        textAlign: 'center',
        width: '100%',
    }]),
    cellLeftText: {
        textAlign: 'left',
    },
    actions: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    tableHeader: {
        height: 50,
        backgroundColor: '#FFF4F0',
        borderBottomWidth: 0,
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        marginBottom: -4,
        zIndex: 2,
    },
    rowStyle: {
        height: 68,
        backgroundColor: '#FFFFFF',
        justifyContent: 'center',
    },
});
