import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import Svg, { Path, G, Defs, ClipPath } from 'react-native-svg';

type MedalIconProps = {
    color?: string;
    type?: 'gold' | 'silver' | 'bronze';
    style?: StyleProp<ViewStyle>;
};

const colors = {
    gold: '#FFD54B',
    silver: '#CACAD3',
    bronze: '#F6B191',
};

export function MedalIcon({ color, type, style }: MedalIconProps) {
    return (
        <Svg
            width={30}
            height={29}
            viewBox="0 0 30 29"
            fill="none"
            style={style}
        >
            <Path
                d="M13.096 1.245a4 4 0 013.808 0l4.584 2.481 4.388 2.354a4 4 0 012.11 3.534l-.011 4.6.01 4.6a4 4 0 01-2.11 3.534l-4.387 2.353-4.584 2.482a4 4 0 01-3.808 0L8.512 24.7l-4.388-2.353a4 4 0 01-2.11-3.534l.011-4.6-.01-4.6a4 4 0 012.11-3.534l4.387-2.354 4.584-2.481z"
                fill={color || colors[type || 'gold']}
            />
            <G clipPath="url(#clip0_218_28506)">
                <Path
                    d="M15 10.714l2.5 3.5 3.125-2.334-1.25 5.834h-8.75l-1.25-5.833 3.125 2.333 2.5-3.5z"
                    fill="#fff"
                    stroke="#fff"
                    strokeWidth={1.5}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </G>
            <Defs>
                <ClipPath id="clip0_218_28506">
                    <Path
                        fill="#fff"
                        transform="translate(7.5 7.214)"
                        d="M0 0H15V14H0z"
                    />
                </ClipPath>
            </Defs>
        </Svg>
    );
}
