import { z } from 'zod';
import { PaginationInputSchema } from '../../common';
import {
    ShapeQuestionModerationStatus,
    ShapeCategories,
} from '../../../entities';

export const GetShapePageInputSchema = PaginationInputSchema.extend({
    filter: z.object({
        status: z.array(z.nativeEnum(ShapeQuestionModerationStatus)),
        category: z.array(z.nativeEnum(ShapeCategories)).nullish(),
    }),
});

export const ModerateQuestionInputSchema = z.object({
    questionId: z.string(),
    status: z.nativeEnum(ShapeQuestionModerationStatus),
});

export const DeleteQuestionInputSchema = z.object({
    questionId: z.string(),
});

export const EditShapeQuestionInputSchema = z.object({
    questionId: z.string(),
    question: z.string(),
    shouldApprove: z.boolean(),
});
