import React from 'react';
import { Dimensions, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import ArrowLeft from '../../../../../resources/svgComponents/ArrowLeft';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../../constants/textStyles';
import { useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../../../../navigations/RootNavigator';
import { getTopInset } from '../../../../../utils/shared';


const Header = ({ title, hasScroll }: { title: string, hasScroll?: boolean }) => {

    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

    return (
        <View style={styles.container}>
            <View style={[styles.gradient, hasScroll && styles.hasScroll]} />
            <TouchableOpacity style={styles.backIcon} onPress={() => navigation.goBack()}>
                <ArrowLeft />
            </TouchableOpacity>
            <Text style={styles.titleText}>{title}</Text>
        </View>
    );
};

export { Header };

const height = 59 + getTopInset();

const styles = StyleSheet.create({
    container: {
        height: height,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        marginHorizontal: -16,
    },
    gradient: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        backgroundColor: '#F35E2E',
    },
    backIcon: {
        position: 'absolute',
        left: 20,
        bottom: 12,
    },
    titleText: mbTextStyles([textStyles.largeText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        fontSize: 22,
        position: 'absolute',
        bottom: 19,
    }]),
    hasScroll: {
        height: Dimensions.get('window').height,
        top: -Dimensions.get('window').height + height,
    },
});
