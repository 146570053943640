import React, { useMemo } from 'react';
import { MB_Modal } from '@mightybyte/rnw.components.modal';
import { ShapeDataForAdminClient, ShapeQuestionModerationStatus } from '@sapphicsavvy/business';
import { StyleSheet, Text, View } from 'react-native';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { trpc } from '../../../../apiCalls/trpcClient';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import { Spacer } from '../../../helperComponents/Spacer';
import XIcon from '../../../../resources/svgComponents/XIcon';
import CheckIcon     from '../../../../resources/svgComponents/CheckIcon';
import TrashIcon from '../../../../resources/svgComponents/TrashIcon';
import { getColors } from '../../../../constants/colors';
import { utils } from '../../../../utils/utils';

const COLORS = getColors();

export enum ShapeActionTypes {
    APPROVE = 'approve',
    REJECT = 'reject',
    REVERT_FEATURED = 'revertFeatured',
    DELETE = 'delete',
}

type Props = {
    isVisible: boolean;
    questionInfo?: ShapeDataForAdminClient;
    onDismiss?: () => void;
    actionType: ShapeActionTypes;
    onModerateSuccess: (message: string) => void;
}


export default function ModerateShapeQuestionModal(
    {
        isVisible,
        questionInfo,
        onDismiss,
        actionType,
        onModerateSuccess,
}:Props) {

    const { mutate: moderateQuestion, isLoading } = trpc.admin.shape.moderate.useMutation();

    const isReject = actionType === ShapeActionTypes.REJECT;
    const isRevertingFeatured = actionType === ShapeActionTypes.REVERT_FEATURED;
    const isDelete = actionType === ShapeActionTypes.DELETE;


    const onModerate = () => {
        if (!questionInfo){
            return;
        }
        moderateQuestion({
            questionId: questionInfo.id,
            status: isReject ? ShapeQuestionModerationStatus.rejected :  ShapeQuestionModerationStatus.approved,
        }, {
            onSuccess: () => {
                const message = isRevertingFeatured ? 'Question has been reverted to voting' :
                    isDelete ? 'Question has been deleted' :
                    `Question has been ${isReject ? 'rejected' : 'approved'}`;
                onModerateSuccess(message);
            },
            onError: (error) => {
               utils.showToast('error', error?.message ?? 'Request failed');
            },
        });
    };

    const title = useMemo(() => {
        if (isRevertingFeatured){
            return 'Continue voting on question';
        }
        if (isDelete){
            return 'Delete question';
        }
        return isReject ? 'Reject question' : 'Approve question';
    }, [isDelete, isReject, isRevertingFeatured]);

    const subtitle = useMemo(() => {
        if (isRevertingFeatured){
            return 'This question will be available for voting again';
        }
        if (isDelete){
            return 'This question will be permanently deleted';
        }
        return `Are you sure you want to ${isReject ? 'reject' : 'approve'} this question?`;
    }, [isDelete, isReject, isRevertingFeatured]);

    const buttonText = useMemo(() => {
        if (isRevertingFeatured){
            return 'Continue voting';
        }
        if (isDelete){
            return 'Delete';
        }
        return isReject ? 'Reject' : 'Approve';
    }, [isReject, isRevertingFeatured, isDelete]);

    const icon = useMemo(() => {
        if (isReject){
            return <XIcon size={24} color={COLORS.red}/>;
        }
        if (isDelete){
            return  <TrashIcon size={23} />;
        }

        return <CheckIcon size={36} color={COLORS.green}/>;
    }, [isReject, isDelete]);


    if (!actionType) {
        return null;
    }


    return (
        <MB_Modal
            isVisible={isVisible}
            onDismiss={onDismiss}
            hideCloseButton
            childrenWrapperStyle={styles.container}
        >
            <View style={[styles.headerIcon, {
                backgroundColor: isReject || isDelete ? mbApplyTransparency('#E53232', 0.13) : mbApplyTransparency('#00A86B', 0.13),
            }]}>
                {icon}
            </View>
            <Spacer height={10} />
            <View>
                <Text style={styles.titleText}>{title}</Text>
                <Spacer height={20} />
                <View style={styles.user}>
                    <Text style={styles.userText}>{questionInfo?.question}</Text>
                </View>
                <Spacer height={20} />
                <Text style={styles.noteText}>{subtitle}</Text>
                <Spacer height={10} />

                <View style={styles.footer}>
                    <MB_Button
                        title={buttonText}
                        style={styles.primaryBtn}
                        textStyle={styles.primaryBtnText}
                        onPress={onModerate}
                        disabled={isLoading}
                        loading={isLoading}
                    />
                    <MB_Button
                        title="Cancel"
                        style={styles.secondaryBtn}
                        textStyle={[styles.primaryBtnText, styles.secondaryBtnText]}
                        onPress={onDismiss}
                        disabled={isLoading}
                    />
                </View>
            </View>
        </MB_Modal>
);


}

const styles = StyleSheet.create({
    container: {
        width: 409,
        paddingTop: 20,
        paddingBottom: 0,
        paddingHorizontal: 20,
        alignItems: 'stretch',
    },
    headerIcon: {
        alignSelf: 'center',
        width: 61,
        height: 61,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 360,
    },
    titleText: mbTextStyles([textStyles.largeText, {
        fontSize: 21,
        color: '#000000',
        fontWeight: '600',
    }]),
    user: {
        backgroundColor: '#F5F7F7',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 17,
    },
    userText: mbTextStyles([textStyles.normalText, {
        color: '#000000',
        fontWeight: '500',
    }]),
    noteText: mbTextStyles([textStyles.normalText, {
        color: '#000000',
        fontWeight: '400',
    }]),
    footer: {
        height: 95,
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#FEF2EE',
        paddingHorizontal: 30,
        flexDirection: 'row',
        marginHorizontal: -20,
    },
    primaryBtn: {
        width: 167,
        height: 49,
        backgroundColor: '#F35E2E',
        borderRadius: 8,
        borderWidth: 0,
    },
    primaryBtnText: mbTextStyles([textStyles.normalText, {
        textAlign: 'left',
        color: '#FFFFFF',
    }]),
    secondaryBtn: {
        width: 167,
        height: 49,
        borderRadius: 8,
        backgroundColor: '#FFFFFF',
        borderWidth: 2,
        borderColor: '#F35E2E',
    },
    secondaryBtnText: {
        color: '#F35E2E',
    },
});
