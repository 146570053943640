export {
    AddShapeQuestionInputSchema,
    UpvoteShapeQuestionInputSchema,
    GetListOfShapeQuestionsInputSchema,
} from './schemas';

export type {
    AddShapeQuestionInputType,
    UpvoteShapeQuestionInputType,
    GetListOfShapeQuestionsInputType,
} from './types';
