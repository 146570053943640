import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

type SocialXIconProps = {
  size?: number,
  background?: string,
  color?: string,
}

export default function SocialXIcon({ size = 40, background = '#000000', color = '#FFFFFF' }: SocialXIconProps) {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
    >
      <Rect width={40} height={40} rx={20} fill={background} />
      <Path
        d="M8.059 8.51l9.267 12.429L8 31.04h2.1l8.162-8.847 6.596 8.847H32l-9.787-13.126 8.678-9.405h-2.096l-7.518 8.146-6.073-8.146H8.059zm3.087 1.549h3.282l14.488 19.43h-3.282l-14.488-19.43z"
        fill={color}
      />
    </Svg>
  );
}

