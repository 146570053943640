


import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import { getTopInset } from '../../../../utils/shared';

type HeaderProps = {
    title: string,
    icon?: React.ReactChild,
    rightElements?: React.ReactChild
};

const Header = ({ title, icon, rightElements }: HeaderProps) => {

    return (
        <View style={styles.container}>
            {!rightElements && icon}
            <Text style={[styles.title, { marginRight: rightElements ? 'auto' : undefined }]}>{title}</Text>
            {rightElements}
        </View>
    );
};

export { Header };

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20 + getTopInset(),
        marginBottom: 11,
    },
    title: mbTextStyles([textStyles.largestText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        fontSize: 38,
        color: '#000000',
        marginLeft: 8,
    }], { allowLineHeight: true }),
});
