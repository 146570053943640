import React, { RefObject, useMemo } from 'react';
import { KeyboardTypeOptions, StyleSheet, Text, TextInput as RNTextInput, View, StyleProp, ViewStyle } from 'react-native';
import { MB_TextInput, MB_TextInputProps } from '@mightybyte/rnw.components.text-input';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../constants/textStyles';
import { InnerShadow } from './InnerShadow';

export type TextInputProps = MB_TextInputProps & {
    titleIcon?: React.ReactNode,
    useShadow?: boolean,
    keyboardType?: KeyboardTypeOptions
    errorMessage?: string,
    showCharacterLimit?: boolean,
    containerStyle?: StyleProp<ViewStyle>
}

const TextInput = ({ useShadow, title, titleIcon, showRequiredAsteriks, requiredAsteriksStyle, titleStyle, keyboardType, errorMessage, showCharacterLimit, containerStyle, ...textInputProps }: TextInputProps) => {

    const charactersLimit = useMemo(() => {
        if (textInputProps.minLength && textInputProps.maxLength) {
            return `${textInputProps.minLength}-${textInputProps.maxLength} Character limit`;
        } else if (textInputProps.minLength) {
            return `${textInputProps.minLength} minimum character`;
        } else if (textInputProps.maxLength) {
            return `${textInputProps.maxLength} Character limit`;
        }
    }, [textInputProps.maxLength, textInputProps.minLength]);

    const Wrapper = useShadow ? InnerShadow : View;

    return (
        <View style={containerStyle}>
            {(!!title) &&
                <View style={styles.inputTitleContainer}>
                    {titleIcon}
                    <Text style={[styles.inputTitle, titleStyle]}>{title}{showRequiredAsteriks && <Text style={[styles.inputTitleAsteriks, requiredAsteriksStyle]}> *</Text>}</Text>
                </View>
            }
            <Wrapper style={[containerStyle, textInputProps.isError && styles.error]}>
                {keyboardType ?
                    <RNTextInput
                        {...textInputProps}
                        placeholderTextColor={textInputProps.placeholderTextColor ?? mbApplyTransparency('#000000', 0.45)}
                        style={[styles.input, textInputProps.style, styles.textInput, textInputProps.textInputStyle]}
                        keyboardType={keyboardType}
                        ref={textInputProps.MB_Ref as RefObject<RNTextInput>}
                    />
                    :
                    <MB_TextInput
                        {...textInputProps}
                        placeholderTextColor={textInputProps.placeholderTextColor ?? mbApplyTransparency('#000000', 0.45)}
                        style={[styles.input, textInputProps.style]}
                        textInputStyle={[styles.textInput, textInputProps.textInputStyle]}
                        showCharacterLimit={false}
                    />
                }
            </Wrapper>
            {showCharacterLimit && charactersLimit && <Text style={styles.charactersLimit}>{charactersLimit}</Text>}
            {textInputProps.isError && <Text style={styles.errorMessageText}>{errorMessage}</Text>}
        </View>
    );
};

const ShadowTextInput = (props: Omit<TextInputProps, 'useShadow'>) => <TextInput {...props} useShadow />;

export { ShadowTextInput, TextInput };

const styles = StyleSheet.create({
    inputTitleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
    },
    inputTitle: mbTextStyles([textStyles.smallText, {
        color: mbApplyTransparency('#000000', 0.55),
        fontWeight: '400',
        textAlign: 'left',
        lineHeight: 16,
    }], { allowLineHeight: true }),
    inputTitleAsteriks: {
        color: '#6D3139',
    },
    input: {
        width: '100%',
        borderWidth: 0,
        height: 48,
        paddingHorizontal: 20,
    },
    textInput: mbTextStyles([textStyles.normalText, {
        color: mbApplyTransparency('#000000', 0.45),
        textAlign: 'left',
        fontWeight: '400',
    }]),
    eye: {
        position: 'absolute',
        right: 10,
        top: 10,
        bottom: 10,
        justifyContent: 'center',
    },
    error: {
        borderColor: mbApplyTransparency('#FF0000', 0.80),
    },
    charactersLimit: mbTextStyles([textStyles.smallText, {
        color: mbApplyTransparency('#000000', 0.25),
        marginTop: 5,
        textAlign: 'right',
    }]),
    errorMessageText: mbTextStyles([textStyles.smallText, {
        position: 'absolute',
        zIndex: 1,
        color: mbApplyTransparency('#FF0000', 0.80),
        textAlign: 'center',
        fontWeight: '400',
        fontSize: 11,
        left: 0,
        bottom: -19,
    }]),
});

