import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React, { useCallback } from 'react';
import { StyleSheet, Text } from 'react-native';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import { Spacer } from '../../../helperComponents/Spacer';
import { ShadowTextInput } from '../../../helperComponents/ShadowTextInput';
import DatePicker from '../../../helperComponents/DatePicker';
import { GradientButton } from '../../../helperComponents/gradients/GradientButton';
import DropdownPicker from '../../../helperComponents/DropdownPicker';
import sharedStyles from './Shared/styles';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Header } from './Shared/Header';
import { UniqueUserFields, UserIdentity } from '@sapphicsavvy/business';
import { z } from 'zod';
import { useForm } from '../../../../hooks/useForm';
import { sub } from 'date-fns';
import { useIsUnique } from '../../../../utils/queries';

type ValidForm = {
    userName: string,
    dob: Date,
    identity: UserIdentity
}

const schemas = {
    userName: z.string().trim().min(1, { message: 'Username is required' }).min(3, { message: 'Username must contain at least 3 character(s)' }).max(10),
    dob: z.date({ message: 'Date of birth is required' })
        .max(sub(Date.now(), { years: 18 }), { message: 'You must be 18 years old to use the app' })
        .min(new Date('1900-01-01'), { message: 'Date of birth can\'t be less than 1900' }),
    identity: z.nativeEnum(UserIdentity, { message: 'Identity is required' }),
};


const AccountSetupStep = ({ onComplete }: { onComplete?: (data: { userName: string, dob: Date, identity: UserIdentity }) => void }) => {

    const {
        userName,
        dob,
        setDob,
        identity,
        setIdentity,
        setUserName,
        validateAll,
        validateUserName,
        errors,
        updateErrors,
    } = useForm<Partial<ValidForm>>({ schemas }, { userName: '' });

    const { isLoading: isCheckingUserName } = useIsUnique({
        value: userName,
        fieldName: UniqueUserFields.userName,
        enabled: (value) => validateUserName(value).isValid,
        onFetch(isUnique) {
            if (isUnique === undefined) {
                updateErrors('userName', 'couldn\'t check the username');
            } else if (!isUnique) {
                updateErrors('userName', 'username is used');
            } else {
                updateErrors('userName', { deleteMessage: true });
            }
        },
    });

    const complete = useCallback(() => {
        const result = validateAll<ValidForm>();
        if (isCheckingUserName) {
            updateErrors('userName', 'we are verifying your username');
        } else if (result.isValid) {
            onComplete?.({
                userName: result.userName,
                dob: result.dob,
                identity: result.identity,
            });
        }
    }, [isCheckingUserName, onComplete, updateErrors, validateAll]);

    return (
        <KeyboardAwareScrollView
            style={sharedStyles.container}
            contentContainerStyle={sharedStyles.contentContainer}
        >
            <Header title="ACCOUNT SETUP" step={2} steps={6} />
            <Spacer height={40} />
            <Text style={styles.title}>LET’S SET UP YOUR ACCOUNT</Text>
            <Spacer height={10} />
            <Text style={styles.subTitle}>Enter your personal details</Text>
            <Spacer height={40} />
            <ShadowTextInput
                showRequiredAsteriks
                title="Create Username"
                placeholder="Enter Username"
                titleStyle={styles.inputTitle}
                onChangeText={value => setUserName(value.toLowerCase().replace(/ /g, ''))}
                autoCapitalize="none"
                minLength={3}
                maxLength={10}
                showCharacterLimit
                isError={errors.userName !== undefined}
                errorMessage={errors.userName}
            />
            <Spacer height={34} />
            <DatePicker
                showRequiredAsteriks
                title="Select your date of birth"
                date={dob}
                onDateChange={setDob}
                titleStyle={styles.inputTitle}
                isError={errors.dob !== undefined}
                errorMessage={errors.dob}
            />
            <Spacer height={34} />
            <DropdownPicker
                showRequiredAsteriks
                title="Which best describes you?"
                placeholder="Select your identity"
                value={identity}
                items={Object.values(UserIdentity)}
                onSelectItem={setIdentity}
                style={identity !== undefined && styles.dropdownPicker}
                dropdownTextStyle={identity !== undefined && styles.dropdownPickerLabel}
                isError={errors.identity !== undefined}
                errorMessage={errors.identity}
            />
            <Spacer />
            <GradientButton
                style={styles.nextBtn}
                title="Next"
                onPress={complete}
            />
        </KeyboardAwareScrollView>
    );
};

export default AccountSetupStep;

const styles = StyleSheet.create({
    title: mbTextStyles([{
        fontFamily: FONT_STYLES.Tabasco_400,
        textAlign: 'left',
        fontSize: 28,
        color: '#242121',
    }]),
    subTitle: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_400,
        textAlign: 'left',
        fontSize: 18,
        color: mbApplyTransparency('#000000', 0.55),
    }]),
    inputTitle: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_600,
        textAlign: 'left',
        fontSize: 18,
        color: mbApplyTransparency('#000000', 0.55),
        lineHeight: 18,
    }], { allowLineHeight: true }),
    dropdownPicker: {
        borderWidth: 1,
        borderColor: '#F35E2E',
        borderRadius: 8,
    },
    dropdownPickerLabel: mbTextStyles([textStyles.normalText, {
        color: '#F35E2E',
        textAlign: 'left',
    }]),
    nextBtn: {
    },
});
