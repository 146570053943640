import React, { Fragment } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import OveviewScreen from '../../../helperComponents/OveviewScreen';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { GradientButton } from '../../../helperComponents/gradients/GradientButton';
import { UIActivityIndicator } from '@mightybyte/rnw.components.activity-indicators';
import HighlightCard from '../../../helperComponents/HighlightCard';
import { mbGetMediumImage } from '@mightybyte/rnw.components.image';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';

type ResultsProps = {
    quarterNumber?: number,
    totalUsers: number,
    isLoading?: boolean,
    onPress?: () => void,
}

export default function Results({ quarterNumber, totalUsers, isLoading, onPress }: ResultsProps) {
    const { currentUserData } = useSignedInContext();

    return (
        <OveviewScreen
            topSectionStyle={styles.topSection}
            bottomSectionStyle={styles.bottomSection}
            gardientColors={['#EF3A24', '#F9943B']}
            topSectionChildren={
                <Fragment>
                    <Text style={styles.topTitleText}>QUARTER {quarterNumber}</Text>
                    <Text style={styles.titleText}>RESULTS</Text>
                    <View style={styles.imageConatiner}>
                        <Image style={styles.image} source={mbGetMediumImage(currentUserData?.avatarUrl, { asUri: true })} />
                    </View>
                </Fragment>
            }
        >
            {isLoading ?
                <View>
                    <UIActivityIndicator size={30} color="#EF3A24" />
                </View>
                :
                <View style={styles.content}>
                    <Text style={styles.greatingText}>Hey @{currentUserData?.userName}! This week's correct answers are based on answers from all</Text>
                    <HighlightCard
                        text={totalUsers}
                        style={styles.highlightCard}
                        textStyle={styles.highlightCardText}
                    />
                    <Text style={styles.subGreatingText}>sapphics who played this quarter (including YOU)</Text>
                    <GradientButton
                        title="Next"
                        containerStyle={styles.btn}
                        onPress={onPress}
                    />
                </View>
            }
        </OveviewScreen>
    );
}

const styles = StyleSheet.create({
    bottomSection: {
        height: '40%',
    },
    topSection: {
        height: '60%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    imageConatiner: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 4,
        borderRadius: 160,
        borderColor: '#FFFFFF',
        marginTop: 30,
    },
    image: {
        width: 181,
        height: 181,
        borderRadius: 160,
    },
    topTitleText: mbTextStyles([textStyles.largestText, {
        fontSize: 25,
        fontFamily: FONT_STYLES.Tabasco_400,
        color: '#FFFFFF',
        fontWeight: '400',
    }]),
    titleText: mbTextStyles([textStyles.largestText, {
        fontSize: 45,
        fontFamily: FONT_STYLES.Tabasco_400,
        color: '#FFFFFF',
        fontWeight: '400',
    }]),
    content: {
        paddingHorizontal: 40,
    },
    greatingText: mbTextStyles([textStyles.normalText, {
        color: '#292E30',
    }]),
    highlightCard: {
        marginVertical: 15,
        paddingVertical: 5,
    },
    highlightCardText: {
        fontSize: 55,
    },
    subGreatingText: mbTextStyles([textStyles.normalText, {
        color: '#292E30',
        marginBottom: 25,
    }]),
    btn: {
        width: 227,
        alignSelf: 'center',
    },
});
