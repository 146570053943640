import React from 'react';
import { GradientButton, GradientContainer } from '../../../helperComponents/gradients/GradientButton';
import { StyleSheet, Text, View } from 'react-native';
import { mbApplyTransparency, mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import { ShadowView } from '../../../helperComponents/ShadowView';
import CoinsIcon from '../../../../resources/svgComponents/CoinsIcon';
import { Spacer } from '../../../helperComponents/Spacer';
import StarIcon from '../../../../resources/svgComponents/StarIcon';
import CoinIcon from '../../../../resources/svgComponents/Quarter/CoinIcon';
import renderRandomStars, { getTopInset } from '../../../../utils/shared';

const stars: [number | string, number | string, number][] = [
    ['27%', '4%', 19],
    ['6%', '36%', 35],
    ['9%', '62%', 19],
    ['13%', '92%', 19],
    ['23%', '55%', 57],
    ['50%', '80%', 19],
    ['94%', '4%', 35],
    ['90%', '14%', 19],
    ['76%', '36%', 19],
    ['85%', '62%', 19],
    ['90%', '86%', 35],
];

type BeginProps = {
    quarterNumber: number,
    onPress?: () => void,
    points?: number,
}

export default function Begin({ onPress, quarterNumber, points = 200 }: BeginProps) {

    return (
        <GradientContainer style={styles.container} angle={-90}>
            <View style={styles.topSection}>
                {renderRandomStars(stars)}
                <Text style={styles.topSectionTitleText}>Quarter {quarterNumber}</Text>
                <Spacer height={30} />
                <View style={styles.topSectionTopCoin}>
                    <CoinIcon size={100} />
                </View>
                <View style={styles.topSectionBottomCoin}>
                    <CoinIcon size={154} />
                </View>
            </View>
            <View style={styles.bottomSection}>
                <Text style={styles.pointsTitleText}>POINTS</Text>
                <ShadowView style={styles.coins}>
                    <CoinsIcon size={27} />
                    <Spacer width={6} />
                    <Text style={styles.pointsText}>{points}</Text>
                </ShadowView>
                <Text style={styles.descText}>Points this week will also contribute to your total SET score as you compete for</Text>
                <ShadowView style={styles.reward}>
                    <Text style={styles.rewardText}>$500</Text>
                    <View style={{ position: 'absolute', top: 0, bottom: 0, left: 20, justifyContent: 'center' }}>
                        <StarIcon color={mbApplyTransparency('#F88337', 0.08)} />
                    </View>
                    <View style={{ position: 'absolute', top: 10, bottom: 0, right: 50 }}>
                        <StarIcon size={38} color={mbApplyTransparency('#F88337', 0.08)} />
                    </View>
                </ShadowView>
                <GradientButton
                    title="BEGIN"
                    containerStyle={styles.beginBtn}
                    onPress={onPress}
                />
            </View>
        </GradientContainer>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    topSection: {
        height: '50%',
    },
    topSectionTitleText: mbTextStyles([textStyles.largeText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        fontSize: 45,
        marginTop: getTopInset() + 20,
    }]),
    topSectionTopCoin: {
        marginLeft: '55%',
        marginBottom: -20,
    },
    topSectionBottomCoin: {
        marginLeft: '20%',
    },
    bottomSection: {
        borderTopLeftRadius: 36,
        borderTopRightRadius: 36,
        backgroundColor: '#EEEEF2',
        paddingTop: 30,
        height: '50%',
        ...mbShadow({
            offsetWidth: -5,
            offsetHeight: 0,
            color: '#000000',
            opacity: 0.15,
            radius: 10,
            elevation: 2,
        }),
    },
    beginBtn: {
        width: 227,
        alignSelf: 'center',
    },
    pointsTitleText: mbTextStyles([textStyles.largerText, {
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        color: '#000000',
    }]),
    pointsText: mbTextStyles([textStyles.largerText, {
        fontSize: 25,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        color: '#F35E2E',
    }]),
    descText: mbTextStyles([textStyles.normalText, {
        color: '#292E30',
    }]),
    coins: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        width: 111,
        height: 40,
        marginVertical: 20,
    },
    reward: {
        width: 310,
        height: 93,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: 20,
    },
    rewardText: mbTextStyles([textStyles.largestText, {
        fontSize: 55,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        color: '#F35E2E',
    }]),
});


