import React from 'react';
import { ComponentWrapper } from '../../../../helperComponents/componentWrapper/ComponentWrapper';
import sharedStyles from '../../Shared/styles';
import { Header } from './Header';
import { ShadowTextInput } from '../../../../helperComponents/ShadowTextInput';
import { Image, StyleSheet, Text, View } from 'react-native';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../../constants/textStyles';
import DatePicker from '../../../../helperComponents/DatePicker';
import { Spacer } from '../../../../helperComponents/Spacer';
import DropdownPicker from '../../../../helperComponents/DropdownPicker';
import { DateInput, UniqueUserFields, UserIdentity, UserSelectTags } from '@sapphicsavvy/business';
import Feather from 'react-native-vector-icons/Feather';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { trpc } from '../../../../../apiCalls/trpcClient';
import { useSignedInContext } from '../../../../../context/SignedInContext';
import { mbGetMediumImage } from '@mightybyte/rnw.components.image';
import { ShadowView } from '../../../../helperComponents/ShadowView';
import EditIcon from '../../../../../resources/svgComponents/EditIcon';
import { useIsUnique, useUploadImage } from '../../../../../utils/queries';
import { UIActivityIndicator } from '@mightybyte/rnw.components.activity-indicators';
import { formUtils, useForm } from '../../../../../hooks/useForm';
import { z } from 'zod';
import { GradientButton } from '../../../../helperComponents/gradients/GradientButton';
import { parse, sub } from 'date-fns';
import { utils } from '../../../../../utils/utils';
import { STRING_CONSTANTS } from '../../../../../constants/constants';
import Tags from '../../../../helperComponents/Tags';
import SocialXIcon from '../../../../../resources/svgComponents/SocialXIcon';

type ValidForm = {
    userName: string,
    dob: Date,
    bio: string,
    location: string,
    identity: UserIdentity,
    tags: UserSelectTags[],
    firstTruth: string,
    secondTruth: string,
    lie: string,
    instagram: string,
    tiktok: string,
    twitter: string,
}

const schemas = {
    userName: z.string().trim().min(1, { message: 'Username is required' }).min(3, { message: 'Username must contain at least 3 character(s)' }).max(10),
    dob: z.date({ message: 'Date of birth is required' })
        .max(sub(Date.now(), { years: 18 }), { message: 'You must be 18 years old to use the app' })
        .min(new Date('1900-01-01'), { message: 'Date of birth can\'t be less than 1900' }),
    bio: z.string().or(z.literal('')).optional(),
    location: z.string().or(z.literal('')).optional(),
    identity: z.nativeEnum(UserIdentity, { message: 'Identity is required' }),
    tags: z.array(z.nativeEnum(UserSelectTags)).optional(),
    firstTruth: z.string().or(z.literal('')).optional(),
    secondTruth: z.string().or(z.literal('')).optional(),
    lie: z.string().or(z.literal('')).optional(),
    instagram: z.string().regex(formUtils.username, { message: 'Invalid Instagram username' }).or(z.literal('')).optional(),
    tiktok: z.string().regex(formUtils.username, { message: 'Invalid TikTok username' }).or(z.literal('')).optional(),
    twitter: z.string().regex(formUtils.username, { message: 'Invalid Twitter username' }).or(z.literal('')).optional(),
};

const instagramText = <Text><Feather name="instagram" size={15} color={mbApplyTransparency('#000000', 0.55)} /> Instagram</Text> as unknown as string;
const tiktokText = <Text><FontAwesome5 name="tiktok" size={15} color={mbApplyTransparency('#000000', 0.55)} /> Tik Tok</Text> as unknown as string;
const twitterText = <Text>X</Text> as unknown as string;

const getDate = (dateInput: DateInput) => {
    const dateString = `${dateInput.day}/${dateInput.month}/${dateInput.year}`;
    return parse(dateString, 'dd/MM/yyyy', new Date());
};

const EditProfile = () => {

    const { currentUserData } = useSignedInContext();
    if (!currentUserData) {
        throw new Error('User data not found');
    }

    const {
        userName,
        dob,
        bio,
        location,
        identity,
        tags,
        instagram,
        tiktok,
        twitter,
        setUserName,
        setDob,
        setBio,
        setLocation,
        setIdentity,
        setTags,
        setFirstTruth,
        setSecondTruth,
        setLie,
        setInstagram,
        setTiktok,
        setTwitter,
        validateUserName,
        validateAll,
        errors,
        updateErrors,
    } = useForm<Partial<ValidForm>>({ schemas }, {
        userName: currentUserData.userName,
        dob: currentUserData.dob ? getDate(currentUserData.dob) : undefined,
        bio: currentUserData.bio,
        location: currentUserData.location,
        identity: currentUserData.identity,
        tags: currentUserData.tags,
        instagram: currentUserData.socialMedia?.instagram,
        tiktok: currentUserData.socialMedia?.tiktok,
        twitter: currentUserData.socialMedia?.twitter,
    });

    const trpcUtils = trpc.useUtils();
    const { mutate: updateCurrentUserData, isLoading: isUpdating } = trpc.user.updateCurrentUserData.useMutation();
    const { pickImage, isUploading, image, uploadUrl } = useUploadImage({ initialImage: mbGetMediumImage(currentUserData?.avatarUrl) });
    const { isLoading: isCheckingUserName } = useIsUnique({
        value: userName,
        fieldName: UniqueUserFields.userName,
        enabled: (value) => value !== currentUserData.userName && validateUserName(value).isValid,
        onFetch(isUnique) {
            if (isUnique === undefined) {
                updateErrors('userName', 'couldn\'t check the username');
            } else if (!isUnique) {
                updateErrors('userName', 'username is used');
            } else {
                updateErrors('userName', { deleteMessage: true });
            }
        },
    });

    const onSave = () => {
        const result = validateAll<ValidForm>();
        if (isCheckingUserName) {
            updateErrors('userName', 'we are verifying your username');
        } else if (result.isValid) {
            updateCurrentUserData({
                avatarFilename: uploadUrl ? utils.getFileNameFromUrl(uploadUrl) : undefined,
                userName: result.userName,
                dob: {
                    year: result.dob.getFullYear(),
                    month: result.dob.getMonth() + 1,
                    day: result.dob.getDate(),
                },
                identity: result.identity,
                tags: result.tags,
                bio: result.bio,
                location: result.location,
                socialMedia: {
                    instagram: result.instagram,
                    tiktok: result.tiktok,
                    twitter: result.twitter,
                },
            }, {
                onSuccess(data) {
                    trpcUtils.user.getCurrentUserData.setData(undefined, { userData: data.userData });
                    utils.showToast('success', 'Your profile has been successfully saved!');
                },
                onError: (error) => utils.showPopUp(error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN),
            });
        }
    };

    return (
        <ComponentWrapper
            innerContainerStyle={sharedStyles.container}
            wrapInScrollView={true}
            mobileHeaderOptions={{ showHeader: true }}
        >
            <Header title="EDIT PROFILE" hasScroll />
            <Spacer height={30} />
            <View style={styles.imageConatiner}>
                <Image style={styles.image} source={{ uri: image }} />
            </View>
            <Spacer height={15} />
            <ShadowView
                style={[styles.imageUploadContainer, styles.imageUpload]}
                disabled={isUploading}
                onPress={() => pickImage()}
            >
                {isUploading ?
                    <UIActivityIndicator size={20} color="#F35E2E" />
                    :
                    <>
                        <EditIcon />
                        <Spacer width={11} />
                        <Text style={styles.imageUploadText}>Edit Profile Picture</Text>
                    </>
                }
            </ShadowView>
            <Spacer height={30} />
            <ShadowTextInput
                title="Username"
                placeholder="Enter Username"
                titleStyle={styles.inputTitle}
                onChangeText={value => setUserName(value.toLowerCase().replace(/ /g, ''))}
                autoCapitalize="none"
                value={userName}
                minLength={3}
                maxLength={10}
                showCharacterLimit={false}
                isError={errors.userName !== undefined}
                errorMessage={errors.userName}
            />
            <Spacer height={34} />
            <DatePicker
                title="Date of birth"
                date={dob}
                onDateChange={setDob}
                titleStyle={styles.inputTitle}
                isError={errors.dob !== undefined}
                errorMessage={errors.dob}
                disabled
            />
            <Spacer height={34} />
            <ShadowTextInput
                value={bio}
                title="Add Bio"
                placeholder="Enter bio here"
                titleStyle={styles.inputTitle}
                onChangeText={setBio}
                multiline
                showCharacterLimit
                style={styles.bio}
                isError={errors.bio !== undefined}
                errorMessage={errors.bio}
                maxLength={250}
            />
            <Spacer height={22} />
            <ShadowTextInput
                value={location}
                title="Location"
                placeholder="Enter your city/state"
                titleStyle={styles.inputTitle}
                onChangeText={setLocation}
                isError={errors.location !== undefined}
                errorMessage={errors.location}
                textInputStyle={styles.textInput}
            />
            <Spacer height={22} />
            <DropdownPicker
                title="Select primary identity for next quarter"
                placeholder="Select your identity"
                value={identity}
                items={Object.values(UserIdentity)}
                onSelectItem={setIdentity}
                style={identity !== undefined && styles.dropdownPicker}
                dropdownTextStyle={identity !== undefined && styles.dropdownPickerLabel}
                isError={errors.identity !== undefined}
                errorMessage={errors.identity}
            />
            <Spacer height={24} />
            <View style={styles.separator} />
            <Spacer height={24} />
            <Tags
                title="Add tags to describe yourself"
                tags={tags}
                max={6}
                onTagsChange={setTags}
            />
            <Spacer height={12} />
            <View style={styles.separator} />
            <Spacer height={24} />
            <Text style={styles.socialmedia}>Add 2 truths and a lie about yourself to get featured in Sleuth for the upcoming quarter</Text>
            <Spacer height={20} />
            <ShadowTextInput
                title="1st truth"
                placeholder="Enter a truth about yourself"
                titleStyle={styles.inputTitle}
                onChangeText={setFirstTruth}
                multiline
                showCharacterLimit
                style={styles.truthOrLie}
                isError={errors.firstTruth !== undefined}
                errorMessage={errors.firstTruth}
            />
            <Spacer height={12} />
            <ShadowTextInput
                title="2nd truth"
                placeholder="Enter a truth about yourself"
                titleStyle={styles.inputTitle}
                onChangeText={setSecondTruth}
                multiline
                showCharacterLimit
                style={styles.truthOrLie}
                isError={errors.secondTruth !== undefined}
                errorMessage={errors.secondTruth}
            />
            <Spacer height={12} />
            <ShadowTextInput
                title="The lie"
                placeholder="Enter a lie about yourself"
                titleStyle={styles.inputTitle}
                onChangeText={setLie}
                multiline
                showCharacterLimit
                style={styles.truthOrLie}
                isError={errors.lie !== undefined}
                errorMessage={errors.lie}
            />
            <Spacer height={24} />
            <View style={styles.separator} />
            <Spacer height={24} />
            <Text style={styles.socialmedia}>Add socials to your profile</Text>
            <Spacer height={24} />
            <ShadowTextInput
                value={instagram}
                title={instagramText}
                placeholder="Add your username"
                titleStyle={styles.inputTitle}
                onChangeText={setInstagram}
                isError={errors.instagram !== undefined}
                errorMessage={errors.instagram}
                textInputStyle={styles.textInput}
            />
            <Spacer height={34} />
            <ShadowTextInput
                value={tiktok}
                title={tiktokText}
                placeholder="Add your username"
                titleStyle={styles.inputTitle}
                onChangeText={setTiktok}
                isError={errors.tiktok !== undefined}
                errorMessage={errors.tiktok}
                textInputStyle={styles.textInput}
            />
            <Spacer height={34} />
            <ShadowTextInput
                value={twitter}
                titleIcon={<SocialXIcon size={20} color="#000000" background="transparent" />}
                title={twitterText}
                placeholder="Add your username"
                titleStyle={styles.inputTitle}
                onChangeText={setTwitter}
                isError={errors.twitter !== undefined}
                errorMessage={errors.twitter}
                textInputStyle={styles.textInput}
            />
            <Spacer height={50} />
            <GradientButton
                title="Submit"
                onPress={onSave}
                loading={isUpdating}
                disabled={isUpdating}
            />
        </ComponentWrapper>
    );
};

export { EditProfile };

const styles = StyleSheet.create({
    imageConatiner: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 2,
        borderRadius: 80,
        borderColor: '#FFFFFF',
        alignSelf: 'center',
    },
    image: {
        width: 158,
        height: 158,
        borderRadius: 80,
    },
    imageUploadContainer: {
        width: 235,
        height: 50,
        alignSelf: 'center',
        justifyContent: 'center',
    },
    imageUpload: {
        alignSelf: 'center',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 4,
    },
    imageUploadText: mbTextStyles([{
        fontFamily: FONT_STYLES.Tabasco_400,
        textAlign: 'left',
        fontSize: 18,
        color: '#F35E2E',
    }], { allowLineHeight: true }),
    inputTitle: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_600,
        textAlign: 'left',
        fontSize: 16,
        color: mbApplyTransparency('#000000', 0.55),
    }]),
    textInput: {
        textAlign: 'auto',
    },
    bio: {
        height: 121,
    },
    separator: {
        height: 1,
        backgroundColor: '#8E9396',
        marginHorizontal: -16,
        borderTopWidth: 1,
        borderTopColor: mbApplyTransparency('#FFFFFF', 0.50),
    },
    socialmedia: mbTextStyles([{
        fontFamily: FONT_STYLES.PlusJakartaSans_600,
        textAlign: 'left',
        fontSize: 16,
        color: '#F35E2E',
    }]),
    truthOrLie: {
        height: 100,
    },
    dropdownPicker: {
        borderWidth: 1,
        borderColor: '#F35E2E',
        borderRadius: 8,
    },
    dropdownPickerLabel: mbTextStyles([textStyles.normalText, {
        color: '#F35E2E',
        textAlign: 'left',
    }]),
});
