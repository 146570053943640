import React, { Fragment, useMemo } from 'react';
import { QuestionResultToView, ResultType } from '@sapphicsavvy/business';
import Layout from '../components/Layout';
import { Image, StyleSheet, Text, View } from 'react-native';
import { mbGetMediumImage } from '@mightybyte/rnw.components.image';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { imageUtils } from '../../../../utils/imageUtils';
import { mbApplyTransparency, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_STYLES, textStyles } from '../../../../constants/textStyles';
import { ShadowView } from '../../../helperComponents/ShadowView';
import BigWinIcon from '../../../../resources/svgComponents/Quarter/BigWinIcon';
import { Spacer } from '../../../helperComponents/Spacer';
import { getNumberOfSteps } from '../../../../utils/shared';
import NoWinIcon from '../../../../resources/svgComponents/Quarter/NoWinIcon';
import WinIcon from '../../../../resources/svgComponents/Quarter/WinIcon';

type ScoreStepProps = {
    onNext?: () => void
    onBack?: () => void,
    points: number,
    results: QuestionResultToView,
}

export default function ScoreStep({ points, results, onBack, onNext }: ScoreStepProps) {
    const { currentUserData } = useSignedInContext();
    const resultType = results.resultType;
    const bonusPoints = results.bonusPoints;
    const isLose = resultType === ResultType.outOfRange && bonusPoints < 0;
    const hasNotBet = resultType === ResultType.outOfRange && bonusPoints === 0;
    const isInMargin = resultType === ResultType.inMargin;

    const Mask = useMemo(() => {
        if (isLose || isInMargin) {
            return <Fragment />;
        }
        const imageSource = hasNotBet ? imageUtils.screens.imageMaskRed : imageUtils.screens.imageMaskYellow;
        return (
            <View style={styles.topSectionMask}>
                <Image source={imageSource} style={styles.topSectionImageMask} />
                {resultType === ResultType.exact && <BigWinIcon style={[styles.topSectionIcon, { marginBottom: -100 }]} />}
                {resultType === ResultType.inWiggle && <Text style={styles.topSectionTitleText}>NICE! DEAD ON</Text>}
                {resultType === ResultType.inWiggle && <WinIcon style={styles.topSectionIcon} />}
                {resultType === ResultType.inWiggle && <Image style={styles.thumbsUpInWiggleIcon} source={imageUtils.icons.thumbsUp} resizeMode="contain" />}
                {hasNotBet && <Text style={styles.topSectionTitleText}>BETTER LUCK NEXT TIME</Text>}
                {hasNotBet && <NoWinIcon style={styles.topSectionIcon} />}
                {hasNotBet && <Image style={styles.thumbsUpInMargingIcon} source={imageUtils.icons.thumbsUp} resizeMode="contain" />}
            </View>
        );

    }, [hasNotBet, isInMargin, isLose, resultType]);

    return (
        <Layout
            title="SCORE"
            points={points}
            numberOfSteps={getNumberOfSteps(results)}
            currentStep={2}
            onBack={onBack}
            onNext={onNext}
            nextButtonColor={isLose ? '#EE3823' : '#FCAF41F4'}
            titleColor="#FFFFFF"
            headerColor={isLose ? '#EE3823' : '#FCAF41F4'}
            pointsColor="#FFFFFF"
            pointsShadowColors={isLose ? '#D1311F' : '#F0A73F'}
            stepperBackgroundColor={isLose ? '#EE3823' : '#FCAF41F4'}
            topSectionStyle={(isLose || isInMargin) ? styles.outOfRange : styles.exact}
            bottomSectionStyle={[styles.bottomSection, (isLose || isInMargin) ? { height: '100%', justifyContent: 'space-around' } : { height: '55%' }]}
            topSectionChildren={Mask}
        >
            {resultType === ResultType.exact &&
                <Fragment>
                    <Text style={styles.extraBetTitleText}>Extra Bet</Text>
                    <ShadowView style={styles.extraBet}>
                        <Text style={styles.extraBetText}>+{bonusPoints}</Text>
                    </ShadowView>
                    <View style={styles.info}>
                        <ShadowView style={styles.infoSection}>
                            <Text style={styles.infoSectionTitleText}>Base Value</Text>
                            <Text style={styles.infoSectionValueText}>50</Text>
                        </ShadowView>
                        <Spacer width={11} />
                        <ShadowView style={styles.infoSection}>
                            <Text style={styles.infoSectionTitleText}>Max Win</Text>
                            <Text style={styles.infoSectionValueText}>{bonusPoints}</Text>
                        </ShadowView>
                    </View>
                </Fragment>
            }
            {resultType === ResultType.inWiggle &&
                <Fragment>
                    <Text style={[styles.extraBetInMarginTitleText, { color: '#7BBF68F4' }]}>Extra Bet</Text>
                    <ShadowView style={[styles.extraBet, { backgroundColor: '#53A43DF4', flexDirection: 'row' }]}>
                        <Text style={[styles.extraBetText, { color: '#FFFFFFF4' }]}>{bonusPoints}</Text>
                        <Text style={[styles.extraBetDelimetrText, { color: '#FFFFFFF4' }]}>out of</Text>
                        <Text style={[styles.extraBetText, { color: '#FFFFFFF4' }]}>50</Text>
                    </ShadowView>
                </Fragment>
            }
            {(isLose || isInMargin) &&
                <Fragment>
                    <View style={[styles.imageConatiner, { borderColor: isInMargin ? '#FCAF41' : '#EE3823' }]}>
                        <Image style={styles.image} source={mbGetMediumImage(currentUserData?.avatarUrl, { asUri: true })} />
                        <Image style={[styles.thumbsUpIcon, { transform: [{ rotateZ: isInMargin ? '10deg' : '-164deg' }] }]} source={imageUtils.icons.thumbsUp} resizeMode="contain" />
                    </View>
                    <View style={[styles.loseContainer, { backgroundColor: isInMargin ? '#FCAF41' : '#EE3823' }]}>
                        <Text style={styles.loseText}>{isInMargin ? 'YOU WON' : 'YOU LOSE'}</Text>
                    </View>
                    <View style={styles.betConatainer}>
                        <Text style={[styles.betTitleText, { color: isInMargin ? '#FCAF41' : '#EE3823' }]}>{isInMargin ? 'Base Points' : 'Extra Bet'}</Text>
                        <ShadowView style={styles.bet}>
                            <Text style={[styles.betText, { color: isInMargin ? '#FCAF41' : '#EE3823' }]}>{bonusPoints}</Text>
                        </ShadowView>
                    </View>
                </Fragment>
            }
            {hasNotBet &&
                <Fragment>
                    <Text style={styles.extraBetInMarginTitleText}>Extra Bet</Text>
                    <ShadowView style={[styles.extraBet, { backgroundColor: '#FFEBE8', flexDirection: 'row' }]}>
                        <Text style={[styles.extraBetText, { color: '#F35E2E' }]}>{bonusPoints}</Text>
                        <Text style={styles.extraBetDelimetrText}>out of</Text>
                        <Text style={[styles.extraBetText, { color: '#F35E2E' }]}>50</Text>
                    </ShadowView>
                </Fragment>
            }
        </Layout>
    );
}

const styles = StyleSheet.create({
    topSectionMask: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    topSectionImageMask: {
        position: 'absolute',
        top: -3,
        left: 0,
        width: '100%',
    },
    topSectionTitleText: mbTextStyles([textStyles.largeText, {
        fontSize: 25,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        color: '#FFFFFF',
        marginBottom: 20,
    }]),
    thumbsUpInMargingIcon: {
        width: 111,
        height: 111,
        position: 'absolute',
        transform: [{ rotateZ: '175deg' }],
        top: '45%',
    },
    thumbsUpInWiggleIcon: {
        width: 111,
        height: 111,
        position: 'absolute',
        transform: [{ rotateZ: '15deg' }],
        top: '35%',
    },
    exact: {
        height: '45%',
    },
    outOfRange: {
        height: 1,
    },
    topSectionIcon: {
        marginBottom: -40,
    },
    bottomSection: {
        paddingHorizontal: 40,
    },
    imageConatiner: {
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 4,
        borderRadius: 360,
        borderColor: '#EE3823',
    },
    image: {
        width: 160,
        height: 160,
        borderRadius: 360,
    },
    thumbsUpIcon: {
        width: 111,
        height: 111,
        position: 'absolute',
        transform: [{ rotateZ: '-164deg' }],
        bottom: -50,
    },
    loseContainer: {
        backgroundColor: '#EE3823',
        borderRadius: 10,
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 26,
    },
    loseText: mbTextStyles([textStyles.largeText, {
        fontSize: 32,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        color: '#FFFFFF',
    }]),
    betConatainer: {
        alignSelf: 'stretch',
    },
    betTitleText: mbTextStyles([textStyles.largeText, {
        fontSize: 22,
        fontWeight: '600',
        color: '#EE3823',
    }]),
    bet: {
        borderRadius: 10,
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 12,
        marginTop: 11,
    },
    betText: mbTextStyles([textStyles.largeText, {
        fontSize: 55,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        color: '#EE3823',
    }]),
    extraBetTitleText: mbTextStyles([textStyles.largeText, {
        fontSize: 22,
        fontWeight: '600',
        color: mbApplyTransparency('#000000', 0.95),
    }]),
    extraBetInMarginTitleText: mbTextStyles([textStyles.largeText, {
        fontSize: 35,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        color: '#F35E2E',
    }]),
    extraBet: {
        alignSelf: 'stretch',
        height: 94,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: mbApplyTransparency('#DBF0D5', 0.95),
        marginTop: 16,
    },
    extraBetText: mbTextStyles([textStyles.largeText, {
        fontSize: 55,
        fontFamily: FONT_STYLES.Tabasco_400,
        fontWeight: '400',
        color: mbApplyTransparency('#7BBF68', 0.95),
    }]),
    extraBetDelimetrText: mbTextStyles([textStyles.largerText, {
        fontSize: 22,
        color: mbApplyTransparency('#000000', 0.95),
        marginHorizontal: 15,
    }]),
    info: {
        flexDirection: 'row',
        alignSelf: 'stretch',
        marginTop: 16,
    },
    infoSection: {
        height: 80,
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    },
    infoSectionTitleText: mbTextStyles([textStyles.normalText, {
        fontWeight: '700',
        color: mbApplyTransparency('#46596B', 0.55),
    }]),
    infoSectionValueText: mbTextStyles([textStyles.normalText, {
        fontSize: 25,
        fontWeight: '700',
        color: '#46596B',
        marginTop: 4,
    }]),
});
