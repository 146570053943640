import * as React from 'react';
import Svg, {
  Path,
  Defs,
  LinearGradient,
  Stop,
  RadialGradient,
} from 'react-native-svg';

export default function MilitaryModalIcon() {
  return (
    <Svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
    >
      <Path
        d="M29.688 3.125v18.172l4.078-2.219V3.125h-4.078z"
        fill="url(#paint0_linear_376_26331)"
      />
      <Path
        d="M29.688 3.125v18.172l4.078-2.219V3.125h-4.078z"
        fill="url(#paint1_linear_376_26331)"
      />
      <Path
        d="M16.25 3.125v15.953l4.078 2.219-.015-18.172H16.25z"
        fill="url(#paint2_linear_376_26331)"
      />
      <Path
        d="M16.25 3.125v15.953l4.078 2.219-.015-18.172H16.25z"
        fill="url(#paint3_linear_376_26331)"
      />
      <Path
        d="M16.25 3.125h-5.14c-.954 0-1.735.797-1.735 1.781v9.047c0 .625.328 1.219.86 1.531l6.015 3.594V3.125z"
        fill="url(#paint4_linear_376_26331)"
      />
      <Path
        d="M16.25 3.125h-5.14c-.954 0-1.735.797-1.735 1.781v9.047c0 .625.328 1.219.86 1.531l6.015 3.594V3.125z"
        fill="url(#paint5_linear_376_26331)"
      />
      <Path
        d="M33.766 3.125h5.14c.953 0 1.735.797 1.735 1.781v9.047c0 .625-.328 1.219-.86 1.531l-6.015 3.594V3.125z"
        fill="url(#paint6_linear_376_26331)"
      />
      <Path
        d="M33.766 3.125h5.14c.953 0 1.735.797 1.735 1.781v9.047c0 .625-.328 1.219-.86 1.531l-6.015 3.594V3.125z"
        fill="url(#paint7_linear_376_26331)"
      />
      <Path
        d="M33.766 3.125h5.14c.953 0 1.735.797 1.735 1.781v9.047c0 .625-.328 1.219-.86 1.531l-6.015 3.594V3.125z"
        fill="url(#paint8_linear_376_26331)"
      />
      <Path
        d="M29.688 3.125h-9.375v18.172h9.375V3.125z"
        fill="url(#paint9_linear_376_26331)"
      />
      <Path
        d="M29.688 3.125h-9.375v18.172h9.375V3.125z"
        fill="url(#paint10_linear_376_26331)"
      />
      <Path
        d="M25 46.875c8.664 0 15.688-7.198 15.688-16.078 0-8.88-7.024-16.078-15.688-16.078-8.664 0-15.687 7.198-15.687 16.078 0 8.88 7.023 16.078 15.687 16.078z"
        fill="url(#paint11_linear_376_26331)"
      />
      <Path
        d="M25 46.875c8.664 0 15.688-7.198 15.688-16.078 0-8.88-7.024-16.078-15.688-16.078-8.664 0-15.687 7.198-15.687 16.078 0 8.88 7.023 16.078 15.687 16.078z"
        fill="url(#paint12_linear_376_26331)"
      />
      <Path
        d="M25 43.984c7.102 0 12.86-5.904 12.86-13.187 0-7.283-5.758-13.188-12.86-13.188-7.102 0-12.86 5.905-12.86 13.188S17.899 43.984 25 43.984z"
        fill="url(#paint13_linear_376_26331)"
      />
      <Path
        d="M25 43.984c7.102 0 12.86-5.904 12.86-13.187 0-7.283-5.758-13.188-12.86-13.188-7.102 0-12.86 5.905-12.86 13.188S17.899 43.984 25 43.984z"
        fill="url(#paint14_radial_376_26331)"
      />
      <Path
        d="M25 43.984c7.102 0 12.86-5.904 12.86-13.187 0-7.283-5.758-13.188-12.86-13.188-7.102 0-12.86 5.905-12.86 13.188S17.899 43.984 25 43.984z"
        fill="url(#paint15_radial_376_26331)"
      />
      <Path
        d="M25.016 44.938c-3.688 0-7.157-1.47-9.75-4.141-2.61-2.672-4.047-6.219-4.047-10 0-3.781 1.437-7.328 4.047-10 5.375-5.516 14.125-5.516 19.515 0 5.375 5.515 5.375 14.484 0 20-2.625 2.672-6.093 4.14-9.765 4.14zM25 18.578c-3.047 0-6.11 1.188-8.422 3.578-2.25 2.313-3.484 5.375-3.484 8.64 0 3.267 1.234 6.329 3.484 8.642 2.25 2.312 5.25 3.578 8.422 3.578 3.188 0 6.172-1.266 8.422-3.578 4.64-4.766 4.64-12.516 0-17.282A11.656 11.656 0 0025 18.578z"
        fill="url(#paint16_linear_376_26331)"
      />
      <Path
        d="M25.953 24.469l1.297 2.687c.156.313.453.547.797.594l2.89.438c.86.125 1.22 1.218.579 1.843l-2 2c-.297.297-.391.735-.266 1.14l.922 2.673c.312.89-.563 1.734-1.422 1.36l-3.344-1.5a.996.996 0 00-.843 0l-3.344 1.5c-.844.374-1.735-.47-1.422-1.36l.922-2.672c.14-.406.031-.844-.266-1.14l-2-2c-.625-.626-.281-1.72.578-1.845l2.89-.437c.345-.047.642-.266.798-.594l1.297-2.687c.422-.813 1.547-.813 1.937 0z"
        fill="url(#paint17_linear_376_26331)"
      />
      <Path
        d="M25.953 24.469l1.297 2.687c.156.313.453.547.797.594l2.89.438c.86.125 1.22 1.218.579 1.843l-2 2c-.297.297-.391.735-.266 1.14l.922 2.673c.312.89-.563 1.734-1.422 1.36l-3.344-1.5a.996.996 0 00-.843 0l-3.344 1.5c-.844.374-1.735-.47-1.422-1.36l.922-2.672c.14-.406.031-.844-.266-1.14l-2-2c-.625-.626-.281-1.72.578-1.845l2.89-.437c.345-.047.642-.266.798-.594l1.297-2.687c.422-.813 1.547-.813 1.937 0z"
        fill="url(#paint18_radial_376_26331)"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_376_26331"
          x1={31.7267}
          y1={3.125}
          x2={31.7267}
          y2={21.2969}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D6C6E5" />
          <Stop offset={1} stopColor="#D3BBE0" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_376_26331"
          x1={31.7267}
          y1={3.125}
          x2={31.7267}
          y2={5.59856}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#CCC1D8" />
          <Stop offset={1} stopColor="#CCC1D8" stopOpacity={0} />
        </LinearGradient>
        <LinearGradient
          id="paint2_linear_376_26331"
          x1={18.2891}
          y1={3.125}
          x2={18.2891}
          y2={21.2969}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#D6C6E5" />
          <Stop offset={1} stopColor="#D3BBE0" />
        </LinearGradient>
        <LinearGradient
          id="paint3_linear_376_26331"
          x1={18.2891}
          y1={3.125}
          x2={18.2891}
          y2={5.59856}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#CCC1D8" />
          <Stop offset={1} stopColor="#CCC1D8" stopOpacity={0} />
        </LinearGradient>
        <LinearGradient
          id="paint4_linear_376_26331"
          x1={18.1273}
          y1={3.125}
          x2={18.1274}
          y2={19.0781}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#3EA2F8" />
          <Stop offset={1} stopColor="#3363C1" />
        </LinearGradient>
        <LinearGradient
          id="paint5_linear_376_26331"
          x1={12.1373}
          y1={17.356}
          x2={13.1731}
          y2={15.6296}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#3856BB" />
          <Stop offset={1} stopColor="#3856BB" stopOpacity={0} />
        </LinearGradient>
        <LinearGradient
          id="paint6_linear_376_26331"
          x1={42.518}
          y1={3.125}
          x2={42.518}
          y2={19.0781}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#3EA2F8" />
          <Stop offset={1} stopColor="#3363C1" />
        </LinearGradient>
        <LinearGradient
          id="paint7_linear_376_26331"
          x1={42.6684}
          y1={10.4199}
          x2={38.4759}
          y2={10.4199}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#53BAFF" />
          <Stop offset={1} stopColor="#53BAFF" stopOpacity={0} />
        </LinearGradient>
        <LinearGradient
          id="paint8_linear_376_26331"
          x1={38.6239}
          y1={17.5533}
          x2={37.4648}
          y2={15.4817}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#3856BB" />
          <Stop offset={1} stopColor="#3856BB" stopOpacity={0} />
        </LinearGradient>
        <LinearGradient
          id="paint9_linear_376_26331"
          x1={25}
          y1={3.125}
          x2={25}
          y2={21.2969}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#F2415A" />
          <Stop offset={1} stopColor="#E83973" />
        </LinearGradient>
        <LinearGradient
          id="paint10_linear_376_26331"
          x1={25}
          y1={3.125}
          x2={25}
          y2={5.0745}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#EB3A52" />
          <Stop offset={1} stopColor="#EB3A52" stopOpacity={0} />
        </LinearGradient>
        <LinearGradient
          id="paint11_linear_376_26331"
          x1={33.3854}
          y1={25.0977}
          x2={20.7877}
          y2={46.3867}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#F4C654" />
          <Stop offset={1} stopColor="#DC9729" />
        </LinearGradient>
        <LinearGradient
          id="paint12_linear_376_26331"
          x1={19.5182}
          y1={47.6563}
          x2={23.2291}
          y2={26.6602}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#FA8547" />
          <Stop offset={1} stopColor="#FA8547" stopOpacity={0} />
        </LinearGradient>
        <LinearGradient
          id="paint13_linear_376_26331"
          x1={19.0554}
          y1={29.1245}
          x2={38.238}
          y2={29.1245}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#FAA945" />
          <Stop offset={1} stopColor="#EC9936" />
        </LinearGradient>
        <RadialGradient
          id="paint14_radial_376_26331"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-17.1558 0 0 -14.9916 29.986 30.797)"
        >
          <Stop offset={0.843882} stopColor="#F7B44D" stopOpacity={0} />
          <Stop offset={1} stopColor="#F7B44D" />
        </RadialGradient>
        <RadialGradient
          id="paint15_radial_376_26331"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(16.198 0 0 14.5999 21.661 30.797)"
        >
          <Stop offset={0.826283} stopColor="#D2801F" stopOpacity={0} />
          <Stop offset={1} stopColor="#D2801F" />
        </RadialGradient>
        <LinearGradient
          id="paint16_linear_376_26331"
          x1={37.5866}
          y1={28.3283}
          x2={25.0156}
          y2={28.3283}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#F9A842" />
          <Stop offset={1} stopColor="#FAA742" />
          <Stop offset={1} stopColor="#FAA742" />
        </LinearGradient>
        <LinearGradient
          id="paint17_linear_376_26331"
          x1={23.6599}
          y1={27.7962}
          x2={28.6928}
          y2={37.7305}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#864B60" />
          <Stop offset={1} stopColor="#5D3247" />
        </LinearGradient>
        <RadialGradient
          id="paint18_radial_376_26331"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="rotate(130.746 8.328 19.712) scale(4.18927 5.71337)"
        >
          <Stop offset={0.200652} stopColor="#602543" />
          <Stop offset={1} stopColor="#602543" stopOpacity={0} />
        </RadialGradient>
      </Defs>
    </Svg>
  );
}
