import { z } from 'zod';
import { AnswerType, ShapeCategories, ShapeSortOptions } from '../../entities';
import { InfiniteScrollingInputSchema } from '../common';

export const AddShapeQuestionInputSchema = z.object({
    question: z.string(),
    answerType: z.nativeEnum(AnswerType),
    category: z.nativeEnum(ShapeCategories),
    showSubmitter: z.boolean(),
});

export const UpvoteShapeQuestionInputSchema = z.object({
    questionId: z.string(),
});

export const GetListOfShapeQuestionsInputSchema =
    InfiniteScrollingInputSchema.extend({
        filter: z
            .object({
                category: z.array(z.string()).optional(),
            })
            .optional(),
        sortBy: z.nativeEnum(ShapeSortOptions),
    });
