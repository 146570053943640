import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native';
import { trpc } from '../../../apiCalls/trpcClient';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { Header } from './Shared/Header';
import { ButtonFilter } from '../../helperComponents/ButtonFilter';
import { MB_Table, MB_TableProps } from '@mightybyte/rnw.components.table';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_DropDownPicker } from '@mightybyte/rnw.components.dropdownpicker';
import { Spacer } from '../../helperComponents/Spacer';
import { useModalState } from '../../../utils/shared';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { utils } from '../../../utils/utils';
import { getColors } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { quarterSharedStyles } from './components/quarter/quarterSharedStyles';
import sharedStyles from './Shared/styles';
import ModerateShapeQuestionModal, { ShapeActionTypes } from './modals/ModerateShapeQuestionModal';
import EditIcon from '../../../resources/svgComponents/EditIcon';
import TrashIcon from '../../../resources/svgComponents/TrashIcon';
import ProfileIcon from '../../../resources/svgComponents/ProfileIcon';
import { Indicators } from './Shared/Indicators';
import {
    AnswerType,
    DateInput,
    ShapeCategories,
    shapeCategoryToLabel,
    ShapeDataForAdminClient,
    ShapeQuestionModerationStatus,
} from '@sapphicsavvy/business';
import { AdminScreenProps } from '../../../typesAndInterfaces/componentProps';
import { EditShapeQuestionModal } from './modals/EditShapeQuestionModal';

const COLORS = getColors();
const PAGE_SIZE = 10;

enum ViewTypes {
    moderate = 'moderate',
    accepted = 'accepted',
    featured = 'featured',
    rejected = 'rejected',
}

const categoryBackgroundColors = {
    [ShapeCategories.relationshipAndSex]: '#0295CD',
    [ShapeCategories.beliefs]: '#F88337',
    [ShapeCategories.identityAndCommunity]: '#FCAF41F4',
    [ShapeCategories.lifestyle]: '#6A2C8C',
    [ShapeCategories.substanceUse]: '#EE3823',
    [ShapeCategories.wellBeing]: '#53A43DF4',
};

const shapeCategoryDropdownOptions = [
    { label: 'All', value: 'all' },
    ...Object.values(ShapeCategories).map(category => ({
        label: shapeCategoryToLabel(category),
        value: category,
    })),
];

const headerTextStyle = mbTextStyles([textStyles.normalText, { color: '#000000', textAlign: 'left' }]);
const headerContainerStyle: ViewStyle = {
    height: 52,
    justifyContent: 'center',
    paddingRight: 0,
    marginRight: 30,
};

const moderateViewHeader: MB_TableProps['header'] = [
    { content: 'Date', conatinerStyle: { ...headerContainerStyle, justifyContent: undefined, minWidth: 150, maxWidth: 170, flex: 1 }, textStyle: headerTextStyle },
    { content: 'Question', conatinerStyle: { ...headerContainerStyle, justifyContent: undefined, minWidth: 300, flex: 1 }, textStyle: headerTextStyle },
    { content: 'Submitted by', conatinerStyle: { ...headerContainerStyle, justifyContent: 'flex-start', minWidth: 120, flex: 1 }, textStyle: headerTextStyle },
    { content: 'Actions', conatinerStyle: { ...headerContainerStyle, flex: 1, maxWidth: 200, minWidth: 200 }, textStyle: headerTextStyle },
];

const activeViewHeader: MB_TableProps['header'] = [
    { content: 'Date', conatinerStyle: { ...headerContainerStyle, justifyContent: undefined, minWidth: 150, maxWidth: 170, flex: 1 }, textStyle: headerTextStyle },
    { content: 'Question', conatinerStyle: { ...headerContainerStyle, justifyContent: undefined, minWidth: 240, flex: 1 }, textStyle: headerTextStyle },
    { content: 'Submitted by', conatinerStyle: { ...headerContainerStyle, justifyContent: 'flex-start', minWidth: 120, maxWidth: 120, flex: 1 }, textStyle: headerTextStyle },
    { content: 'Votes', conatinerStyle: { ...headerContainerStyle, minWidth: 80, maxWidth: 80, flex: 1 }, textStyle: headerTextStyle },
    { content: 'Actions', conatinerStyle: { ...headerContainerStyle, flex: 1 }, textStyle: headerTextStyle },
];

const getTableHeaders = (viewType: ViewTypes): MB_TableProps['header'] => {
    if (viewType === ViewTypes.featured) {
        const featuredHeader = [...activeViewHeader];
        featuredHeader[featuredHeader.length - 1] = {
            content: 'Actions',
            conatinerStyle: { ...headerContainerStyle, minWidth: 100, maxWidth: 100, flex: 1 },
            textStyle: headerTextStyle,
        };
        return featuredHeader;
    }
    return viewType === ViewTypes.accepted ? activeViewHeader : moderateViewHeader;
};

const ModerateUserQuestions = ({ navigation, route }: AdminScreenProps<'ModerateUserQuestions'>) => {
    const [page, setPage] = useState(Number(route?.params?.page) || 1);
    const [updatingId, setUpdatingId] = useState<string | null>(null);
    const { state: modalInfo, show, hide } = useModalState<{
        question: ShapeDataForAdminClient;
        type: ShapeActionTypes | 'edit';
    }>();

    const trpcUtils = trpc.useUtils();
    const { mutate: moderateQuestion, isLoading: moderateIsLoading } = trpc.admin.shape.moderate.useMutation();

    const handleViewChange = useCallback((view: ViewTypes) => {
        setPage(1);
        navigation.setParams({ view });
    }, [navigation]);

    const handleCategoryChange = useCallback((category: ShapeCategories | 'all') => {
        setPage(1);
        navigation.setParams({ category: category === 'all' ? undefined : category });
    }, [navigation]);

    useEffect(() => {
        navigation.setParams({ page: page.toString() });
    }, [page, navigation]);

    const tableFilterOptions = useMemo(() => ([
        { label: 'Moderate', value: ViewTypes.moderate, action: handleViewChange, filter: [ShapeQuestionModerationStatus.pending] },
        { label: 'Approved', value: ViewTypes.accepted, action: handleViewChange, filter: [ShapeQuestionModerationStatus.approved] },
        { label: 'Featured', value: ViewTypes.featured, action: handleViewChange, filter: [ShapeQuestionModerationStatus.inactive] },
        { label: 'Rejected', value: ViewTypes.rejected, action: handleViewChange, filter: [ShapeQuestionModerationStatus.rejected] },
    ]), [handleViewChange]);

    const TableFilter = ButtonFilter({
        options: tableFilterOptions,
        initialSelectedIndex: route?.params?.view ? tableFilterOptions.findIndex(({ value }) => value === route.params.view) : 0,
    });

    const currentFilter = useMemo(() => (
        tableFilterOptions.find(({ value }) => value === TableFilter.selectedValue)?.filter
    ), [TableFilter.selectedValue, tableFilterOptions]);

    const { data: { items, totalItems } = {}, isLoading } = trpc.admin.shape.getPage.useQuery({
        page,
        pageSize: PAGE_SIZE,
        filter: {
            status: currentFilter ?? [ShapeQuestionModerationStatus.pending],
            category: route?.params?.category ? [route.params.category as ShapeCategories] : undefined,
        },
    });

    const renderQuestion = useCallback((text: string, type: AnswerType) => (
        <View style={styles.row}>
            <View style={styles.answerTypeLabel}>
                <Text style={styles.cellTextBold}>{type === AnswerType.percentage ? '%' : '#'}</Text>
            </View>
            <Text style={styles.cellText} numberOfLines={4}>{text}</Text>
        </View>
    ), []);

    const renderDate = useCallback((date: DateInput, category: ShapeCategories) => (
        <View>
            <View style={[styles.categoryLabel, { backgroundColor: categoryBackgroundColors[category] }]}>
                <Text style={[styles.cellText, styles.categoryText]}>{shapeCategoryToLabel(category)}</Text>
            </View>
            <Text style={[styles.cellText, styles.dateText]}>{utils.formatDateForAdmin(date)}</Text>
        </View>
    ), []);

    const renderSubmittedBy = useCallback((question: ShapeDataForAdminClient) => (
        <View>
            <View style={[styles.row, {alignItems: 'center'}]}>
                <ProfileIcon size={24} style={{marginRight: 6}} disableBackgroundColor color={question.showSubmitter ? undefined : COLORS.lightGray}/>
                <Text style={[styles.cellText]} numberOfLines={1}>{question.submittedBy.userName}</Text>
            </View>
            <Text style={[styles.cellText, styles.dateText]} numberOfLines={1}>{question.submittedBy.phoneNumber}</Text>
        </View>
    ), []);

    const handleModerateQuestion = useCallback((questionId: string, status: ShapeQuestionModerationStatus) => {
        setUpdatingId(questionId);
        moderateQuestion({
            questionId,
            status,
        }, {
            onSuccess: () => {
                utils.showToast('success', `Question has been set to ${status}`);
                trpcUtils.admin.shape.getPage.invalidate();
                setUpdatingId(null);
            },
            onError: (error) => {
                utils.showToast('error', error?.message ?? 'Failed to moderate question');
                setUpdatingId(null);
            },
        });
    }, [moderateQuestion, trpcUtils.admin.shape.getPage]);

    const renderActions = useCallback((question: ShapeDataForAdminClient) => {
        const isApproved = TableFilter.selectedValue === ViewTypes.accepted;
        const isRejected = TableFilter.selectedValue === ViewTypes.rejected;
        const isFeatured = TableFilter.selectedValue === ViewTypes.featured;

        return (
            <View style={styles.actions}>
                <MB_Button
                    style={[quarterSharedStyles.secondaryBtn, styles.actionButton]}
                    textStyle={styles.buttonText}
                    onPress={() => {
                        if (isRejected || isApproved) {
                            handleModerateQuestion(
                                question.id,
                                isRejected ? ShapeQuestionModerationStatus.pending : ShapeQuestionModerationStatus.inactive
                            );
                        } else {
                            show({
                                question,
                                type: isFeatured ? ShapeActionTypes.REVERT_FEATURED : ShapeActionTypes.APPROVE,
                            });
                        }
                    }}
                    title={isRejected ? 'Set Pending' : isApproved ? 'Feature' : isFeatured ? 'Activate' : 'Approve'}
                    disabled={moderateIsLoading || updatingId === question.id}
                    loading={moderateIsLoading && updatingId === question.id}
                />

                {!isRejected && !isFeatured && (
                    <>
                        <Spacer width={10} />
                        <MB_Button
                            style={[quarterSharedStyles.secondaryBtn, styles.actionButton]}
                            textStyle={styles.buttonText}
                            onPress={() => show({ question, type: ShapeActionTypes.REJECT })}
                            title="Reject"
                        />
                        <Spacer width={40} />
                        <TouchableOpacity onPress={() => show({ question, type: 'edit' })}>
                            <EditIcon />
                        </TouchableOpacity>
                    </>
                )}

                {!isFeatured && (
                    <>
                        <Spacer width={10} />
                        <TouchableOpacity onPress={() => show({ question, type: ShapeActionTypes.DELETE })}>
                            <TrashIcon size={18} />
                        </TouchableOpacity>
                    </>
                )}
            </View>
        );
    }, [TableFilter.selectedValue, moderateIsLoading, updatingId, show, handleModerateQuestion]);

    const moderateViewContent = useMemo(() => {
        return items?.map((question) => [
            renderDate(question.submittedAt, question.category),
            renderQuestion(question.question, question.answerType),
            renderSubmittedBy(question),
            renderActions(question),
        ]);
    }, [items, renderDate, renderQuestion, renderSubmittedBy, renderActions]);

    const activeViewContent = useMemo(() => {
        return items?.map((question) => [
            renderDate(question.submittedAt, question.category),
            renderQuestion(question.question, question.answerType),
            renderSubmittedBy(question),
            <View style={{width: '100%', alignItems: 'center'}}>
                <Text style={[styles.cellTextBold]}>{question.votesTotal}</Text>
            </View>,

            renderActions(question),
        ]);
    }, [items, renderDate, renderQuestion, renderSubmittedBy, renderActions]);

    return (
        <ComponentWrapper
            innerContainerStyle={sharedStyles.innerContainer}
            containerStyle={sharedStyles.container}
            scrollViewStyle={sharedStyles.scrollView}
            wrapInScrollView
        >
            <Header />
            <View style={sharedStyles.body}>
                {/* Filter Controls */}
                <View style={styles.filterControls}>
                    {TableFilter.IUComponent}
                    <View style={styles.row}>
                        <Text style={styles.categoryFilterLabel}>Category</Text>
                        <MB_DropDownPicker
                            multiple={false}
                            items={shapeCategoryDropdownOptions}
                            value={route?.params?.category ?? 'all'}
                            onValueChange={(v)=>handleCategoryChange(v as ShapeCategories | 'all')}
                            style={styles.categoryDropdown}
                            dropdownContainerStyle={styles.dropdownContainer}
                            highlightColor={COLORS.orange}
                            labelStyle={mbTextStyles([textStyles.normalText, {color: COLORS.textPrimary, textAlign: 'left'}])}
                            dropdownLabelStyle={mbTextStyles([textStyles.normalText, {color: COLORS.textInputText, textAlign: 'left'}])}
                            arrowStyle={{backgroundColor: COLORS.orange}}
                            titleStyle={{display: 'none'}}
                        />
                    </View>
                </View>

                {/* Table */}
                <MB_Table
                    header={getTableHeaders(TableFilter.selectedValue as ViewTypes)}
                    data={TableFilter.selectedValue === ViewTypes.moderate || TableFilter.selectedValue === ViewTypes.rejected ?
                        moderateViewContent : activeViewContent}
                    headerStyle={styles.tableHeader}
                    rowStyle={styles.rowStyle}
                    style={styles.table}
                    keyExtractor={(index) => items?.[index].id ?? index.toString()}
                    loading={isLoading}
                />

                {/* Pagination */}
                <Indicators
                    page={page}
                    totalPages={Math.ceil((totalItems || 0) / PAGE_SIZE)}
                    onPageChange={setPage}
                />
            </View>

            {/* Moderation Modal */}
            <ModerateShapeQuestionModal
                isVisible={!!modalInfo?.type && modalInfo.type !== 'edit'}
                actionType={modalInfo?.type as ShapeActionTypes}
                questionInfo={modalInfo?.question}
                onDismiss={hide}
                onModerateSuccess={(message) => {
                    utils.showToast('success', message);
                    trpcUtils.admin.shape.getPage.invalidate();
                    hide();
                }}
            />
            <EditShapeQuestionModal isVisible={modalInfo?.type === 'edit'}
                                    questionInfo={modalInfo?.question}
                                    onDismiss={hide}
                                    showExtraButton={TableFilter.selectedValue === ViewTypes.moderate}
            />
        </ComponentWrapper>
    );
};

const styles = StyleSheet.create({
    filterControls: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
        zIndex: 1000,
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    table: {
        borderWidth: 0,
    },
    tableHeader: {
        height: 50,
        backgroundColor: '#FFF4F0',
        borderBottomWidth: 0,
        borderRadius: 10,
        marginBottom: -4,
        zIndex: 2,
    },
    rowStyle: {
        height: 68,
        backgroundColor: COLORS.white,
        justifyContent: 'center',
    },
    cellText: {
        ...mbTextStyles([textStyles.smallText]),
        color: COLORS.textPrimary,
        fontWeight: '400',
        width: '100%',
        textAlign: 'left',
    },
    cellTextBold: {
        ...mbTextStyles([textStyles.largerText]),
        color: COLORS.textSecondary2,
        fontSize: 16,
        textAlign: 'center',
    },
    categoryLabel: {
        paddingHorizontal: 10,
        paddingVertical: 2,
        borderRadius: 10,
        width: 'fit-content',
    },
    categoryText: {
        color: '#fff',
    },
    dateText: {
        color: COLORS.greyText,
    },
    categoryFilterLabel: {
        color: COLORS.textPrimary,
        textAlign: 'left',
        marginRight: 10,
        fontSize: 14,
    },
    categoryDropdown: {
        width: 250,
    },
    dropdownContainer: {
        backgroundColor: COLORS.white,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: COLORS.lightGray,
    },
    answerTypeLabel: {
        width: 20,
        height: 20,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLORS.lightBlue,
        borderRadius: 10,
        marginRight: 10,
    },
    actions: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
    },
    actionButton: {
        borderWidth: 1,
        height: 39,
        minWidth: 80,
    },
    buttonText: {
        ...mbTextStyles([textStyles.smallText]),
        fontSize: 16,
        color: COLORS.orange,
    },
});

export { ModerateUserQuestions };
